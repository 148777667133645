<template>
    <div class=" mx-1 radius-small">
      <div class="">
        <v-btn v-if="Object.keys(selecedHeader).length > 0 " @click="clearfilter(selecedHeader)" text>Clear Filter</v-btn>
      </div>
        <v-simple-table class="simple-table border-on">
            <template v-slot:default>
                <thead class="grayBackground">
                    <tr class="py-2 grayBackground">
                      <th class="pl-2 grayBackground" v-if="bulkActions">
                        <v-checkbox dense color="gray"
                          :indeterminate="isDataSelected" :ripple="false"
                          class="ma-0 py-1 single-line pl-2" hide-details
                          v-model="selectAll" @change="onSelectAllChange"
                        ></v-checkbox>
                      </th>
                      <template v-for="(item, index) in headers">
                        <th class="grayBackground px-0 resizable-header" :key="item.value"
                          :ref="'header' + index" v-if="item.visible" style="min-width:5vh">
                          <div class="all-width all-height d-flex">
                            <div class="d-flex all-width align-center justify-space-between ml-2" draggable="true"
                              @dragstart="startDrag(index)" @dragover.prevent @drop="onDrop(index)">
                              <p  class="ma-0 black--text pa-0 font-weight-medium "  style="cursor:grab">
                                {{ item.text }}
                              </p>
                              <v-menu
                                :close-on-content-click="false"
                                offset-y
                                transition="slide-y-transition"
                                @input="handleMenuClose"
                                style="z-index:11 !important "
                              >
                                <template v-slot:activator="{ on, attrs }" v-if="item.sortable || item.filterable">
                                  <v-btn small icon v-bind="attrs" v-on="on" @click="clickedHeader = item.value;filterStage=0">
                                    <v-icon color="black" size="20">mdi-chevron-down</v-icon>
                                  </v-btn>
                                </template>
      
                                <!-- Main Menu: Filter By and Sort By -->
                                <v-list v-if="filterStage === 0" style="max-height: 50vh; overflow: hidden; z-index: 2;min-width:20vh" class="pa-0 ma-0 d-flex flex-column align-start radius-small justify-start all-width">
                                  <v-list-item @click="filterStage = 1; onFilterByClick(item)" class="all-width align-center px-2 justify-space-between">
                                    <p class="ma-0 "><v-icon class="pl-1">mdi-filter-outline</v-icon> Filter By</p>
                                    <v-icon style="position:absolute; right:11px">mdi-chevron-right</v-icon>
                                  </v-list-item>
                                  <v-list-item @click="filterStage = 2" class="all-width align-center px-2 justify-space-between">
                                    <p class="ma-0 "><v-icon class="pl-1">mdi-swap-vertical</v-icon> Sort By</p>
                                    <v-icon style="position:absolute; right:11px">mdi-chevron-right</v-icon>
                                  </v-list-item>
                                </v-list>
      
                                <!-- Filter By Options -->
                                <v-list v-else-if="filterStage === 1" style="max-height: 30vh; overflow-y: hidden;" class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width">
                                  <v-list-item class="all-width grayBackground px-2 font-weight-bold">
                                    <v-btn small icon @click="resetStage"><v-icon>mdi-arrow-left</v-icon></v-btn>
                                    Filter By
                                  </v-list-item>
                                  <div class="pt-1 px-2 all-width">
                                    <lb-string v-model="searchfilters" :hidedetails="true" class="all-width" placeholder="Search" label=""/>
                                  </div>
                                  <v-list class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width overflow-scroll">
                                    <template v-for="(dataItem, index) in sortedFilteredColumnItems">
                                      <v-list-item  :key="index" class="all-width px-2 d-flex align-center " v-if="dataItem.length > 0 || dataItem !== ''" >
                                        <v-checkbox color="caccounting" class="ma-0"  multiple :value="dataItem"  hide-details :label="dataItem.toString()"  v-model="selecedHeader[selectedHeader.value]" @change="applyTableFilter(selecedHeader)"></v-checkbox>
                                      </v-list-item>
                                    </template>
                                  </v-list>
                                </v-list>
      
                                <!-- Sort By Options -->
                                <v-list v-else-if="filterStage === 2" class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width">
                                  <v-list-item class="all-width grayBackground font-weight-bold">
                                    <v-btn small icon @click="resetStage"><v-icon>mdi-arrow-left</v-icon></v-btn>
                                    Sort By
                                  </v-list-item>
                                  <v-list-item class="all-width" @click="sortData(item.value, true)">Ascending</v-list-item>
                                  <v-list-item class="all-width" @click="sortData(item.value, false)">Descending</v-list-item>
                                </v-list>
                              </v-menu>
      
                              
                          
                            </div>
                            <span v-if="item.value != 'action'" class="resizer "
                              @mousedown="initResize($event, index)"></span>
                          </div>
                        </th>
                      </template>
                      <!-- <th class="grayBackground" style="min-width:5.6vh"></th> -->
                    </tr>
                  </thead>
                <tbody>
                    <slot name="tbody" />
    
                </tbody>
              </template>    
        </v-simple-table>
    </div>


</template>
<script>
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    tabledata: {
      type: Array,
      required: true,
    },
    itemsperpage:{
        type:Number,
        default:10
    },
    searchterm:{
        type:String,
        default:''
    },
    selectAllFunction:{
      type:Function
    },
    isDataSelected:{
      type:Boolean,
      default:false
    },
    bulkActions:{
      type:Boolean,
      default:true
    },
    checkalldata:{
      type:Boolean,
      default:false,
    }
  },
  data() {
    return {
        selecedHeader:{},
        selectedFilterItems: [],
        filterStage:0,
        selectItem:[],
        selectAll:false,
        currentIndex: null,
        startX: 0,
        draggingIndex: null,
        startWidth: 0,
        searchfilters:'',
        filteredColumnItems:[],
        filteredColumnItemsCopy:[],
        selectedHeader:"",
        
    };
  },
  created() {
    
  },
  activated() {
   
  },
  computed: {
    sortedFilteredColumnItems() {
      const selectedItems = this.selecedHeader[this.selectedHeader.value] || [];
      const unselectedItems = this.filteredColumnItems.filter(
        item => !selectedItems.includes(item)
      );
      const data = [...selectedItems, ...unselectedItems]
      const searchTerm = this.searchfilters?.toLowerCase();      
      return data.filter(header =>
        String(header)?.toLowerCase()?.includes(searchTerm)
      );

    },
  },
  methods:{
    sortData(item){
      console.log(item,this.tabledata);
      
    },
   filteredUniqueDataItems() {
  if (!this.selectedHeader) return [];

  // Extract unique values from the selected column
  const uniqueItems = this.tabledata
    .map(item => item[this.selectedHeader.value]) // Access the column data
    .flatMap(value => (Array.isArray(value) ? value : [value])); // Flatten if array, wrap if single value

  // Use a Set to ensure unique values
  const uniqueValues = [...new Set(uniqueItems)];

  // Filter unique values based on the search filter
  this.filteredColumnItems = uniqueValues.filter(item => {
    if (item === null || item === undefined) return false; // Ignore null or undefined values

    if (typeof item === 'number') {
      // For numbers, compare the number itself with the search filter (if it's a valid number)
      return item.toString().includes(this.searchfilters);
    }

    if (typeof item === 'string') {
      // For strings, ensure case-insensitive filtering
      return item.toLowerCase().includes(this.searchfilters.toLowerCase());
    }

    // Skip any unsupported types
    return false;
  });
},
  //   filteredUniqueDataItems() {
  //       if (!this.selectedHeader) return [];
  //       const uniqueItems = this.tabledata
  //         .map(item => item[this.selectedHeader.value])
  //         .flatMap(value => {
  //           if (Array.isArray(value)) {
  //             return value;
  //           }
  //           return [value];
  //         });
          
  //         const uniqueValues = [...new Set(uniqueItems)];
          
  //       this.filteredColumnItems = uniqueValues.filter(item => {
  //         const itemString = item?.toString()?.toLowerCase();
  //         return itemString?.includes(this.searchfilters.toLowerCase());
  //       });
  // },
    pagecount() {
      return Math.ceil(this.tabledata?.length / this.itemsperpage);
    },
    resetStage() {
      this.filterStage = 0;
      this.selectedFilterItems = [];
    },
    onFilterByClick(header) {
      this.selectedHeader = header;
      this.filteredUniqueDataItems()
      this.filterStage = 1;
    },
    handleMenuClose(isOpen) {
      if (!isOpen) {
        // this.resetStage(); 
      }
    },
    startDrag(index) {
      this.draggingIndex = index;
    },
    updateFixedDivHeight() {
      this.fixedDivHeight = this.$refs.fixedDiv.offsetHeight;
    },
    onDrop(index) {
      const draggedHeader = this.headers.splice(this.draggingIndex, 1)[0];
      this.headers.splice(index, 0, draggedHeader);
      this.draggingIndex = null;
    },
    getItemData(item, key) {
      return item[key];
    },
    initResize(event, index) {

      this.currentIndex = index;
      this.startX = event.clientX;
      this.startWidth = event.target.parentElement.offsetWidth;
      document.documentElement.addEventListener('mousemove', this.doResize, false);
      document.documentElement.addEventListener('mouseup', this.stopResize, false);
    },
    doResize(event) {
      const width = this.startWidth + (event.clientX - this.startX);
      const header = this.$refs['header' + this.currentIndex][0];
      if (header) {
        const styleString = `max-width: ${width}px !important; min-width: ${width}px !important;`;
        header.setAttribute('style', styleString);
      }
    },
    stopResize() {
      document.documentElement.removeEventListener('mousemove', this.doResize, false);
      document.documentElement.removeEventListener('mouseup', this.stopResize, false);
    },
    isHeaderVisible(value) {
      const header = this.headers.find(header => header.value === value && header.visible === true);
      return header ? header.visible : false;
    },
    onSelectAllChange() {
      this.$emit('select-all-change', this.selectAll);
      this.select(this.selectAll);
    },
    applyTableFilter(item){  
      this.$emit('filter-item',item);
    },
    clearfilter(item){
      this.selecedHeader = {};
      this.$emit('clear-item',item);
    }
  },
  watch: { 
  },
};
</script>
<style scoped>
.fixed-position {
    position: absolute !important;
    width: 14vh;
    right: 0.7%;
    z-index: 1;
    border-radius: 0px 8px 0px 0px;
}
.border-top-sides {
    border-top: 1px solid #EBEBF7;
    border-left: 1px solid #EBEBF7;
    border-right: 1px solid #EBEBF7;
}
.table-bottom-border {
    border-bottom: 1px solid #EBEBF7;
}
  


.resizable-table th {
    position: relative;
}

.resizable-header .resizer {
    top: 0;
    right: -2px;
    width: 5px;
    height: 100%;
    cursor: col-resize;
    z-index: 1;
}

.resizer:hover {
    background: #EBEBF7 !important;
}
</style>