<template>
    <div class="all-width screen-height">
        <v-card v-if="isFirst" width="60%" class="" :style="{ backgroundColor: '#ffffff' }">
            <v-card-text>
                <div class="balloon-prompt" :style="promptStyles">
                    <h6>
              &nbsp;&nbsp;
              <v-icon> mdi-arrow-right-bold</v-icon>
              Set Up Chart of Accounts:
              &nbsp;
              {{ promptMessage }}
              <v-icon @click="hidePrompt">mdi mdi-close-circle</v-icon>
            </h6>
                </div>
            </v-card-text>
        </v-card>
        <br v-if="isFirst">
        <v-card :loading="loading" class="all-width screen-height shadow-off" :disabled="loading" loader-height="2">
            <v-card-text class="all-width screen-height">
                <div class="d-flex align-center  justify-space-between mb-4">

                    <div class=""></div>

                    <div class="d-flex align-center justify-center">
                        <div class="border-on d-flex shadow-on radius-small">
                            <div class="text-center px-4 py-2 hoverable">
                                <div class="grey--text text--darken-1 line-1">Year</div>
                                <div class="font-weight-bold">
                                    <v-menu offset-y v-if="(filterData.created_year).length > 1" open-on-hover>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="filter-hide cursor-pointer" v-bind="attrs" v-on="on">{{ (filterSelectedData || {}).selectedyear }}
                                                <v-icon class="">mdi-chevron-down</v-icon>
                                            </div>
                                        </template>
                                        <perfect-scrollbar style="max-height: 300px">
                                            <v-list dense class="pa-0 border-left-default">
                                                <template v-for="(v, k) in filterData.created_year">
                                                    <v-list-item @click="filterWithYear(v)" :key="k">
                                                        <v-list-item-title>{{ v || "NA" }}</v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                            </v-list>
                                        </perfect-scrollbar>
                                    </v-menu>
                                    <span v-else>{{ (filterData || {}).created_at || "NA" }}</span>
                                </div>
                            </div>
                            <div class="text-center px-4 py-2 hoverable">
                                <div class="grey--text text--darken-1 line-1">Month</div>
                                <div class="font-weight-bold">
                                    <v-menu offset-y v-if="filterData.created_month.length > 1" open-on-hover>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="filter-hide cursor-pointer" v-bind="attrs" v-on="on">{{ (filterSelectedData || {}).selectedmonth }}
                                                <v-icon class="">mdi-chevron-down</v-icon>
                                            </div>
                                        </template>
                                        <perfect-scrollbar style="max-height: 300px">
                                            <v-list dense class="pa-0 border-left-default">
                                                <template v-for="(v, k) in filterData.created_month">
                                                    <v-list-item @click="filterByMonth(v)" :key="k">
                                                        <v-list-item-title>{{ v || "NA" }}</v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                            </v-list>
                                        </perfect-scrollbar>
                                    </v-menu>
                                    <span v-else>{{ monthNames[(filterData || {}).created_month || "NA"] }}</span>
                                </div>
                            </div>
                            <div class="text-center px-4 py-2 hoverable">
                                <div class="grey--text text--darken-1 line-1">User Name</div>
                                <div class="font-weight-bold">
                                    <v-menu offset-y v-if="filterData.created_month.length > 1" open-on-hover>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="filter-hide cursor-pointer" v-bind="attrs" v-on="on" v-if="(filterSelectedData || {}).selecteduser !== 'All'">{{ userdata[(filterSelectedData || {}).selecteduser].name || "" }}
                                                <v-icon class="">mdi-chevron-down</v-icon>
                                            </div>
                                            <div class="filter-hide cursor-pointer" v-bind="attrs" v-on="on" v-else>{{ (filterSelectedData || {}).selecteduser }}
                                                <v-icon class="">mdi-chevron-down</v-icon>
                                            </div>
                                        </template>
                                        <div @click.stop=";" class="white pa-2">
                                            <lb-string v-model="userSearch" hidedetails label="Search"></lb-string>
                                        </div>
                                        <perfect-scrollbar style="max-height: 300px">
                                            <v-list dense class="pa-0 border-left-default">
                                                <template v-for="(v, k) in filteredUserList">
                                                    <v-list-item @click="filterByUser(v)" :key="k">
                                                        <v-list-item-title v-if="v !== 'All'">{{ (userdata[v] || "NA").name || "NA" }}
                                                        </v-list-item-title>
                                                        <v-list-item-title v-else>{{ (v || "NA") }}</v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                            </v-list>
                                        </perfect-scrollbar>
                                    </v-menu>
                                    <span v-else>{{ monthNames[(filterData || {}).created_month || "NA"] }}</span>
                                </div>
                            </div>
                            <div>
                                <lb-divider type="vertical"></lb-divider>
                            </div>
                            <div class="text-center py-2 px-1 hoverable">
                                <div>
                                    <v-tooltip bottom content-class="tooltip-bottom">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-bind="attrs" v-on="on" @click.stop="resetFilter()">
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Reset filters</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </div>
                        <v-btn class="shadow-off ml-1" icon @click="refreshData()" small>
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </div>
                    <div class="d-flex  align-center justify-center">
                        <v-btn small height="4vh" color="" class="mr-1 px-4 border-dark black--text" @click="addProjectDialogBox()" v-if="$nova.hasRight(right, 'add')">
                            <v-icon class="mr-1">mdi-plus</v-icon>
                            <span>Cycle</span>
                        </v-btn>
                        &nbsp;&nbsp;&nbsp;
                        <v-btn small height="4vh" color="black" class="mr-1 px-4 border-dark white--text" @click="addAIProjectDialogBox()" v-if="$nova.hasRight(right, 'add')">
                            <v-icon class="mr-1">mdi-plus</v-icon>
                            <span>Gen AI Cycle</span>
                        </v-btn>
                    </div>
                </div>

                <lb-datatableaction :headers="tableHeaders" :tabledata="formatedDataCopy" :itemsperpage="itemsPerPage" :bulkActions="false" @filter-item="filterPart" @clear-item="clearPart">
                    <template v-slot:tbody>
                        <tr v-for="item in paginatedFilteredItems" :key="item._id" class="cursor-pointer" :class="{ 'low-opacity': item.status === false }">
                            <template v-for="header in tableHeaders">
                                <td v-if="header.visible && header.value !== 'action'" :key="header.value" @click="handleRowClick(item)" :class="{ 'description-header': header.value === 'description' }" class="px-2 single-line">
                                    <div v-if="header.value === 'stage'">

                                        <v-chip small label v-if="item.stage === 3" class="green--text" color="lightgreen">Closed</v-chip>
                                        <v-chip small label text v-else-if="item.stage === 1" class="yellow--text" color="lightyellow">Pending for review</v-chip>
                                        <v-chip small label text v-else-if="item.stage === 0" class="blue--text" color="lightblue">Open</v-chip>
                                    </div>
                                    <div v-else-if="header.value === 'summary'">
                                        <v-menu offset-x left open-on-hover>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="my-1" v-bind="attrs" v-on="on">
                                                    <div class="font-weight-bold">
                                                        <v-tooltip bottom content-class="tooltip-bottom" v-if="item.managerlist.indexOf(userid) > -1">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <span v-bind="attrs" v-on="on"><v-avatar size="8" color="caccounting"></v-avatar></span>
                                                            </template>
                                                            <span>Manager role</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom content-class="tooltip-bottom" v-else>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <span v-bind="attrs" v-on="on"><v-avatar size="8"
                                      color="grey lighten--2"></v-avatar></span>
                                                            </template>
                                                            <span>General user role</span>
                                                        </v-tooltip>
                                                        <span class="ml-1">
                                {{ (item.summary || {}).count || 0 }} Requests ({{ ((((item.summary || {}).approved || 0)
                                  /
                                  ((item.summary || {}).count || 0) || 0) * 100).toFixed(0) }}%)
                              </span>
                                                    </div>
                                                    <div class="d-flex">
                                                        <div class="green" :style="`height:10px; width: ${(((item.summary || {}).approved || 0) / ((item.summary || {}).count || 0)) * 100}%`">
                                                        </div>
                                                        <div class="yellow" :style="`height:10px; width: ${(((item.summary || {}).pendingapproval || 0) / ((item.summary || {}).count || 0)) * 100}%`">
                                                        </div>
                                                        <div class="blue" :style="`height:10px; width: ${(((item.summary || {}).pending || 0) / ((item.summary || {}).count || 0)) * 100}%`">
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <v-card class="shadow-off">
                                                <v-card-text class="py-2">
                                                    <span class="subtitle-2">Progress details</span>
                                                    <div>
                                                        <span>As</span>
                                                        <span class="mx-1 caccounting--text font-weight-bold" v-if="item.managerlist.indexOf(userid) > -1">Manager</span>
                                                        <span class="mx-1 font-weight-bold" v-else>General User</span>
                                                        <span>role</span>
                                                    </div>
                                                </v-card-text>
                                                <v-divider></v-divider>
                                                <v-card-text class="py-2">
                                                    <table>
                                                        <caption class="d-none">
                                                            Total Details
                                                        </caption>
                                                        <tbody>
                                                            <tr>
                                                                <td class="font-weight-bold mr-2 subtitle-2 pr-2">
                                                                    {{ (item.summary || {}).count || 0 }}
                                                                </td>
                                                                <td class="border-off">Total Task</td>
                                                            </tr>
                                                            <tr class="info--text">
                                                                <td class="font-weight-bold mr-2 subtitle-2">
                                                                    {{ (item.summary || {}).pending || 0 }}
                                                                </td>
                                                                <td class="border-off">Pending Submission</td>
                                                            </tr>
                                                            <tr class="warning--text">
                                                                <td class="font-weight-bold mr-2 subtitle-2">
                                                                    {{ (item.summary || {}).pendingapproval || 0 }}
                                                                </td>
                                                                <td class="border-off">Pending Approval</td>
                                                            </tr>
                                                            <tr class="success--text">
                                                                <td class="font-weight-bold mr-2 subtitle-2">
                                                                    {{ (item.summary || {}).approved || 0 }}
                                                                </td>
                                                                <td class="border-off">Closed Task</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </v-card-text>
                                            </v-card>
                                        </v-menu>
                                    </div>
                                    <div class="" v-else-if="header.value ==='created_at' || header.value === 'updated_at' || header.value==='to' || header.value==='from'">
                                        {{ $nova.formatDate(getItemData(item, header.value)) }}
                                    </div>
                                    <span v-else> {{ getItemData(item, header.value) }}</span>
                                </td>
                            </template>
                            <td class="  d-flex align-center justify-center ">
                                <v-tooltip bottom content-class="tooltip-bottom" v-if="item.stage !== 3 && item.managerlist.indexOf(userid) > -1 && $nova.hasRight(right, 'edit')">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-1 " @click.stop="addeditproject = { ...item }; editprojectdialog = true; addeditprojecterror = {};" v-bind="attrs" v-on="on" icon small>
                                            <v-icon class="">mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Edit</span>
                                </v-tooltip>
                                <v-tooltip bottom content-class="tooltip-bottom" v-if="item.stage === 3 && item.managerlist.indexOf(userid) > -1 && $nova.hasRight(right, 'open')">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-1" @click.stop="openProject(item.indexno - 1, item._id)" v-bind="attrs" v-on="on" icon small>
                                            <v-icon class="">mdi-circle-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Re-Open</span>
                                </v-tooltip>
                                <v-tooltip bottom content-class="tooltip-bottom" v-else-if="item.managerlist.indexOf(userid) > -1 && $nova.hasRight(right, 'close')">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-1" @click.stop="closeProject(item.indexno - 1, item._id)" v-bind="attrs" v-on="on" icon small>
                                            <v-icon class="">mdi-circle</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Close</span>
                                </v-tooltip>
                                <v-tooltip bottom content-class="tooltip-bottom">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">
                        <lb-activitylogwithicon :config="activitylogconfig"
                          :url="`/v2/financialclose/project/getlogs/${item._id}`" />
                      </span>
                                    </template>
                                    <span>Activity Log</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </template>
                </lb-datatableaction>

                <div :class="[$store.state.sidedraweropen ? 'w-60 position-fixed' : 'all-width position-fixed']" v-if="pagecount > 1" style="bottom:0; overflow: hidden;background:white;z-index:11">
                    <v-divider></v-divider>
                    <v-row justify="center" class="d-flex pt-3 align-center justify-space-between">
                        <v-col :cols="$store.state.sidedraweropen ? 4 : 2" class="pa-0 all-width">
                            <v-container>
                                <v-pagination v-model="page" :length="pagecount"></v-pagination>
                            </v-container>
                        </v-col>
                        <v-col cols="3">
                            <div class="d-flex align-center justify-start">
                                <p class="ma-0 pa-0 px-2">Go to this page</p>
                                <lb-number min="1" class="mr-1" label="" hidedetails :max="pagecount" v-model="page" :style="`max-width:65px !important`" />
                            </div>
                        </v-col>
                    </v-row>
                </div>

            </v-card-text>
        </v-card>

        <lb-drawer v-model="addprojectdialog" heading="Create New Cycle" width="600" :loading="addprojectloading">
            <template v-slot:body>
                <div>
                    <v-stepper class="shadow-off pa-0 ma-0" v-model="addprojectstepper" vertical>
                        <v-stepper-step :complete="addprojectstepper > 1" step="1">
                            Basic Project Information
                        </v-stepper-step>
                        <v-stepper-content step="1">
                            <div class="mt-4">
                                <v-row>
                                    <v-col cols="6" class="my-0 py-1">
                                        <lb-date v-model="addeditproject.from" label="Period From*" :error="addeditprojecterror.from" />
                                    </v-col>
                                    <v-col cols="6" class="my-0 py-1">
                                        <lb-date v-model="addeditproject.to" label="Period To*" :error="addeditprojecterror.to" :min="addeditproject.from" />
                                    </v-col>
                                    <v-col cols="12" class="my-0 py-1">
                                        <lb-string v-model="addeditproject.description" label="Description*" :error="addeditprojecterror.description" />
                                    </v-col>
                                    <!-- <v-col cols="12" class="my-0 py-1">
                      <lb-dropdown label="Associate User(s)" multiple v-model="addeditproject.userlist" :error="addeditprojecterror.userlist" :items="formdata.users" itemtext="name" itemvalue="_id" />
                    </v-col> -->
                                </v-row>
                            </div>
                        </v-stepper-content>
                        <v-stepper-step :complete="addprojectstepper > 2" step="2">
                            Tasklist configuration
                        </v-stepper-step>
                        <v-stepper-content step="2">
                            <div class="mt-4">
                                <lb-dropdown label="Populate Checklist*" v-model="checklistfrom" :error="addeditprojecterror.checklistfrom" :items="populatechecklistitems" itemtext="name" itemvalue="_id" />
                                <lb-dropdown v-if="checklistfrom === 'previousproject'" label="Select Project*" v-model="checklistfromvalue" :items="formdata.projects" itemtext="description" itemvalue="_id" />
                                <div v-if="checklistfrom === 'checklistmaster'" class="mx-2">
                                    <div class="mb-1 d-flex">
                                        <div class="font-weight-bold">Selects the tags*:</div>
                                        <v-spacer></v-spacer>
                                        <div v-if="(formdata.tags || []).length > 1" class="caption">
                                            <v-btn x-small text class="mr-1" @click="checklistfromvalue = formdata.tags">
                                                <v-icon class="mr-1">mdi-checkbox-multiple-marked</v-icon>Select all</v-btn>
                                            <v-btn x-small text @click="checklistfromvalue = []">
                                                <v-icon class="mr-1">mdi-checkbox-multiple-blank-outline</v-icon>Deselect all</v-btn>
                                        </div>
                                    </div>
                                    <template v-if="(formdata.tags || []).length > 0">
                                        <div class="d-flex" style="overflow:scroll">
                                            <v-checkbox class="mr-8" dense v-for="(v, k) in formdata.tags || []" multiple v-model="checklistfromvalue" :value="v" :key="k" :label="v"></v-checkbox>
                                        </div>
                                    </template>
                                    <div v-else class="grey--text">No tags found</div>
                                </div>
                                <div v-if="checklistfrom === 'checklistmaster'">
                                    <div class="font-weight-bold mr-2">Note:</div>
                                    <div>
                                        The Users associated in the masters will be added as general users to this project.
                                    </div>
                                    <div>
                                        The Information Owners associated in the Information Manager master will also be added to this project.
                                    </div>
                                </div>
                                <div v-else-if="checklistfrom === 'previousproject'">
                                    <div class="font-weight-bold mr-2">Note:</div>
                                    <div>
                                        The Users/Information Owners associated in the selected previous project will be added to this project.
                                    </div>
                                </div>
                            </div>
                        </v-stepper-content>
                    </v-stepper>
                </div>
            </template>
            <template v-slot:actions>
                <span class="caption">*Required</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" v-if="addprojectstepper === 1 && addeditproject.from && addeditproject.to && addeditproject.description && addeditproject.description.trim() !== ''" small @click="addprojectstepper = 2">Continue</v-btn>
                <v-btn small class="shadow-off" text v-if="addprojectstepper === 2" @click="addprojectstepper = 1">Back</v-btn>
                <!-- <v-btn color="primary" v-if="addprojectstepper === 2" small @click="gotoUserConfig()">Continue</v-btn>
          <v-btn small class="shadow-off" text v-if="addprojectstepper === 3" @click="addprojectstepper = 2">Back</v-btn> -->
                <v-btn small color="primary" v-if="addprojectstepper === 2" @click="addProject()">Create</v-btn>
            </template>
        </lb-drawer>

        <lb-drawer v-model="addAIProjectDialog" heading="Create New AI Cycle" width="600" :loading="addprojectloading">
            <template v-slot:body>
                <div>
                    <v-stepper class="shadow-off pa-0 ma-0" v-model="addprojectstepper" vertical>
                        <v-stepper-step :complete="addprojectstepper > 1" step="1">
                            Basic Project Information
                        </v-stepper-step>
                        <v-stepper-content step="1">
                            <div class="mt-4">
                                <v-row>
                                    <v-col cols="6" class="my-0 py-1">
                                        <lb-date v-model="addeditproject.from" label="Period From*" :error="addeditprojecterror.from" />
                                    </v-col>
                                    <v-col cols="6" class="my-0 py-1">
                                        <lb-date v-model="addeditproject.to" label="Period To*" :error="addeditprojecterror.to" :min="addeditproject.from" />
                                    </v-col>
                                    <v-col cols="12" class="my-0 py-1">
                                        <lb-string v-model="addeditproject.description" label="Description*" :error="addeditprojecterror.description" />
                                    </v-col>
                                </v-row>
                            </div>
                        </v-stepper-content>
                        <v-stepper-step :complete="addprojectstepper > 2" step="2">
                            Tasklist configuration
                        </v-stepper-step>
                        <v-stepper-content step="2">
                            <div class="mt-4">
                                <lb-dropdown label="Populate Checklist*" v-model="checklistfrom" :error="addeditprojecterror.checklistfrom" :items="populateAIchecklistitems" itemtext="name" itemvalue="_id" :readonly='true' :clearable='false' />
                            </div>
                        </v-stepper-content>
                    </v-stepper>
                </div>
            </template>
            <template v-slot:actions>
                <span class="caption">*Required</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" v-if="addprojectstepper === 1 && addeditproject.from && addeditproject.to && addeditproject.description && addeditproject.description.trim() !== ''" small @click="addprojectstepper = 2">Continue</v-btn>
                <v-btn small class="shadow-off" text v-if="addprojectstepper === 2" @click="addprojectstepper = 1">Back</v-btn>
                <!-- <v-btn color="primary" v-if="addprojectstepper === 2" small @click="gotoUserConfig()">Continue</v-btn>
          <v-btn small class="shadow-off" text v-if="addprojectstepper === 3" @click="addprojectstepper = 2">Back</v-btn> -->
                <v-btn small color="primary" v-if="addprojectstepper === 2 && checklistfrom === 'genAI'" @click="createCheckListUsingGenAI()">Create</v-btn>
            </template>
        </lb-drawer>

        <lb-drawer v-model="editprojectdialog" heading="Edit Cycle" max-width="600" persistent>
            <template v-slot:body>

                <v-row>
                    <v-col cols="6" class="my-0 py-1">
                        <p class="pa-0 ma-0  text-subtitle-1 mb-1">Period From*</p>
                        <lb-date v-model="addeditproject.from" label="" :error="addeditprojecterror.from" :max="addeditproject.to" placeholder="Start Date" />
                    </v-col>
                    <v-col cols="6" class="my-0 py-1">
                        <p class="pa-0 ma-0  text-subtitle-1 mb-1">Period To*</p>
                        <lb-date v-model="addeditproject.to" label="" :error="addeditprojecterror.to" :min="addeditproject.from" :max="$nova.formatDateISO(new Date())" placeholder="End Date" />
                    </v-col>

                    <v-col cols="12" class="my-0 py-1">
                        <p class="pa-0 ma-0  text-subtitle-1 mb-1">Description*</p>
                        <lb-string v-model="addeditproject.description" label="" :error="addeditprojecterror.description" />
                    </v-col>
                </v-row>

            </template>
            <template v-slot:actions>
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click="editProject()">Update</v-btn>
            </template>

        </lb-drawer>
        <lb-drawer v-model="displayGenAIDataDialog" heading="Generated List" width="600" persistent>
            <template v-slot:body>
                <div>
                    <div class="align-center mb-3 mt-3">
                        <v-simple-table dense v-if="genAIData.length > 0">
                            <thead>
                                <tr>
                                    <th class="text-left">Description</th>
                                    <th class="text-left">Category</th>
                                    <th class="text-left">Sub category</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(v, k) in genAIData">
                                    <tr :key="k">
                                        <td>{{ v.description }}</td>
                                        <td>{{ v.category }}</td>
                                        <td>{{ v.subcategory }}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <v-divider></v-divider>
                </div>
            </template>
            <template v-slot:actions>
                <v-spacer></v-spacer>
                <v-btn small class="ml-2" color="primary" @click="addProject()">Create</v-btn>
            </template>
        </lb-drawer>
    </div>
</template>

<script>
    export default {
        name: "bookclosure_project_list",
        // props: ['props'],
        data: function () {
          return {
            right: "financialclose_project",
            userid: "",
            generateddescription: "",
            addeditproject: {},
            addeditprojecterror: {},
            addprojectdialog: false,
            addAIProjectDialog: false,
            checklistfrom: "",
            checklistfromvalue: null,
            addprojectstepper: 1,
            formdata: {},
            populatechecklistitems: [
              { _id: "blank", name: "Blank" },
              { _id: "checklistmaster", name: "Tasklist Master" },
              { _id: "previousproject", name: "Previous Project" },
            ],
            populateAIchecklistitems: [
              { _id: "genAI", name: "Generative AI" },
            ],
            addprojectloading: false,
            editprojectdialog: false,
            editprojectloading: false,
            loading: false,
            listdata: [],
            activitylogconfig: {
              userassociate: {
                icon: "mdi-plus",
                color: "info",
                displaytext: "User Associated",
              },
              userreplaced: {
                icon: "mdi-account-convert",
                color: "info",
                displaytext: "User Replaced",
              },
              userremoved: {
                icon: "mdi-close",
                color: "error",
                displaytext: "User Removed",
              },
              usermoved: { icon: "mdi-sync", color: "", displaytext: "Updated" },
              projectcreate: {
                icon: "mdi-plus",
                color: "info",
                displaytext: "Created",
              },
              projectopen: {
                icon: "mdi-lock-open-variant",
                color: "info",
                displaytext: "Opened",
              },
              projectclose: {
                icon: "mdi-lock",
                color: "success",
                displaytext: "Closed",
              },
              projectupdated: {
                icon: "mdi-pencil",
                color: "primary",
                displaytext: "Updated",
              },
              tbadd: {
                icon: "mdi-table-plus",
                color: "error",
                displaytext: "Trial Balance Added",
              },
              bulkpbcadd: {
                icon: "mdi-file-question-outline",
                color: "",
                displaytext: "Information Request Added",
              },
            },
            userdata: {},
      
            userlist: [],
            managerlist: [],
      
            tableHeaders: [
              {
                text: 'SL No',
                value: 'indexno',
                visible: true,
                filterable: false,
                sortable: false,
              },
              {
                value: "description",
                text: "Description",
                sortable: false,
                filterable: true,
                visible: true,
              },
              {
                value: "from",
                text: "Start Period",
                sortable: false,
                filterable: true,
                visible: true,
              },
              {
                value: "to",
                text: "End Period",
                sortable: false,
                filterable: true,
                visible: true,
              },
              {
                value: "stage",
                text: "Status",
                sortable: false,
                filterable: false,
                visible: false,
              },
              {
                value: "summary",
                text: "Tasklist Progress",
                sortable: false,
                filterable: false,
                visible: true,
              },
      
              {
                value: "created_at",
                text: "Created At",
                sortable: false,
                filterable: false,
                visible: false,
              },
              {
                value: "updated_at",
                text: "Updated At",
                sortable: false,
                filterable: false,
                visible: false,
              },
              {
                value: "action",
                text: "Action",
                sortable: false,
                filterable: false,
                visible: true,
              },
            ],
            formatedData: [],
            formatedDataCopy: [],
            displayGenAIDataDialog: false,
            genAIData: [],
            genAITableHeaders: [
              {
                value: "description",
                text: "Descritption",
              },
              {
                value: "category",
                text: "Category",
              },
              {
                value: "subcategory",
                text: "Sub Category",
              }
            ],
            isFirst: false,
            isPromptVisible: false,
            promptMessage: "This will help in linking your finance close task list with your Chart of Accounts and Trial Balance.",
            promptStyles: {
              top: "0",
              left: "0"
            },
            filterData: [],
            filterSelectedData: { "selectedyear": "All", "selectedmonth": "All", "selecteduser": "All" },
            timeFormat: {
              month: 'short',   // Short month name (e.g., Dec)
              day: 'numeric',   // Day of the month as a numeric value (e.g., 28)
              year: 'numeric',  // Full year (e.g., 2023)
              hour: 'numeric',  // Hour (e.g., 16)
              minute: 'numeric'
            },
            monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            filteredData: [],
            sectorsList: [],
            userSearch: "",
            itemsPerPage: 14,
            search: '',
            page: 1,
          };
        },
        components: {
        },
        created() {
          this.init();
          this.isChartOfAccountsSetup();
        },
        activated() {
          this.init();
        },
        methods: {
          handleRowClick(item) {
            console.log("qwert",item);
            localStorage.setItem("userObj", JSON.stringify(item));
            this.$store.commit('addItemToCycleList', item);
            this.$nova.gotoLink({ path: `/financialclose/project/dashboard/${item._id}` });
          },
          getItemData(item, key) {
            return item[key];
          },
          isChartOfAccountsSetup() {
            this.axios.post("/v2/financialclose/masters/chartofaccount/get")
              .then((dt) => {
                if (dt.data.status === "success") {
                  if (dt.data.data.length <= 0) {
                    this.isFirst = true
                  }
                  else {
                    this.isFirst = false
                  }
                }
                else {
                  this.formdata.push({ "name": "Chart of Account", "length": 0 })
                }
              }
              );
          },
          formateData() {
            let ObjectTemp = {};
            let ArrayTemp = [];
            this.filterData = { "created_year": new Set(), "created_month": new Set(), "userlist": new Set() };
            this.listdata.forEach((item, index) => {
              let date = new Date(item.from)
              this.filterData.created_year.add(date.getFullYear())
              this.filterData.created_month.add(this.monthNames[date.getMonth()])
              item.managerlist?.filter((user) => this.filterData.userlist.add(user))
              item.userlist?.filter((user) => this.filterData.userlist.add(user))
              ObjectTemp = {
                __key: index,
                __formatedkey: index,
                _id: item._id,
                indexno: index + 1,
                __click: "/financialclose/project/dashboard/" + item._id,
                type: item.type,
                category: item.category,
                description: item.description,
                files: item.files,
                nopbc: item.nopbc,
                pbcproject: item.pbcproject,
                from: item.from?.split("T")[0],
                to: item.to?.split("T")[0],
                stage: item.stage,
                summary: item.summary,
                created_at: item.created_at?.split("T")[0],
                updated_at: item.updated_at?.split("T")[0],
                year: date.getFullYear(),
                month: this.monthNames[date.getMonth()],
                status: item.status,
                managerlist: item.managerlist || [],
                conversation_read: item.conversation_read,
              };
              ArrayTemp.push(ObjectTemp);
            });
            this.formatedData = ArrayTemp;
            this.formatedDataCopy = ArrayTemp;
            this.filteredData = this.formatedData;
            this.filterData.created_year = Array.from(this.filterData.created_year);
            this.filterData.created_year.push('All');
            this.filterData.created_month = Array.from(this.filterData.created_month);
            this.filterData.created_month.push('All');
            this.filterData.userlist = Array.from(this.filterData.userlist);
            this.filterData.userlist.push('All');
          },
          init() {
            this.userid = this.$store.state.user.id;
            this.refreshData();
          },
          refreshData() {
            this.charts = [];
            this.chartdata = {};
            this.listdata = [];
            this.resetFilter();
            this.getData();
          },
          addProjectDialogBox() {
            this.addeditproject = { 'description': undefined, 'from': undefined, 'to': undefined };
            console.log(this.addeditproject)
            this.addprojectstepper = 1;
            this.checklistfrom = "checklistmaster";
            this.checklistfromvalue = null;
            this.addeditprojecterror = {};
            this.managerlist = [this.$store.state.user.id];
            this.addprojectdialog = true;
            console.log(this.$store.state.user);
          },
          addAIProjectDialogBox() {
            this.addeditproject = { 'description': undefined, 'from': undefined, 'to': undefined };
            this.addprojectstepper = 1;
            this.checklistfrom = "genAI";
            this.checklistfromvalue = null;
            this.addeditprojecterror = {};
            this.managerlist = [this.$store.state.user.id];
            this.addAIProjectDialog = true;
            console.log('project', this.addeditproject)
          },
          getData() {
            this.loading = true;
            Promise.all([this.getformdata(), this.getProjectsData()])
              .finally(() => {
                this.formateData();
                this.loading = false;
              });
          },
          getformdata() {
            return this.axios
              .post("/v2/financialclose/project/getformdata/")
              .then((dt) => {
                if (dt.data.status === "success") {
                  this.formdata = dt.data.data[0] || {};
                  this.formdata.users.push({ "name": this.$store.state.user.name, "_id": this.$store.state.user.id, "displayname": this.$store.state.user.name + "(" + this.$store.state.user.email + ")", "email": this.$store.state.user.email });
                  for (const i of this.formdata.users || []) {
                    this.userdata[i._id] = i;
                  }
                }
              }).catch((err) => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.log(err);
              });
          },
          getProjectsData() {
            return this.axios.post("/v2/financialclose/project/get").then((dt) => {
              this.listdata = [];
              if (dt.data.status === "success" && dt.data.data.length > 0) {
                this.listdata = dt.data.data;
                
              }
            })
              .catch((err) => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.log(err);
              });
          },
          createCheckListUsingGenAI() {
            this.addprojectloading = true
            this.axios
              .post("/v2/financialclose/project/generateaichecklist", { data: this.addeditproject })
              .then((dt) => {
                if (dt.data.status === "success") {
                  this.genAIData = dt.data.data[0].data;
                  this.addeditproject["data"] = dt.data.data[0].data;
                  this.displayGenAIDataDialog = true;
                } else {
                  if (dt.data.data.length > 0) {
                    if (dt.data.data[0].index0)
                      this.addeditprojecterror = dt.data.data[0].index0;
                    this.$store.commit(
                      "sbError",
                      dt.data.message || "Unknown error!"
                    );
                    this.addprojectstepper = 1;
                  } else throw new Error(dt.data.message);
                }
              })
              .catch((err) => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.log(err);
              })
              .finally(() => {
                this.addprojectloading = false;
              });
          },
          addProject() {
            if (this.checklistfrom != "") {
              this.displayGenAIDataDialog = false;
              this.addprojectloading = true;
              this.addeditproject.checklistfrom = this.checklistfrom;
              //console.log(this.newchecklistusers);
              this.axios
                .post("/v2/financialclose/project/add", { data: this.addeditproject })
                .then((dt) => {
                  if (dt.data.status === "success") {
                    this.$store.commit("sbSuccess", "New Analysis Created");
                    if (dt.data.data.length > 0) {
                      this.$nova.gotoLink({
                        path:
                          "/financialclose/project/checklist/" + dt.data.data[0]._id,
                      });
                    } else this.refreshData();
                  } else {
                    if (dt.data.data.length > 0) {
                      if (dt.data.data[0].index0)
                        this.addeditprojecterror = dt.data.data[0].index0;
                      this.$store.commit(
                        "sbError",
                        dt.data.message || "Unknown error!"
                      );
                      this.addprojectstepper = 1;
                    } else throw new Error(dt.data.message);
                  }
                }).then(()=>{
                  this.axios.post("/v2/financialclose/project/getmonth").then(dt => {
                    this.$store.commit("setNewList", dt.data.data)
                  })
                })
                .catch((err) => {
                  this.$store.commit("sbError", err.message || err || "Unknown error!");
                  console.log(err);
                })
                .finally(() => {
                  this.addprojectloading = false;
                });
            }
            else {
              this.addprojectloading = false;
              this.$store.commit("sbError", "Select Populate checklist");
            }
          },
          editProject() {
            this.editprojectloading = true;
            this.axios
              .post("/v2/financialclose/project/edit/" + this.addeditproject._id, {
                data: this.addeditproject,
              })
              .then((dt) => {
                if (dt.data.status === "success") {
                  this.$store.commit("sbSuccess", "Project Updated");
                  const index = this.listdata.findIndex(item => item._id === dt.data.data[0]._id);
                  if (index !== -1) {
                    this.listdata[index] = dt.data.data[0];
                  }
                  this.formateData();
                  this.editprojectdialog = false;
                  // this.refreshData();
                } else {
                  if (dt.data.data.length > 0) {
                    if (dt.data.data[0].index0)
                      this.addeditprojecterror = dt.data.data[0].index0;
                  } else throw new Error(dt.data.message);
                }
              })
              .catch((err) => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.log(err);
              })
              .finally(() => {
                this.editprojectloading = false;
              });
          },
          closeProject(k, i) {
            this.loading = true;
            this.axios
              .post("/v2/financialclose/project/close/" + i)
              .then((dt) => {
                if (dt.data.status === "success") {
                  this.$store.commit("sbSuccess", "Project Closed");
                  this.listdata[k].stage = 3;
                  this.listdata.push({});
                  this.listdata.pop();
                  this.formateData();
                } else throw new Error("Error closing project");
              })
              .catch((err) => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.log(err);
              })
              .finally(() => {
                this.loading = false;
              });
          },
          openProject(k, i) {
            this.loading = true;
            this.axios
              .post("/v2/financialclose/project/open/" + i)
              .then((dt) => {
                if (dt.data.status === "success") {
                  this.$store.commit("sbSuccess", "Project Re-opened");
                  this.listdata[k].stage = 0;
                  this.listdata.push({});
                  this.listdata.pop();
                  this.formateData();
                } else throw new Error("Error re-opening project");
              })
              .catch((err) => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.log(err);
              })
              .finally(() => {
                this.loading = false;
              });
          },
          generateDescription() {
            if (this.addeditproject.from && this.addeditproject.to) {
              if (
                !this.addeditproject.description ||
                this.generateddescription === this.addeditproject.description
              ) {
                this.generateddescription =
                  "Financial Close - " +
                  this.$nova.formatDate(this.addeditproject.from) +
                  " to " +
                  this.$nova.formatDate(this.addeditproject.to);
                this.addeditproject.description = this.generateddescription;
              }
            }
          },
          showPrompt() {
            // Get the position of the trigger button
            const xpathResult = document.evaluate("//div[contains(text(),'Chart of Accounts')]", document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
            const targetElement = xpathResult.singleNodeValue;
            if (targetElement) {
              // Calculate the position of the prompt relative to the target component
              const targetRect = targetElement.getBoundingClientRect();
              const promptTop = targetRect.bottom + window.scrollY + 10; // Adjust as needed
              const promptLeft = targetRect.left + window.scrollX;
              this.promptStyles = { top: `${promptTop}px`, left: `${promptLeft}px` };
            }
          },
          hidePrompt() {
            this.isFirst = false;
          },
          filter() {
            this.filteredData = []
            this.filteredData = this.formatedData;
            if (this.filterSelectedData.selectedyear !== "All") {
              this.filteredData = this.filteredData.filter((projectyear) => {
                if (projectyear.year === this.filterSelectedData.selectedyear) {
                  return projectyear;
                }
              });
            }
            if (this.filterSelectedData.selectedmonth !== "All") {
              this.filteredData = this.filteredData.filter((projectmonth) => {
                if (projectmonth.month === this.filterSelectedData.selectedmonth) {
                  return projectmonth;
                }
              });
            }
            if (this.filterSelectedData.selecteduser !== "All") {
              this.filteredData = this.filteredData.filter((project) => {
                let users = [];
                project.userlist?.filter((item) => users.push(item))
                project.managerlist?.filter((item) => users.push(item))
                users = new Set(users)
                users = Array.from(users)
                let isMatched = ""
                isMatched = users.filter((user) => {
                  if (user === this.filterSelectedData.selecteduser) {
                    this.filteredData.push(project)
                    return true
                  }
                })
                if (isMatched[0] === this.filterSelectedData.selecteduser) {
                  isMatched = false
                  return project
                }
              });
            }
            const uniqueSet = new Set();
            this.formatedData.forEach(obj => uniqueSet.add(JSON.stringify(obj))); // Convert object to string to ensure uniqueness
            this.formatedData = Array.from(uniqueSet, objString => JSON.parse(objString));
          },
          filterWithYear(year) {
            this.filterSelectedData.selectedyear = year
            this.filter()
          },
          filterByMonth(month) {
            this.filterSelectedData.selectedmonth = month
            this.filter();
          },
          filterByUser(user) {
            this.filterSelectedData.selecteduser = user
            this.filter();
          },
          resetFilter() {
            this.filterSelectedData.selecteduser = 'All'
            this.filterSelectedData.selectedmonth = 'All'
            this.filterSelectedData.selectedyear = 'All'
            this.filteredData = this.formatedData
          },
          // gotoUserConfig() {
          //   let url = "";
          //   let fltr = {};
          //   Promise.resolve().then(() => {
          //     this.addprojectloading = true;
          //     if (this.addeditproject.checklistfrom === "checklistmaster") {
          //       url = "/v2/financialclose/project/getmasteruserlist/";
          //       if(this.addeditproject.checklistfromvalue instanceof Array && this.addeditproject.checklistfromvalue.length > 0) fltr = { tags: this.addeditproject.checklistfromvalue };
          //       else throw Error("No tags selected");
          //     }
          //     else if (this.addeditproject.checklistfrom === "previousproject") {
          //       url = "/v2/financialclose/project/getprojectuserlist/" + this.addeditproject.checklistfromvalue;
          //       if(!this.addeditproject.checklistfromvalue) fltr = { project: this.addeditproject.checklistfromvalue };
          //       else throw Error("No project selected");
          //     }
          //     if (url) return this.axios.post(url, {filter: fltr});
          //     else throw new Error("Error getting the user information");
          //   }).then(dt => {
          //     if (dt.data.status === "success") {
          //       this.checklistusers = dt.data.data[0].users;
          //       this.informationowners = dt.data.data[0].infoowners;
          //       this.newchecklistusers = JSON.parse(JSON.stringify(this.checklistusers));
          //       this.newinformationowners = JSON.parse(JSON.stringify(this.informationowners));
          //       // for (const k in this.checklistusers) {
          //       //   if (Object.hasOwnProperty.call(this.checklistusers, k)) {
          //       //     const el = this.checklistusers[k];
          //       //     if(this.userlist.indexOf(el._id) === -1 && this.managerlist.indexOf(el._id) === -1) this.userlist.push(el._id);
          //       //   }
          //       // }
          //       this.addprojectstepper = 3;
          //     }
          //   }).catch(err => {
          //     this.$store.commit("sbError", err.message || err || "Unknown error!");
          //     console.log(err);
          //   }).finally(() => {
          //     this.loading = false;
          //     this.addprojectloading = false;
          //   });
          // },
          // updateUser(v, from, type="remove") {
          //   if(from === "manager"){
          //     let ui = this.managerlist.indexOf(v);
          //     if(ui > -1) {
          //       this.managerlist.splice(ui, 1);
          //       if(type === "move") this.userlist.push(v);
          //     }
          //   }
          //   else if(from === "general"){
          //     let ui = this.userlist.indexOf(v);
          //     if(ui > -1) {
          //       this.userlist.splice(ui, 1);
          //       if(type === "move") this.managerlist.push(v);
          //     }
          //   }
          // },
          filterPart(item,header){
            console.log(item,header ,this.formatedDataCopy);
            this.filteredData = this.$nova.filterByDynamicCriteria(this.formatedDataCopy, item);  
          },
        clearPart(item){
            console.log(item,"item");
            this.filteredData = this.formatedDataCopy;
          }
        },
        watch: {
          // userlist: function (d) {
          //   this.addeditproject.userlist = d;
          // },
          // managerlist: function (d) {
          //   this.addeditproject.managerlist = d;
          // },
          "addeditproject.from": function () {
            this.generateDescription();
          },
          "addeditproject.to": function () {
            this.generateDescription();
          },
          addprojectdialog: function (d) {
            if (!d) this.$nova.gotoLink({ hash: "" });
          },
          checklistfrom: function (d) {
            this.addeditproject.checklistfrom = d;
          },
          checklistfromvalue: function (d) {
            this.addeditproject.checklistfromvalue = d;
          },
          
        },
        computed: {
          filteredUserList() {
            return this.filterData.userlist.filter(user => {
              return this.userSearch === '' || (this.userdata[user] && this.userdata[user].name.toLowerCase().includes(this.userSearch.toLowerCase()));
            });
          },
          pagecount() {
            return Math.ceil(this.filteredData?.length / this.itemsPerPage);
          },
          paginatedFilteredItems() {
            const start = (this.page - 1) * this.itemsPerPage;
            const end = this.page * this.itemsPerPage;
            return this.filteredData?.slice(start, end);
          },
        }
      };
</script>

<style scoped>
    .balloon-prompt {
        position: absolute;
        background-color: #ffffff;
        border: 1px solid #ffffff;
        padding: px;
        border-radius: 3px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      }
      
      .close-btn-container {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 30px;
        height: 30px;
        background-color: #ccc;
        /* Gray color */
        border-radius: 50%;
        /* Circle shape */
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      
      .close-btn {
        color: #fff;
        font-size: 20px;
      }
      
      button {
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
      }
</style>