<template>
    <div class="all-width screen-height">
        <v-card class="all-width screen-height shadow-off" :loading="loading" :disabled="loading" loader-height="2">
            <v-card-text>
                <div class="d-flex align-center px-2 justify-space-between">
                    <div class="d-flex">
                        <h1 class="d-flex align-center ma-0 text-h5 black--text font-weight-bold">Chart of Account</h1>

                        <v-tooltip bottom content-class="tooltip-bottom" >
                        <template v-slot:activator="{ on, attrs }"> 
                            <v-btn 
                                v-bind="attrs"
                                v-on="on"
                                class="shadow-off ml-1" icon @click="refreshData()"><v-icon
                                size="24">mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                      </v-tooltip>
                      
                    </div>
                    <div class="d-flex align-center ma-0">

                        <bulkChartOfAccountUpload @reload="refreshData()" v-if="$nova.hasRight(right, 'add')" />
                        <v-btn small height="4vh" color="black" class="mr-1 px-4 border-dark white--text" @click="
                            addeditdialog = true;
                        addeditdata = {};
                        addeditdataerror = {};
                        locationUserList = [];
                        customassertions = false;
                        " v-if="$nova.hasRight(right, 'add')">
                            <v-icon class="pr-2">mdi-plus</v-icon>
                            <span class="text-capitalize">New Account</span>
                        </v-btn>
                    </div>
                </div>
                <div class="d-flex align-center px-2 justify-space-between my-3 ">

                    <lb-string placeholder="Search" label="" v-model="search" width="30vh" appendiconinner="mdi-magnify"
                        hidedetails :isClearable="false" radius="8px" />
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-tooltip left content-class="tooltip-left">
                              <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                              <v-btn icon v-bind="{ ...attrs, ...tooltipAttrs }" v-on="{ ...on, ...tooltipOn }"  class="ml-2 border-on"><v-icon color="caccounting">mdi-table-large-plus</v-icon></v-btn>
                              </template>
                              <span>Customize column</span>
                            </v-tooltip>
                          </template>

                        <v-list style="max-height:50vh ; overflow:hidden; z-index:13 !important"
                            class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width">
                            <v-list-title class="ma-0  all-width">
                                <div
                                    class="d-flex grayBackground align-center pa-2 pb-3 pt-3 pb-1 justify-space-between">
                                    <p class="ma-0 font-weight-bold">Customize Column</p>
                                </div>
                                <v-divider></v-divider>
                                <lb-string label="" placeholder="Search" class="px-2 mt-3 mb-2" v-model="searchcolumn"
                                    width="30vh" :clearable="false" appendiconinner="mdi-magnify" hidedetails
                                    radius="8px" />
                            </v-list-title>


                            <v-list-item style="max-height:25vh ; overflow:scroll"
                                class="d-flex all-width align-start pa-0 ma-0 flex-column">
                                <template v-for="(item, index) in filteredHeaders">
                                    <v-list-item  class="d-flex pa-0 px-2 ma-0 " v-if="item.text !== 'Action'"
                                    :key="index">
                                    <v-checkbox color="caccounting"  :ripple="false"
                                        class="pa-0 ma-0" @change="addTableHeader(item)" hide-details
                                        :input-value="item.visible" :label="item.text"></v-checkbox>
                                </v-list-item>
                                </template>

                            </v-list-item>

                        </v-list>
                    </v-menu>
                </div>


                <lb-datatableaction :headers="headers" :tabledata="formatedDataCopy" :itemsperpage="itemsPerPage"
                    :searchterm="search" :bulkActions="false" @filter-item="filterPart" @clear-item="clearPart">

                    <template v-slot:tbody>
                        <tr v-for="item in paginatedFilteredItems" :key="item._id" class="cursor-pointer  "
                            :class="{ 'low-opacity': item.status === false }">
                            <template v-for="header in headers">
                                <td v-if="header.visible && header.value !== 'action'" :key="header.value" :class="{'description-header': header.value === 'name'}"
                                    class="px-2 single-line" @click="viewchartAcc(item)">
                                    <div v-if="header.value === 'tasklist'">
                                        <span
                                            class="caccounting-background caccounting--text  radius-mini pa-2 mr-2">{{ getTaskName(item.tasklist[0]).ref }}
                                        </span>
                                        <span v-if="item.tasklist.length > 1 && item.tasklist?.[0]"
                                            class=" radius-mini border-on radius-mini text-caption pa-1 ">+{{
                                            item.tasklist.length - 1 }}</span>
                                    </div>
                                    <span v-else-if="header.value === 'location'">
                                        <span v-if="item[header.value].length===0" class="caccounting-background caccounting--text  radius-mini pa-2 mr-2"> Base</span>
                                        <div class="" v-else>
                                        <span v-if="getItemData(item, header.value)[0]"
                                            class="caccounting-background caccounting--text  radius-mini pa-2 mr-2">{{
                                            getItemData(item, header.value)[0] }} </span>
                                        <span v-if="getItemData(item, header.value).length > 1"
                                            class=" radius-mini border-on radius-mini text-caption pa-1  ">+{{
                                            getItemData(item, header.value).length -1 }}</span>
                                        </div>
                                    </span>
                                    <span v-else-if="header.value === 'created_at' || header.value === 'updated_at'"> {{
                                        getItemData(item, header.value)?.split('T')?.[0] }} </span>
                                    <span v-else> {{ getItemData(item, header.value) }}</span>
                                </td>
                                <td v-else-if="header.value === 'action'" :key="header.value"
                                    class=" px-3 single-line border-right border-left action-data">
                                    <v-tooltip bottom content-class="tooltip-bottom" >
                                        <template v-slot:activator="{ on, attrs }"> 
                                            <v-btn v-bind="attrs" v-on="on" icon @click="addEditForm(item)"><v-icon>mdi-pencil-outline</v-icon></v-btn>
                                        </template>
                                        <span>Edit</span>
                                      </v-tooltip>
                                      
                                      <v-tooltip bottom content-class="tooltip-bottom" v-if="item.status">
                                        <template v-slot:activator="{ on, attrs }"> 
                                        <v-btn  v-bind="attrs" v-on="on" icon @click="enableDisableAccess(item._id, 'disable')"><v-icon
                                            color="success">mdi-check-circle</v-icon></v-btn>
                                        </template>
                                        <span>Enable</span>
                                      </v-tooltip>

                                      <v-tooltip bottom content-class="tooltip-bottom"  v-else>
                                        <template v-slot:activator="{ on, attrs }"> 
                                            <v-btn v-bind="attrs" v-on="on" icon
                                                @click="enableDisableAccess(item._id, 'enable')"><v-icon
                                                    color="error">mdi-close-circle</v-icon></v-btn>
                                        </template>
                                        <span>Disable</span>
                                      </v-tooltip>

                                </td>
                                
                            </template>
                            

                        </tr>
                    </template>
                </lb-datatableaction>

                <Pagination :list="filteredItemsfromsearch" :itemsperpage="itemsPerPage" @paginated-data="setPaginatedData" />

            </v-card-text>
        </v-card>

        <lb-drawer v-model="addeditdialog" :heading="addeditdata._id ? 'Update' : 'Add New'" width="600"
            :loading="addeditloading">
            <template v-slot:body>
                <div>
                    <v-row>
                        <v-col cols="6" class="my-0 py-1">
                            <p class="ma-0 pa-0  text-subtitle-1 darkgray--text">Account No.*</p>
                            <lb-string v-model="addeditdata.glcode" label="" placeholder="Enter account number"
                                :error="addeditdataerror.glcode" :isRequired="true"  />
                        </v-col>
                        <v-col cols="6" class="my-0 py-1">
                            <p class="ma-0 pa-0  text-subtitle-1 darkgray--text">Type*</p>
                            <lb-dropdown v-model="addeditdata.type" label="" :error="addeditdataerror.type"
                                :items="possibletypes"  />
                        </v-col>
                        <v-col cols="12" class="my-0 py-1">
                            <p class="ma-0 pa-0  text-subtitle-1 darkgray--text">Account Name*</p>
                            <lb-textBox v-model="addeditdata.name" label="" requriedrows="3" 
                                placeholder="Enter account name" :isRequired="true" :error="addeditdataerror.name" />
                        </v-col>
                        <v-col cols="6" class="my-0 py-1">
                            <p class="ma-0 pa-0  text-subtitle-1 darkgray--text">Category*</p>
                            <lb-string v-model="addeditdata.category" label="" :error="addeditdataerror.category"
                                placeholder="Enter category"  :isRequired="true" />
                        </v-col>
                        <v-col cols="6" class="my-0 py-1">
                            <p class="ma-0 pa-0  text-subtitle-1 darkgray--text">Sub Category*</p>
                            <lb-string v-model="addeditdata.subcategory" label="" :error="addeditdataerror.subcategory"
                                placeholder="Enter sub category"  :isRequired="true" />
                        </v-col>
                        <v-col cols="12" class="my-0 py-1">
                            <p class="ma-0 pa-0  text-subtitle-1 darkgray--text">{{$store.state.LocationHeader|| 'Location'}}</p>
                            <lb-tagsInput tagcolor="purpleborder" :multiple="true" v-model="addeditdata.locationId"
                                placeholder="Select Location" :multiplechips="true" 
                                :items="locationNames" :error="addeditdataerror.locationId" />

                        </v-col>
                        <v-col cols="12" class="d-flex all-width align-center justify-space-between">
                            <p class="ma-0 pa-0 font-weight-bold">Linked tasks</p>


                            <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn text v-bind="attrs" v-on="on"> + Add
                                        Tasks</v-btn>
                                </template>

                                <v-card class="pa-0 ma-0 radius-small">
                                    <div class="d-flex align-center pa-2 grayBackground justify-space-between">
                                        <p class="ma-0">Select Task</p>
                                        <v-icon>mdi-information</v-icon>
                                    </div>
                                    <lb-string placeholder="Search" label="" v-model="searchTask" class="ma-2"
                                        :hidedetails="true"></lb-string>

                                    <v-simple-table dense class="border-on overflow-scroll ma-2 simple-table"
                                        style="max-height:35vh ;min-width:60vh;">
                                        <thead class="grayBackground">
                                                <th class="grayBackground pa-2  d-flex align-center justify-center   ">
                                                    <v-checkbox
                                                        v-model="selectAll"
                                                        @change="toggleSelectAll"
                                                        :indeterminate="selectItem.length >0 && selectItem.length < filtertasklist"
                                                        hide-details
                                                        class="ma-0"
                                                    ></v-checkbox>
                                                </th>
                                            <th class="grayBackground pa-2 ">Reference</th>
                                            <th class="grayBackground pa-2 ">Description</th>
                                            <th class="grayBackground pa-2 ">Category</th>
                                        </thead>
                                        <tbody>
                                            <template v-for="(item, index) in filtertasklist">
                                                <tr :key="index" class="cursor-pointer" >
                                                    <td>
                                                        <v-checkbox
                                                            v-model="selectedRows"
                                                            :value="item"
                                                            hide-details
                                                            color="caccounting"
                                                        ></v-checkbox>
                                                    </td>
                                                    <td>{{ item.ref }}</td>
                                                    <td class="single-line">{{ item.description }}</td>
                                                    <td>{{ item.category }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </v-simple-table>
                                    <v-divider></v-divider>
                                    <div class="d-flex align-center pa-1 justify-end">
                                        <v-btn class="primary" @click="addRows">Add</v-btn>
                                    </div>
                                </v-card>
                            </v-menu>
                        </v-col>
                        <v-col cols="12">
                            <v-simple-table class="border-on simple-table" v-if="tasklistlocation.length > 0">
                                <thead>
                                    <th class="grayBackground pa-2">Reference</th>
                                    <th class="grayBackground pa-2">Description</th>
                                    <th class="grayBackground pa-2">Category</th>
                                    <th class="grayBackground pa-2">Actions</th>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in tasklistlocation" :key="index">
                                        <td>{{ item.ref }}</td>
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.category }}</td>
                                        <td>
                                            <v-btn icon @click="removetasklist(item)">
                                                <v-icon color="error">mdi-delete</v-icon></v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </div>
            </template>
            <template v-slot:actions>
                <span class="caption">*Required</span>
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click="addEditItem()">
                    <span v-if="addeditdata._id">Update</span>
                    <span v-else>Add</span>
                </v-btn>
            </template>
        </lb-drawer>

        <lb-drawer v-model="viewchartaccdialog" heading="Account Details" width="800">
            <template v-slot:body>
                <div>
                    <div class="d-flex body-1 align-center justify-start mb-3">
                        <span
                            class="caccounting-background caccounting--text px-2 py-1 radius-small font-weight-bold mr-3">{{
                                viewchartaccdata.glcode }}</span>
                        <div class="font-weight-bold black--text">{{ viewchartaccdata.name || "" }}</div>
                    </div>
                    <div class="">
                        <v-row>
                            <v-col cols="3"> Type</v-col>
                            <v-col cols="9"> <span class="gray-background pa-1 radius-mini black--text">{{
                                viewchartaccdata.type || "" }} </span> </v-col>
                            <v-col cols="3"> Location</v-col>
                            <v-col cols="9" class="d-flex">
                                <div class="mr-2" v-for="(item, index) in viewchartaccdata.location " :key="index">
                                    <span class="caccounting-background caccounting--text pa-1 radius-mini">{{ item }}
                                    </span>
                                </div>
                            </v-col>
                            <v-col cols="3"> Category</v-col>
                            <v-col cols="9"> <span class="gray-background pa-1 radius-mini black--text">{{
                                viewchartaccdata.category || "" }} </span></v-col>
                            <v-col cols="3"> Sub Category</v-col>
                            <v-col cols="9"> <span class="gray-background pa-1 radius-mini black--text">{{
                                viewchartaccdata.subcategory || "" }} </span></v-col>
                        </v-row>
                    </div>
                    <div class="">
                        <v-row>
                            <v-col cols="12" class="mt-0">
                                <div class="font-weight-bold  py-1">
                                    <div class="d-flex align-center">
                                        <div class="text-body-1 black--text pt-2">Linked Task</div>
                                        <v-spacer></v-spacer>
                                        <div v-if="(viewchartaccdata.checklist_id_detail || []).length > 0">
                                            <span class="font-wight-bold">Total Count:</span>
                                            {{ viewchartaccdata.checklist_id_detail.length }}
                                        </div>
                                    </div>
                                </div>
                                <v-simple-table dense class=" mb-2 border-on radius-small simple-table"
                                    v-if="(viewchartaccdata.checklist_id_detail || []).length > 0">
                                    <template v-slot:default>
                                        <thead>
                                            <th class="text-left text-body-1 black--text grayBackground py-2 px-3">Ref
                                            </th>
                                            <th class="text-left text-body-1 black--text grayBackground py-2 px-3">
                                                Description</th>
                                            <th class="text-left text-body-1 black--text grayBackground py-2 px-3">
                                                Category</th>
                                        </thead>
                                        <v-slide-y-transition class="" group tag="tbody"
                                            style="overflow:hidden !important;">
                                            <tr v-for="(v, k) in viewchartaccdata.checklist_id_detail" :key="k"
                                                class="">
                                                <td class="pa-3"> <span
                                                        class="caccounting-background caccounting--text pa-2 radius-mini">{{
                                                        v.ref }}</span>
                                                </td>
                                                <td class="">{{ v.description }}</td>
                                                <td class="">{{ v.category }}</td>
                                            </tr>
                                        </v-slide-y-transition>
                                    </template>
                                </v-simple-table>
                                <div v-else class="grey--text px-4 mb-2">No Data</div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </lb-drawer>
    </div>
</template>

<script>
import Pagination from "../../../../views/common/Pagination.vue";
import bulkChartOfAccountUpload from "../../views/Components/BulkUploadChartOfAccount.vue";
export default {
    name: "financialclose_master_chartofaccount",
    // props: ['props'],
    data: function () {
        return {
            right: "financialclose_master_chartofaccount",
            // generateddescription: "",
            tableheader: [
                { text: "GL Code", value: "glcode" },
                { text: "Name", value: "name" },
                { text: "Type", value: "type" },
                { text: "Category", value: "category" },
                { text: "Sub Category", value: "subcategory" },
                { text: "Tasklist", value: "checklist_id" },
                { text: "Action", value: "action", align: "center", sortable: false },
            ],
            possibletypes: [
                "Asset",
                "Equity",
                "Liability",
                "Income",
                "Expense",
                "Others",
            ],
            addeditdata: {},
            addeditdataerror: {},
            addeditdialog: false,
            checklist: [],
            addeditloading: false,
            loading: false,
            listdata: [],
            headers: [

                {
                    value: "glcode",
                    text: "Account No.",
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    value: "name",
                    text: "Account Name",
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    value: "type",
                    text: "Type",
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    value: "category",
                    text: "Category",
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    value: "subcategory",
                    text: "Sub Category",
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    value: "tasklist",
                    text: "Task Linked",
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
                {
                    value: "location",
                    text:this.$store.state.LocationHeader|| 'Location',
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
                {
                    value: "created_at",
                    text: "Created On",
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    value: "updated_at",
                    text: "Updated On",
                    visible: true,
                   filterable: true,
                    sortable: true,
                },
                {
                    value: "action",
                    text: "Action",
                    sticky: true,
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
            ],
            formatedData: [],
            formatedDataCopy: [],
            selectchecklistdialog: false,
            selectchecklist: [],
            searchcustom: '',
            checklistIds: [],
            viewchartaccdialog: false,
            viewchartaccdata: {},
            filteredItems: [],
            locationItem: [],
            searchTask: "",
            tasklistlocation: [],
            locationNames: [],
            itemsPerPage: 13,
            search: '',
            selectedFilterItems: [],
            selectItem: [],
            searchcolumn: '',
            selectlocation_id: '',
            selectchecklist_id: '',
            selectedRows: [],
            selectAll: false,
            paginatedFilteredItems:[]
        };
    },
    created() {
        this.init();
    },
    activated() {
        this.init();
    },
    components: {
        bulkChartOfAccountUpload,Pagination
    },
    computed: {
        filtertasklist() {
            return this.checklist.filter(item =>
                item.description.toLowerCase().includes(this.searchTask.toLowerCase())
            );
        },
        filteredItemsfromsearch() {
            let filteredData = this.formatedData.filter(item =>
                item.name.toLowerCase().includes(this.search?.trim()?.toLowerCase())
            );
            return filteredData;
        },
        filteredHeaders() {
            const searchTerm = this.searchcolumn.toLowerCase();
            return this.headers.filter(header =>
                header.text.toLowerCase()?.includes(searchTerm)
            );
        },
    },
    methods: {
        setPaginatedData(paginatedData) {
            this.paginatedFilteredItems = paginatedData; 
        },
        toggleSelectAll() {
            this.selectedRows = this.selectAll ? [...this.selectedRows,...this.filtertasklist] : [];
        },
        addRows() {
            this.tasklistlocation=this.selectedRows
            this.addeditdata.checklist_id_detail=this.selectedRows
            this.checklistIds= this.selectedRows.map((item)=> item._id)            
        },
        formateData() {
            let ObjectTemp = {};
            let ArrayTemp = [];
            console.log(this.listdata, "this.listdata");
            this.listdata.forEach((item, index) => {
                ObjectTemp = {
                    __key: index,
                    __formatedkey: index,
                    _id: item._id,
                    indexno: index + 1,
                    glcode: item.glcode,
                    name: item.name,
                    type: item.type,
                    category: item.category,
                    subcategory: item.subcategory,
                    tasklist: item.checklist_id,
                    checklist_id_detail: item.checklist_id_detail,
                    location: (item.location || []).map(item => item.name),
                    created_at: item.created_at?.split("T")[0],
                    updated_at: item.updated_at?.split("T")[0],
                    status: item.status,
                    __click: this.viewchartAcc,
                };
                ArrayTemp.push(ObjectTemp);
            });
            this.formatedData = ArrayTemp;
            this.formatedDataCopy = ArrayTemp;
        },
        init() {
            this.refreshData();
        },
        refreshData() {
            this.charts = [];
            this.chartdata = {};
            this.listdata = [];
            this.getData();
        },
        getData() {
            this.loading = true;
            return this.axios
                .post("/v2/financialclose/masters/chartofaccount/formData")
                .then((dt) => {
                    if (dt.data.status === "success") {
                        let formdt = dt.data.data[0] || {};
                        console.log(formdt);
                        this.checklist = formdt.checklist || [];
                        return this.axios.post(
                            "/v2/financialclose/masters/chartofaccount/get"
                        );
                    } else throw new Error("Error reading form data");
                })
                .then((dt) => {
                    this.listdata = [];
                    if (dt.data.status === "success") {
                        this.listdata = dt.data.data;
                        this.formateData();
                    } else throw new Error(dt.data.message);
                })
                .then(() => {
                    this.axios
                        .post("/v2/financialclose/location/get")
                        .then((ele) => {
                            this.locationItem = ele.data.data;
                            this.locationNames = ele.data.data.map((item) => item.name);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addTableHeader(item) {
            this.headers.find((x) => x === item ? x.visible = !x.visible : "")
        },
        addEditForm(dt = {}) {
            this.addeditdata = { ...dt };
            this.addeditdata.checklist_id = { ...dt.tasklist };
            let filteredLocations = this.locationItem.filter(location => this.addeditdata && this.addeditdata.location.includes(location.name));
            let locationObjects = filteredLocations.map(location => (location.name));
            this.addeditdata.locationId = locationObjects;
            this.addeditdataerror = {};
            this.tasklistlocation = this.addeditdata.checklist_id_detail;
            this.selectedRows=this.addeditdata.checklist_id_detail
            this.addeditdialog = true;
        },
        locationmapping() {
            const filteredLocations = this.locationItem.filter(obj =>
                this.addeditdata.locationId.includes(obj.name)
            );
            this.addeditdata.locationId = filteredLocations.map((item) => item._id)
        },
        addEditItem() {
            this.locationmapping();
            this.addeditdata.checklist_id_detail = this.addeditdata.checklist_id_detail || [];
            this.addeditdata.checklist_id = this.checklistIds;
            let filteredLocations = [];
            if (this.addeditdata && this.addeditdata.locationId && Array.isArray(this.locationItem)) {
                filteredLocations = this.locationItem.filter(location => {
                    return this.addeditdata.locationId.includes(location && location._id);
                });
            }
            let locationObjects = filteredLocations.map(location => ({ name: location.name, _id: location._id }));
            this.addeditdata.location = locationObjects.length===0? ["base"]:locationObjects;
            this.addeditloading = true;
            let type = this.addeditdata._id ? "edit" : "add";
            let url = this.addeditdata._id ? `/v2/financialclose/masters/chartofaccount/edit/${this.addeditdata._id}` : "/v2/financialclose/masters/chartofaccount/add";
            let ndt = { ...this.addeditdata };
            delete ndt._id;
            this.axios
                .post(url, { data: ndt })
                .then((dt) => {
                    if (dt.data.status === "success") {
                        this.$store.commit("sbSuccess", type === "add" ? "New Account added" : "Account updated");
                        this.addeditdialog = false;
                        this.refreshData();
                    } else {
                        if (dt.data.data.length > 0 && dt.data.data[0].index0)
                            this.addeditdataerror = dt.data.data[0].index0;
                        else
                            throw new Error(dt.data.message);
                    }
                })
                .catch((err) => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                    console.log(err);
                })
                .finally(() => {
                    this.addeditloading = false;
                });
        },
        enableDisableAccess(id, action) {
            this.loading = true;
            let url = "/v2/financialclose/masters/chartofaccount/disable/";
            if (action === "enable") {
                url = "/v2/financialclose/masters/chartofaccount/enable/";
            }
            this.axios
                .post(url + id, {})
                .then((dt) => {
                    if (dt.data.status === "success") {
                        if (action === "enable") {
                            this.$store.commit("sbSuccess", "Item enabled");
                            // this.listdata[k].status = true;
                        } else {
                            this.$store.commit("sbSuccess", "Item disabled");
                            // this.listdata[k].status = false;
                        }
                        this.refreshData();
                    } else throw new Error(dt.data.message || "Error performing action");
                })
                .catch((err) => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        viewchartAcc(item) {
            this.viewchartaccdata = { ...item };
            console.log(this.viewchartaccdata, "this.viewchecklistdata");
            this.viewchartaccdialog = true;
            //console.log(item);
        },
        getItemData(item, key) {
            return item[key];
        },
        getTaskName(taskId) {
            const task = this.checklist.find((task) => task._id === taskId);
            return task ? task : "";
        },
        removetasklist(item) {
            let indexItem = this.tasklistlocation.indexOf(item);
            this.tasklistlocation.splice(indexItem, 1);
            this.addeditdata.checklist_id_detail = this.addeditdata.checklist_id_detail.filter((x) => x._id !== item)
        },
    filterPart(item,header){
        console.log(item,header);
        this.formatedData = this.$nova.filterByDynamicCriteria(this.formatedDataCopy, item);  
      },
    clearPart(item){
        console.log(item,"item");
        this.formatedData = this.formatedDataCopy;
      }
    },
    watch: {},
};
</script>