<template>
    <div class="screen-height">
        <v-card :loading="loading" :disabled="loading" loader-height="2" class="screen-height shadow-off all-width">
            <v-card-text>


                <div class="d-flex align-center justify-space-between ">
                    <div class="">
                        <div class="d-flex">
                            <p class="mb-1">Home</p>
                            <p class="px-2 mb-1">/</p>
                            <p class="mb-1">{{ metadata?.description }}</p>
                        </div>
                        <div class="d-flex">
                            <p class="black--text font-weight-bold text-h5">Threshold</p>
                            <v-tooltip bottom content-class="tooltip-bottom" >
                                <template v-slot:activator="{ on, attrs }"> 
                                    <v-btn 
                                        v-bind="attrs"
                                        v-on="on"
                                        class="shadow-off ml-1" icon @click="refreshData()"><v-icon
                                        size="24">mdi-refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>Refresh</span>
                              </v-tooltip>
                        </div>
                    </div>
                    <div class="d-flex " >
                        <v-btn small color="black" class="mr-1 white--text" height="4vh" @click="addEditDialogue"
                            v-if="$nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add')">
                            <v-icon class="mr-1">mdi-plus</v-icon>
                            <span>New</span>
                        </v-btn>
                    </div>
                </div>

                <lb-datatableaction v-if="paginatedItems.length>0" :headers="headers" :tabledata="formatedDataCopy"
                :itemsperpage="itemsPerPage" searchterm="" :bulkActions="false"  @filter-item="filterPart" @clear-item="clearPart">

                <template v-slot:tbody>
                    <tr v-for="item in paginatedItems" :key="item._id" class="cursor-pointer  "
                        :class="{ 'low-opacity': item.status === false }">
                        <template v-for="header in headers">
                            <td v-if="header.visible && header.value !== 'action'" :key="header.value"
                                class="px-2 single-line"
                                :class="{ 'description-header': header.value === 'description', 'single-line': header.value !== 'description' }">
                            {{ item[header.value] }}
                            </td>
                        </template>
                        <td class="d-flex align-center px-3  border-right border-left action-data">
                            <div class="d-flex align-center justify-center">
                                <v-tooltip bottom content-class="tooltip-bottom" v-if="$nova.hasRight(right, 'edit')|| $nova.hasRight(right, 'add')">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon small v-bind="attrs" v-on="on" @click.stop="editThreshold(item)">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Edit</span>
                                </v-tooltip>
                                <v-tooltip bottom content-class="tooltip-bottom" v-if="!item.status">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="error">mdi-close-circle</v-icon>
                                    </template>
                                    <span>Disable</span>
                                  </v-tooltip>
                                  <v-tooltip bottom content-class="tooltip-bottom" v-else>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                                    </template>
                                    <span>Enable</span>
                                </v-tooltip>
                            </div>
                        </td>
                    </tr>
                </template>
                </lb-datatableaction>
                
                <div class="d-flex align-center justify-center" v-else>
                    NO DATA
                </div>

                <Pagination :list="formatedData" :itemsperpage="itemsPerPage" @paginated-data="setPaginatedData" />

            </v-card-text>
        </v-card>

        <lb-drawer v-model="locationdialog" :heading="locationdata._id ? 'Update Threshold' : 'Add Threshold'"
            width="600" :loading="locationdialogloading">
            <template v-slot:body>
                <div>
                    <v-row>
                        <v-col cols="12" class="my-0 py-1">
                            <p class="ma-0 mb-1">{{$store.state.LocationHeader ? $store.state.LocationHeader : 'Location'}}</p>
                            <lb-dropdown v-model="locationdata.name" :items="locationList"
                                label=""
                                :error="locationdataerror.location" itemtext="name" itemvalue="value"
                                :readonly="locationdata._id ? true : false" :clearable="locationdata._id ? false : true" />
                        </v-col>
                        <v-col cols="12" class="my-0 py-1">
                            <p class="ma-0 mb-1">Threshold</p>
                            <lb-number v-model="locationdata.threshold" label=""
                                :error="locationdataerror.threshold" min="0" />
                        </v-col>
                    </v-row>
                </div>
            </template>
            <template v-slot:actions>
                <v-switch :label="enableSwitch === true ? 'Enable' : ' Disable'" class="mt-0" v-model="enableSwitch"
                    @onchange="toggleSwitch(locationdata)" inset hide-details></v-switch>
                <v-spacer></v-spacer>
                <v-btn small color="primary">
                    <span v-if="locationdata._id" @click="updateThreshold(locationdata)">Update</span>
                    <span v-else @click="addThreshold()">Add</span>
                </v-btn>
            </template>
        </lb-drawer>
    </div>
</template>

<script>
import Pagination from '../../../../views/common/Pagination.vue';
export default {
    data() {
        return {
            right: "financialclose_project",
            locationdialog: false,
            locationdialogloading: false,
            locationdata: {},
            locationdataerror: {},
            loading: false,
            projectId: "",
            locationtypeItem: [],
            formatedData: [],
            formatedDataCopy:[],
            locationListdata: [],
            locationList: [],
            locationtypedialog: false,
            headers: [
                {
                    value: "name",
                    text: "Name",
                    sortable: true,
                    filterable: true,
                    visible: true,
                },
                {
                    value: "threshold",
                    text: "Threshold",
                    sortable: true,
                    filterable: true,
                    visible: true,
                },
                {
                    value: "action",
                    text: "Action",
                    sortable: false,
                    filterable:false,
                    visible: true,
                },
            ],
            listdata: [],
            enableSwitch: true,
            metadata:[],
            itemsPerPage:16,
            paginatedItems:[]
            
        };
    },
    created() {
        this.refreshData();
    },
    components: {
        Pagination,
    },
    activated() {
        this.refreshData();
    },
    methods: {
        setPaginatedData(paginatedData) {
            this.paginatedItems = paginatedData; 
        },
        refreshData() {
            this.projectId = this.$route.params.id || "";
            this.getData();
        },
        formateData() {
            let ObjectTemp = {};
            let ArrayTemp = [];
            this.listdata.forEach((item, index) => {
                ObjectTemp = {
                    __key: index,
                    __formatedkey: index,
                    _id: item._id,
                    indexno: index + 1,
                    name: item.locationName,
                    locationId: item.locationId,
                    threshold: item.threshold,
                    created_at: item.created_at,
                    updated_at: item.updated_at,
                    status: item.status,
                };
                ArrayTemp.push(ObjectTemp);
            });
            this.formatedData = ArrayTemp;
            this.formatedDataCopy = ArrayTemp;
        },
        addEditDialogue() {
            this.locationdialog = true;
            this.locationdata = { threshold: 0 };
            this.locationdataerror = {};
            this.locationUserList = [];
            this.customassertions = false;
        },
        async getData() {
            this.loading = true;
            await this.axios
                .post("/v2/financialclose/project/threshold/get/" + this.projectId)
                .then(async (ele) => {
                    if (ele.data.status === "success") {
                        this.listdata = ele.data.data;
                        await this.axios
                            .post("/v2/financialclose/location/get")
                            .then((ele) => {
                                if (ele.data.status === "success") {
                                    this.locationListdata = ele.data.data;
                                    this.locationListdata.map(item => this.locationList.push(item.name))
                                    this.listdata = this.listdata.filter(obj1 => {
                                        return this.locationListdata.filter(obj2 => {
                                            if (obj2._id === obj1.location) {
                                                obj1.locationName = obj2.name
                                                return obj1;
                                            }
                                        });
                                    })
                                    this.formateData()
                                }
                            })
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addThreshold() {
            this.locationdialogloading = true;
            let locationId = this.locationListdata.filter((item) => {
                if (item.name === this.locationdata.name) {
                    return item._id;
                }
            })
            this.locationdata.location = locationId[0]?._id || ""
            this.locationdata = { ...this.locationdata, 'status': this.enableSwitch }
            this.axios
                .post("/v2/financialclose/project/threshold/add/" + this.projectId, { data: this.locationdata })
                .then((ele) => {
                    if (ele.data.status === "success") {
                        this.locationdialog = false;
                        this.$store.commit("sbSuccess", ele.data.message);
                        this.refreshData();
                    } else {
                        if (ele.data.data.length > 0) {
                            if (ele.data.data[0].index0)
                                this.locationdataerror = ele.data.data[0].index0;
                        } else throw new Error(ele.data.message);
                    }
                })
                .catch((err) => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                })
                .finally(() => {
                    this.locationdialogloading = false;
                });
        },
        editThreshold(item) {
            this.locationdata = { ...item };
            this.locationdialog = true;
            this.enableSwitch = item.status
            this.locationdataerror = {};
        },
        updateThreshold(item) {
            this.locationdialogloading = true;
            item.status = this.enableSwitch
            this.axios
                .post("v2/financialclose/project/threshold/edit/" + item._id, { data: item })
                .then((ele) => {
                    if (ele.data.status === "success") {
                        this.locationdialog = false;
                        this.$store.commit("sbSuccess", ele.data.message);
                        this.refreshData();
                    } else {
                        if (ele.data.data.length > 0) {
                            if (ele.data.data[0].index0)
                                this.locationdataerror = ele.data.data[0].index0;
                        } else throw new Error(ele.data.message);
                    }
                })
                .catch((err) => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                })
                .finally(() => {
                    this.locationdialogloading = false;
                });
        },
        toggleSwitch(item) {
            item.status = this.enableSwitch
        },
    filterPart(item,header){
        console.log(item,header);
        this.formatedData = this.$nova.filterByDynamicCriteria(this.formatedDataCopy, item);  
      },
    clearPart(item){
        console.log(item,"item");
        this.formatedData = this.formatedDataCopy;
      }
    },

};
</script>

<style></style>