<template>
  <div>
    <div class="screen-height d-flex justify-center">
      <div class="all-width">
        <v-card loader-height="2" class="shadow-off" :loading="loading" :disabled="loading">


          <div class="d-flex align-center justify-space-between px-4 pt-3">
            <div class="">
              <div class="d-flex">
                <p class="mb-1">Home</p>
                <p class="px-2 mb-1">/</p>
                <p class="mb-1">{{ metadata?.description }}</p>
              </div>
              <div class="d-flex">
                <p class="black--text font-weight-bold text-h5">Information Manager</p>
                <v-tooltip bottom content-class="tooltip-bottom" >
                  <template v-slot:activator="{ on, attrs }"> 
                      <v-btn 
                          v-bind="attrs"
                          v-on="on"
                          class="shadow-off ml-1" icon @click="refreshData()"><v-icon
                          size="24">mdi-refresh</v-icon>
                      </v-btn>
                  </template>
                  <span>Refresh</span>
                </v-tooltip>
              </div>
            </div>
            <div class="d-flex ">
              <v-btn small class="mr-1 border-dark" v-if="requestlist.length > 0" height="4vh"
                @click="downloadAllRequest()"><v-icon>mdi-download</v-icon>Download All</v-btn>
              <v-btn small color="black" class="mr-1 white--text" height="4vh"
                v-if="data.stage === 0 && ($nova.hasRight(right, 'add') || $nova.hasRight(right, 'edit'))"
                @click="$nova.gotoLink({ hash: 'add' })">
                <v-icon class="mr-1">mdi-plus</v-icon>
                <span>New Request</span>
              </v-btn>
            </div>
          </div>


          <v-card-text class="d-flex align-center pt-0" v-if="!loading">
            <div class="d-flex all-width align-center justify-space-between" v-if="!loading || metadata._id">
              <v-card
                class="lightgray gray--text all-width text-h6 mx-2 d-flex align-center justify-space-between radius-small shadow-off pa-4"
                height="8vh" @click="selectedTab = null">
                <span class="" style="font-size: 13px !important; font-weight:550">Total Request </span>
                <span class="gray--text font-size-font text-h4 font-weight-bold ">{{ totalrequests || 0 }}</span>
              </v-card>

              <v-card
                class="lightyellow yellow--text all-width text-h6 mx-2 d-flex align-center justify-space-between radius-small shadow-off pa-4"
                height="8vh" @click="selectedTab = 0">
                <span class="" style="font-size: 13px !important; font-weight:550">Pending Submissions</span>
                <span class="yellow--text font-size-font text-h4 font-weight-bold ">{{ (data.summary || {}).requested ||
                  0 }}</span>
              </v-card>

              <v-card
                class="lightblue blue--text all-width text-h6 mx-2 d-flex align-center justify-space-between radius-small shadow-off pa-4"
                height="8vh" @click="selectedTab = 1">
                <span class="" style="font-size: 13px !important; font-weight:550">Pending Approvals</span>
                <span class="blue--text font-size-font text-h4 font-weight-bold ">{{ (data.summary || {}).pending || 0
                  }}</span>
              </v-card>
              <v-card
                class="lightred error--text all-width text-h6 mx-2 d-flex align-center justify-space-between radius-small shadow-off pa-4"
                height="8vh" @click="selectedTab = -1">
                <span class="" style="font-size: 13px !important; font-weight:550">Cancelled</span>
                <span class="error--text font-size-font text-h4 font-weight-bold ">{{ (data.summary || {}).cancelled ||
                  0 }}</span>
              </v-card>

              <v-card
                class="lightgreen green--text all-width text-h6 mx-2 d-flex align-center justify-space-between radius-small shadow-off pa-4"
                height="8vh" @click="selectedTab = 2">
                <span class="" style="font-size: 13px !important; font-weight:550">Closed Tasks</span>
                <span class="green--text font-size-font text-h4 font-weight-bold ">{{ (data.summary || {}).closed || 0
                  }}</span>
              </v-card>
            </div>


          </v-card-text>
          <div class="d-flex align-center justify-end mx-4 mb-2">

            <v-slide-x-reverse-transition>
              <lb-string v-if="showSearch" width="30vh" v-model="searchQuery" placeholder="Search.." hidedetails
                label=""  :isClearable="false"/>
            </v-slide-x-reverse-transition>
            <v-tooltip  bottom content-class="tooltip-bottom">
              <template v-slot:activator="{ on, attrs }">  
                  <v-btn v-bind="attrs"
                  v-on="on" icon @click="showSearch=!showSearch">
                      <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                  </template>
                  <span>Search</span>
              </v-tooltip>

            <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition" v-if="selectItem.length>0">

              <template v-slot:activator="{ on, attrs }">
                <v-tooltip bottom content-class="tooltip-bottom">
                    <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                        <v-btn icon v-bind="{ ...attrs, ...tooltipAttrs }" v-on="{ ...on, ...tooltipOn }"  >
                            <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                    </template>
                  <span>Bulk Actions</span>
                </v-tooltip>
              </template>


              <v-list      class="pa-0 ma-0 d-flex flex-column all-wdth align-start justify-start all-width">
               <v-list-item class="grayBackground all-width font-weight-bold">Bulk  Actions</v-list-item>
               <v-list-item @click="addinfoownerdialog=true"> <v-icon class="mr-2">mdi-pencil-outline</v-icon> Edit Information owner</v-list-item>
              </v-list>
            </v-menu>

            <v-tooltip  bottom content-class="tooltip-bottom">
              <template v-slot:activator="{ on, attrs }">  
              <v-btn v-bind="attrs"
              v-on="on" icon large @click="infoownersummarydialog = true"> <v-icon>mdi-newspaper-variant-outline</v-icon>
              </v-btn>
                </template>
                <span>Information Owner</span>
            </v-tooltip>

            <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-tooltip left content-class="tooltip-left">
                  <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                  <v-btn icon v-bind="{ ...attrs, ...tooltipAttrs }" v-on="{ ...on, ...tooltipOn }"  class="ml-2 border-on"><v-icon color="caccounting">mdi-table-large-plus</v-icon></v-btn>
                  </template>
                  <span>Customize column</span>
                </v-tooltip>
              </template>

              <v-list style="max-height:50vh ; overflow:hidden; z-index:13 !important"
                class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width">
                <v-list-title class="ma-0  all-width">
                  <div class="d-flex grayBackground align-center pa-2 pb-3 pt-3 pb-1 justify-space-between">
                    <p class="ma-0 font-weight-bold">Customize Column</p>
                  </div>
                  <v-divider></v-divider>
                  <lb-string label="" placeholder="Search" class="px-2 mt-3 mb-2" v-model="searchcolumn" width="30vh"
                    :isClearable="false" appendiconinner="mdi-magnify" hidedetails radius="8px" />
                </v-list-title>
                <v-list-item style="max-height:25vh ; overflow:scroll"
                  class="d-flex all-width align-start pa-0 ma-0 flex-column">
                  <template v-for="(item, index) in filteredHeaders">
                    <v-list-item  class="d-flex pa-0 px-2 ma-0 " :key="index" v-if="item.text !== 'Action'">
                    <v-checkbox color="caccounting"  :ripple="false" class="pa-0 ma-0"
                      @change="addTableHeader(item)" hide-details :input-value="item.visible"
                      :label="item.text"></v-checkbox>
                  </v-list-item>
                  </template>

                </v-list-item>

              </v-list>
            </v-menu>


          </div>
          <v-card-text v-if="loading">
            <v-skeleton-loader type="table-heading, list-item-two-line, article, article"></v-skeleton-loader>
          </v-card-text>
          <v-card-text class="pt-0" v-else>

            <!-- v-if="requestlist.length > 0 && counter > 0" -->

            <lb-datatableaction v-if="paginatedItems.length > 0" :headers="tableheaders" :tabledata="requestlistCopy"
              itemsperpage="" :searchterm="searchQuery" @select-all-change="select"
              :isDataSelected="selectItem.length > 0 && selectItem.length < paginatedItems.length"  @filter-item="filterPart" @clear-item="clearPart">

              <template v-slot:tbody>
                <tr v-for="(item, index) in paginatedItems" :key="item._id" class="cursor-pointer  "
                  :class="{ 'low-opacity': item.status === false }">
                  <td class=" px-2 d-flex align-center justify-center">

                    <v-checkbox dense
                      color="caccounting"
                      class="ma-0 py-1 single-line"
                      :ripple="false"
                      hide-details
                      :value="item._id"
                      v-model="selectItem"
                      multiple
                      ></v-checkbox>
                  </td>
                  <template v-for="header in tableheaders">
                    <td v-if="header.visible && header.value !== 'action'" :key="header.value" class="px-2 single-line"
                      :class="{ 'description-header': header.value === 'description', 'single-line': header.value !== 'description' }">
                      <span v-if="header.value === 'index'">{{ index + 1 }}</span>

                      <div class="" v-else-if="header.value === 'stage'">
                        <v-chip class="lightyellow yellow--text" v-if="item[header.value] === 0">
                          <v-icon color="yellow">mdi-circle-small</v-icon>
                          <span>Pending Submission</span>
                        </v-chip>
                        <v-chip class="lightblue blue--text" v-if="item[header.value] === 1">
                          <v-icon color="blue">mdi-circle-small</v-icon>
                          <span>Pending Approval</span>
                        </v-chip>
                        <v-chip class="lightred error--text" v-if="item[header.value] === -1">
                          <v-icon color="error">mdi-circle-small</v-icon>
                          <span>Cancelled </span>
                        </v-chip>
                        <v-chip class="lightgreen green--text" v-if="item[header.value] === 2">
                          <v-icon color="green">mdi-circle-small</v-icon>
                          <span>Closed </span>
                        </v-chip>
                      </div>
                      <div class="" v-else-if="header.value === 'info_owner_detail'">
                        {{ (item.info_owner_detail || {}).email }}
                      </div>
                      <span v-else-if="header.value === 'duedate'">
                        <div v-if="new Date(item.duedate || '')">
                          <v-tooltip bottom content-class="tooltip-bottom" v-if="item.stage === 3">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip label size="25" class="error--text lightred" v-bind="attrs" v-on="on"
                                v-if="((new Date(item.duedate || '') - new Date(item.reviewed_on))) / (1000 * 24 * 60 * 60) < -1">
                                {{ Math.ceil((new Date(item.reviewed_on) - new Date(item.duedate ||
                                ''))/(1000*24*60*60))}}
                              </v-chip>
                            </template>
                            <span>Delay</span>
                          </v-tooltip>
                          <v-tooltip bottom content-class="tooltip-bottom"
                            v-else-if="(new Date(item.duedate || '') - new Date()) >= -1">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip label size="25" color="lightpurple caccounting--text" v-bind="attrs" v-on="on">
                                {{ Math.ceil((new Date(item.duedate || '') - new Date()) / (1000 * 24 * 60 * 60)) }}
                              </v-chip>
                            </template>
                            <span>Days Remaining</span>
                          </v-tooltip>
                          <v-tooltip bottom content-class="tooltip-bottom"
                            v-else-if="(new Date(item.duedate || '') - new Date()) < -1">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip label size="25" color="lightred error--text" v-bind="attrs" v-on="on">
                                {{ Math.ceil((new Date() - new Date(item.duedate || '')) / (1000 * 24 * 60 * 60)) }}
                              </v-chip>
                            </template>
                            <span>Days Overdue</span>
                          </v-tooltip>
                        </div>

                      </span>
                      <span v-else> {{ item[header.value] }}</span>
                    </td>
                  </template>
                  <td class="d-flex align-center px-3 single-line border-right border-left action-data">
                    <div class="d-flex align-center justify-center">

                      <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon small v-bind="attrs" v-on="on"
                            @click="responsedialog = true; addeditresponseheader = item; addeditresponse = { response: item.response || '', remark: item.remark || '' }">
                            <v-icon>mdi-upload</v-icon>
                          </v-btn>
                        </template>
                        <span>Upload</span>
                      </v-tooltip>





                      <v-menu bottom offset-y :max-width="400" elevation-0 :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                          <div class="text-center">
                            <v-btn icon small v-bind="attrs" v-on="on"> <v-icon>mdi-dots-horizontal</v-icon> </v-btn>
                          </div>
                        </template>
                        <v-list style="width:25vh">

                          <!-- upload for pending submissions -->
                          <v-list-item class="d-flex pa-0 flex-column align-start justify-center px-1">
                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" :key="index" v-if="item.stage===0"
                              @click="responsedialog = true; addeditresponseheader = item; addeditresponse = { response: item.response || '', remark: item.remark || '' }">
                                <v-icon class=" pr-2">mdi-file-upload-outline</v-icon>
                              <span>Upload</span>
                            </v-list-item>


                            <!-- download for pending approval -->
                            <v-list-item class="all-width"  v-if="item.stage === 1 || item.stage === 2|| item.stage === -1">
                            <v-menu offset-x transition="slide-x-transition" :close-on-content-click="false" >
                              <template v-slot:activator="{ on, attrs }">
                                <div class="cursor-pointer all-width all-height  radius-small" v-bind="attrs" v-on="on" >
                                    <v-icon class=" pr-2">mdi-download</v-icon>
                                    <span>Download</span>
                                </div>
                              </template>
                      
                              <v-list style="max-height:50vh ; overflow:hidden; z-index:13 !important; width:20vh"
                                  class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width">
                                      <v-list-title class="all-width d-flex grayBackground align-center pa-2 pb-3 pt-3 pb-1 justify-space-between">
                                          Documnent Attatched
                                      </v-list-title>
                                  <v-divider></v-divider>
                                  <v-list-item      class="d-flex all-width align-start pa-0 ma-0 flex-column">
                                      <v-list-item class="d-flex pa-0 px-2 ma-0 all-width "  v-if="item.fileid_detail">
                                              <div class="d-flex align-center justify-space-between all-width" >
                                                  <span class="single-line">{{item.fileid_detail.originalname}}</span>
                                                  <v-btn icon @click="$nova.downloadFile(item.fileid)"><v-icon class="pr-2">mdi-download</v-icon></v-btn>
                                              </div>
                                      </v-list-item>
                                      <v-list-item v-if="item.response">
                                        <span> {{item.response}}</span>      
                                      </v-list-item>                              
                                  </v-list-item>
                      
                              </v-list>
                            </v-menu>
                            </v-list-item>

                            

                            <!-- closed req view response-->
                              <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" v-if="item.stage=== -1 || item.stage===2"
                                :key="index" @click="addeditresponseheader = item; addeditresponse = { response: item.response || '', remark: item.remark || '' } ,viewresponsedrawer =true">
                                  <v-icon class=" pr-2">mdi-eye</v-icon>
                                <span>View Response</span>
                              </v-list-item>

                            <!-- approve pending approval req -->

                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" v-if="item.stage===1"
                            :key="index" @click="changeStage(item._id, 2)">
                              <v-icon class=" pr-2" color="green">mdi-check-circle</v-icon>
                            <span>Approve</span>
                          </v-list-item>

                            <!-- reject pending approval req -->

                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" v-if="item.stage===1"
                            :key="index" @click="changeStage(item._id, 0)">
                              <v-icon class=" pr-2" color="error">mdi-close-circle</v-icon>
                            <span>Reject</span>
                          </v-list-item>

                            <!-- edit for pending approval -->

                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" v-if="item.stage===1"
                              :key="index"
                              @click="responsedialog = true; addeditresponseheader = item; addeditresponse = { response: item.response || '', remark: item.remark || '' }">
                              <v-icon class="pr-2">mdi-file-download-outline</v-icon>
                              <span>Add/ Edit Response</span>
                            </v-list-item>

                            <!-- conversation -->
                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"
                              :key="index">
                              <!-- <lb-comment heading="Conversation" width="600" :recurring="false" :recurringText="''"
                                :showname="true" @markread="item.conversation_read = true"
                                :notify="!item.conversation_read"
                                :getapi="'/v2/financialclose/analysis/markcommentasread/' + item._id"
                                :sendapi="'/v2/financialclose/request/addcomment/' + item._id"
                                :readapi="'/v2/financialclose/analysis/markcommentasread/' + item._id" :name="'Comment'"
                                :mentionapi="'/v2/financialclose/analysis/getusersformdata'" /> -->

                                <v-btn @click="markCommentAsRead(item, k);commentreqkey=k;commentsdialog=true;addeditresponseheader=item;commentsdata='';commentserror=''" text class="pa-0" small>
                                  <v-badge color="error" offset-x="10" offset-y="10" dot v-if="(item.comments || []).length > 0 && (((item.comments || [])[(item.comments || []).length - 1] || {}).readby || []).indexOf($store.state.user.id) === -1">
                                    <v-icon class="">mdi-comment-text-multiple</v-icon>
                                  </v-badge>
                                  <v-icon v-else class="mr-1">mdi-comment-text-multiple</v-icon>
                                  <span >Comments</span>
                                </v-btn>
                            </v-list-item>

                            <!-- send back to pending for closed req -->
                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" :key="index" v-if="item.stage===-1 || item.stage===2" @click="changeStage(item._id, 0)">
                            <v-icon class="pr-2">mdi-restore</v-icon>
                            <span>Send back to pending</span>
                          </v-list-item>

                          <v-divider class="pa-0 ma-0"></v-divider>

                            <!-- follow up for pending app & submissin-->
                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" v-if=" item.stage===1 || item.stage===0"
                              :key="index">
                              <v-icon class="pr-2">mdi-calendar-clock-outline</v-icon>
                              <span>Follow Up</span>
                            </v-list-item>

                            <!-- edit for all req -->
                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"
                              :key="index" @click="item.fileupload = (['fileupload', 'fileuploadtolink'].indexOf(item.type) > -1)?true:false;addrequestdialog = true;addeditrequest={...item};addeditrequesterror={}; selectedTasklist= addeditrequest.reference">
                              <v-icon class="pr-2">mdi-pencil-outline</v-icon>
                              <span>Edit Request</span>
                            </v-list-item>

                            <!-- cancel req -->
                            <v-list-item v-if="item.stage != -1" class="cursor-pointer hoverable-caccounting all-width  radius-small"
                              :key="index" @click="changeStage(item._id, -1)">
                              <v-icon class="pr-2">mdi-cancel</v-icon>
                              <span>Cancel Request</span>
                            </v-list-item>

                            <!-- delete req  -->
                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"
                              :key="index" @click="deleteRequest(item._id)">
                              <v-icon class="pr-2">mdi-delete</v-icon>
                              <span>Delete Request</span>
                            </v-list-item>
                          </v-list-item>
                          

                        </v-list>
                      </v-menu>

                    </div>
                  </td>
                </tr>
              </template>
            </lb-datatableaction>

            <div v-else>
              <v-alert type="info" text border="left" :value="true">
                No Requests added yet.
              </v-alert>
            </div>
            <Pagination :list="filterbysearch" :itemsperpage="itemsPerPage" @paginated-data="setPaginatedData" />
          </v-card-text>
        </v-card>
        <v-row>

          <!-- <lb-drawer v-model="addrequestdialog" :heading="addeditrequest._id?'Edit Request':'Create New Request'" >
                <template v-slot:body>

                </template>
                <template v-slot:actions>
                    <v-spacer></v-spacer>
                    <v-btn class="black white--text ">Close</v-btn>
                </template>
            </lb-drawer> -->



          <lb-drawer v-model="addrequestdialog" :heading="addeditrequest._id ? 'Edit Request' : 'Create New Request'">
            <template v-slot:body>
              <v-row>

                <v-col cols="12" class="my-0 py-1">
                  <p class="ma-0 gray--text">Requested Information</p>
                  <lb-string v-model="addeditrequest.description" label="" placeholder="Enter Requested Information"
                    :error="addeditrequesterror.description" />
                </v-col>
                <v-col cols="6" class="my-0 py-1">
                  <p class="ma-0 gray--text">Requested from</p>
                  <lb-dropdown class="flex-grow-1" :items="infoownerlist" itemtext="name" itemvalue="_id"
                    v-model="addeditrequest.info_owner" label="" placeholder="Information owner "
                    :error="addeditrequesterror.info_owner" />
                </v-col>

                <v-col cols="6" class="my-0 py-1">
                  <p class="ma-0 gray--text">Type</p>
                  <lb-dropdown class="flex-grow-1" :customvalue="true" :items="typeofrequest"
                    v-model="addeditrequest.type" label="" :error="addeditrequesterror.type" />
                </v-col>

                <v-col cols="6" class="my-0 py-1">
                  <p class="ma-0 gray--text">Priority</p>
                  <lb-dropdown class="flex-grow-1" :customvalue="true" :items="prioritylist"
                    v-model="addeditrequest.priority" label="" :error="addeditrequesterror.priority" />
                </v-col>


                <v-col cols="6" class="my-0 py-1">
                  <p class="ma-0 gray--text">Classification</p>
                  <lb-dropdown class="flex-grow-1" :customvalue="true" :items="categorylist"
                    v-model="addeditrequest.category" label="" :error="addeditrequesterror.category" />
                </v-col>

                <v-col cols="6" class="my-0 py-1">
                  <p class="ma-0 gray--text">Date</p>
                  <lb-date v-model="addeditrequest.duedate" label="Due Date" :error="addeditrequesterror.duedate"
                    :min="$nova.formatDateISO(new Date())" />
                </v-col>

                <v-col cols="6" class="my-0 py-1">
                  <p class="ma-0 gray--text">{{$store.state.LocationHeader || 'Location'}}</p>
                  <lb-dropdown class="flex-grow-1" :items="locationList" itemtext="name" itemvalue="_id"
                    v-model="addeditrequest.location"
                    label=""
                    :error="addeditrequesterror.location" />
                </v-col>

                <v-col cols="9">
                 <span class="font-weight-bold"> Linked Task</span>
                </v-col>
                <v-col cols="3">
                  <v-menu ref="taskMenu" v-model="menuOpen" :close-on-content-click="false" offset-y
                  transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn text class="" v-bind="attrs" v-on="on">
                        + Add Task
                      </v-btn>
                  </template>

                  <v-list style="max-height:50vh; min-width:50vh ; overflow:scroll; z-index:13 !important"
                      class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width">
                      <v-simple-table dense class="border-on all-width radius-small" >
                        <thead>
                          <tr>
                            <th class="grayBackground black--text">
                              <v-checkbox v-model="selectAllTask" @change="toggleSelectAll"></v-checkbox>
                            </th>
                            <th class="grayBackground black--text" scope="Red">Ref</th>
                            <th class="grayBackground black--text" scope="Name">Task Name</th>
                            <th class="grayBackground black--text" scope="location">Location</th>
                            <th class="grayBackground black--text" scope="Action">Remove</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(item,index) in reflist">
                            <tr :key="index">
                              <td>
                                <v-checkbox
                                  v-model="item.checked"
                                  :value="isSelected(item)"
                                  color="caccounting"
                                  @change="toggleItemSelection(item)"
                                ></v-checkbox>
                              </td>
                              <td class="  ">
                                {{getRefById(item.value)?.ref}}
                              </td>
                              <td>{{getNameById(item.value)}}</td>
                              <td class="  ">
                                {{ getLocationName(getRefById(item.value)?.location) }}
                              </td>
                              <td>
                                <v-icon class="ma-1" color="error" @click="removetasklist(item)">mdi-delete</v-icon>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </v-simple-table>
                      <v-btn color="primary" class="mt-3" @click="$refs.taskMenu.isActive = false">
                        Submit
                      </v-btn>
                  </v-list>
              </v-menu>

                </v-col>

                <v-col cols="12" class="my-0 py-1">
                  <v-simple-table dense class="mb-4 border-on radius-small" v-if="selectedTasklist.length>0">
                    <thead>
                      <tr>
                        <th class="grayBackground black--text" scope="Red">Ref</th>
                        <th class="grayBackground black--text" scope="Name">Task Name</th>
                        <th class="grayBackground black--text" scope="location">Location</th>
                        <th class="grayBackground black--text" scope="Action">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                       v-for="item, index in (selectedTasklist)">
                        <tr :key="index">
                          <td class="  ">
                            {{ getRefById(item).ref }}
                          </td>
                          <td>{{ getNameById(item) }}</td>
                          <td class="  ">
                            {{ getLocationName(getRefById(item)?.location) }}
                          </td>
                          <td>
                            <v-icon class="ma-1" color="error" @click="removetasklist(item)">mdi-delete</v-icon>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </v-simple-table>
                </v-col>

              </v-row>
            </template>
            <template v-slot:actions>

              <v-spacer></v-spacer>
              <v-btn small color="black" class="white--text" v-if="addeditrequest._id"
                @click="editRequest()">Update</v-btn>
              <v-btn small color="black" class="white--text" v-else @click="addRequest()">Create</v-btn>
            </template>
          </lb-drawer>


          <lb-drawer v-model="responsedialog" heading="Upload">
            <template v-slot:body>
              <v-row>
                <v-col cols="12" class="font-weight-bold text-h6">{{ addeditresponseheader.description }}</v-col>
                <v-col cols="3" class="gray--text">Requested Fom</v-col>
                <v-col cols="9">{{ addeditresponseheader.info_owner_detail.name }}</v-col>
                <v-col cols="3" class="gray--text">Email Id</v-col>
                <v-col cols="9"
                  class="blue--text text-decoration-underline">{{ addeditresponseheader.info_owner_detail.email }}</v-col>
                <v-col cols="3" class="gray--text">Type</v-col>
                <v-col cols="9"> <v-chip label class="lightgray radius-small">{{ addeditresponseheader.type }}</v-chip>
                </v-col>
                <v-col cols="3" class="gray--text">Priority</v-col>
                <v-col cols="9"><v-chip label class="lightgray radius-small"
                    v-if="addeditresponseheader.priority">{{ addeditresponseheader.priority }}</v-chip></v-col>
                <v-col cols="3" class="gray--text">Status</v-col>
                <v-col cols="9">
                  <v-chip class="lightyellow yellow--text" v-if="addeditresponseheader.stage === 0">
                    <v-icon color="yellow">mdi-circle-small</v-icon>
                    <span>Pending Submission</span>
                  </v-chip>
                  <v-chip class="lightblue blue--text" v-if="addeditresponseheader.stage === 1">
                    <v-icon color="blue">mdi-circle-small</v-icon>
                    <span>Pending Approval</span>
                  </v-chip>
                  <v-chip class="lightred error--text" v-if="addeditresponseheader.stage === -1">
                    <v-icon color="error">mdi-circle-small</v-icon>
                    <span>Cancelled </span>
                  </v-chip>
                  <v-chip class="lightgreen green--text" v-if="addeditresponseheader.stage === 2">
                    <v-icon color="green">mdi-circle-small</v-icon>
                    <span>Closed </span>
                  </v-chip>
                </v-col>
                <v-col cols="4" v-if="addeditresponseheader.type=== 'fileupload'">Upload Your File</v-col>
                <v-col cols="12" v-if="addeditresponseheader.type=== 'fileupload'">
                  <DragDropfile hidedetails :files="uploadedfiles" @update:files="updateFilesUpload" />
                </v-col>
                <v-col cols="4" v-if="addeditresponseheader.type=== 'text'" class="pb-0">Add Response</v-col>
                <v-col cols="12" v-if="addeditresponseheader.type=== 'text'">
                  <lb-textBox v-model="requestreponse" label="" requriedrows="3" placeholder="Enter response" :hidedetails="true"
                  :isRequired="true"  />
                </v-col>
                <v-col class="pb-0">Remark</v-col>
                <v-col cols="12">
                  <lb-textBox v-model="addeditresponse.remark" label="" requriedrows="2" placeholder="Enter remark" :hidedetails="true"
                  :isRequired="true"  />
                </v-col>
                <v-col cols="12">
                  <v-card class=" d-flex align-center justify-space-between border-on shadow-off " style="width:25%"
                    v-for="(item, index) in uploadedfiles" :key="index">
                    <p class="ma-0 black--text text-body-1 pl-2" style="">{{ item.name }}</p>
                    <v-btn icon @click="uploadedfiles = []"> <v-icon color="error">mdi-delete</v-icon> </v-btn>
                  </v-card>
                </v-col>
                <v-col cols=12>
                  <!-- {{addeditresponseheader.fileid_detail}} -->


                  <v-tabs v-model="tabify" bg-color="primary" class="border-bottom d-flex align-center justify-center"
                    color="caccounting" height="34">
                    <v-tab :key="0" class="text-transform-none pa-0 px-2  justify-start">
                      Recent Activity
                      <v-icon class="mr-2">mdi-information-outline</v-icon>
                    </v-tab>
                    <v-tab :key="1" class="text-transform-none pa-0 px-2  justify-start"
                      @click="markCommentAsRead(addeditresponseheader, 0); commentreqkey = 0; addeditresponseheader = addeditresponseheader; commentsdata = ''; commentserror = ''">
                      Comments
                    </v-tab>

                  </v-tabs>

                  <v-tabs-items v-model="tabify">
                    <v-tab-item :key="0">
                      <v-container class="pa-0">
                        <v-row no-gutters class="">
                          <template v-if="addeditresponseheader.fileid_detail">
                            <v-col cols='3' class=" pa-3 mt-2 mr-1 border-on radius-small">
                                <div class=" d-flex align-start justify-space-between">
                                  <p class="ma-0 black--text text-body-1 mb-3 single-line">{{
                                    addeditresponseheader.fileid_detail.uploadname ||
                                    addeditresponseheader.fileid_detail.originalname }}</p>
                                  <v-btn icon> <v-icon color="error">mdi-delete</v-icon> </v-btn>
                                </div>

                                <p class=" text-caption">{{ $nova.formatDate(addeditresponseheader.fileid_detail.date)
                                  }}</p>
                                <div class="mt-2">
                                  <v-btn @click="$nova.downloadFile(addeditresponseheader.fileid_detail._id)" icon
                                    class="cursor-pointer radius-mini  border-on" >
                                    <v-icon>mdi-download</v-icon>
                                  </v-btn>
                                  <v-spacer></v-spacer>
                                </div>
                            </v-col>
                          </template>
                          <v-col v-if="addeditresponse?.response" cols='3' class=" pa-3 mt-2 mr-1 border-on radius-small">
                            <p class="ma-0 black--text text-body-1 mb-3">Response</p>
                            <!-- <p class=" text-caption">{{ $nova.formatDate('2024-02-23T07:01:03.080Z') }}</p> -->
                            <v-tooltip left content-class="tooltip-left">
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" class="blue--text text-decoration-underline">View
                                  response</span>
                              </template>
                              <span>{{ addeditresponse?.response }}</span>
                            </v-tooltip>
                          </v-col>
                          <v-col v-if="addeditresponse?.remark" cols='3' class=" pa-3 mt-2 border-on radius-small">
                            <p class="ma-0 black--text text-body-1 mb-3">Remark</p>
                            <!-- <p class=" text-caption">{{ $nova.formatDate('2024-02-23T07:01:03.080Z') }}</p> -->
                            <v-tooltip left content-class="tooltip-left">
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" class="blue--text text-decoration-underline">View
                                  remark</span>
                              </template>
                              <span>{{ addeditresponse?.remark }}</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-tab-item>
                    <v-tab-item :key="1" class="all-height">
                      <v-card-text
                        class="border-on d-flex flex-column all-height justify-space-between comments-container overflow-scroll pa-2">
                        <div v-if="(addeditresponseheader.comments || []).length === 0"
                          class="title text-center my-5 grey--text">
                          No Comments added yet
                        </div>
                        <div v-else class="mb-8">
                          <perfect-scrollbar class="">
                            <template v-for="(v, k) in addeditresponseheader.comments">
                              <div
                                :class="`d-flex  all-width ${($store.state.user.id !== v.user) ? '' : 'flex-column   justify-end'}`"
                                :key="k">
                                <div class="mx-1 caption d-flex align-center justify-end ">
                                  {{ $nova.formatDate(v.created_at, "DD-MMM-YYYY, H:m", "") }}
                                  <v-avatar size="24" class="lightpurple caccounting--text pa-2 ml-2">{{getUserById( v.user)}}</v-avatar>
                                </div>
                                <div
                                  :class="`lightpurple radius-small px-4 py-2 my-2 ${($store.state.user.id !== v.user) ? 'grey lighten-2' : 'text-left'}`">
                                  {{ v?.comment }}</div>
                              </div>
                            </template>
                          </perfect-scrollbar>
                        </div>
                        <div
                          v-if="data.stage === 0 && ($nova.hasRight(right, 'add') || $nova.hasRight(right, 'edit') || $nova.hasRight(right, 'delete'))"
                          class="d-flex align-center" :disabled="[-1, 2].indexOf(addeditresponseheader.stage) > -1"
                          style=" position: absolute; bottom: 2%; width: 95%;  background: white;">
                          <lb-string v-model="commentsdata" label="Write here..." :error="commentserror" hidedetails />
                          <v-btn small color="caccounting" class="white--text ml-2" @click="addComment()">
                            <v-icon>mdi-send</v-icon>
                          </v-btn>
                        </div>
                      </v-card-text>
                    </v-tab-item>
                  </v-tabs-items>






                </v-col>
              </v-row>
            </template>
            <template v-slot:actions>
              <v-spacer></v-spacer>
              <v-btn class="black white--text " @click="updateResponse()">Upload</v-btn>
            </template>
          </lb-drawer>

          <lb-drawer v-model="addinfoownerdialog" :heading="selectItem?'Edit Information Owner':'Add New Information Owner'" >
                <template v-slot:body>
                  <div class="d-flex align-center">
                    <v-chip color="lightpurple" class="caccounting--text d-flex align-center w-30">
                         <v-icon class="mr-2">mdi-information-outline</v-icon>
                         {{selectItem.length}}    
                         task item selected
                    </v-chip>
                </div>

                 <v-row class="mt-3">
                  <v-col cols="12" class="my-0 py-1">
                    <lb-dropdown hidedetails v-if="!isLocation" label="" placeholder="Select action " :items="bulkactionuserusertype"
                     class="mt-2" width="10" />
                  </v-col>
              
                  <v-col cols="12" class="my-0 py-1">
                    <lb-dropdown hidedetails  label="" placeholder="Select user"  v-model="addeditinforowner.name" :items="infoownerlist"
                    itemtext="name" itemvalue="_id" class="mt-1" :error="addeditinforownererror.name" />
                    </v-col>
                </v-row>
                </template>
                <template v-slot:actions>
                     <v-spacer></v-spacer>
                <v-btn small color="black" class="white--text" v-if="selectItem"
                  @click="editInfoOwner()">Update</v-btn>
                <v-btn small color="black" class="white--text" v-else @click="addInfoOwner()">Add</v-btn>
 
                </template>
          </lb-drawer>

          <lb-drawer v-model="infoownersummarydialog" heading='Summary' Maxwidth="1665">
            <template v-slot:body>
              <v-simple-table v-if="Object.keys(infoownersummarylist).length > 0" class="simple-table border-on">
                <thead>
                  <tr>
                    <th scope="name" class=" grayBackground ">Requested From</th>
                    <th scope="pending" class=" grayBackground text-center">Pending Submission</th>
                    <th scope="wating-approval" class=" grayBackground text-center">Pending Re-Submission</th>
                    <th scope="closed" class=" grayBackground text-center">Closed/Approved</th>
                    <th scope="cancelled" class=" grayBackground text-center">Cancelled</th>
                    <th scope="last-mail-date" class=" grayBackground text-center" style="min-width: 120px">Last Mail
                      Sent</th>
                    <th scope="mail-action" class=" grayBackground text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v, k) in infoownersummarylist" :key="k">

                    <td class="d-flex align-center justify-space-between">{{ v.name || "" }}
                      <v-icon>mdi-email-outline</v-icon>
                    </td>
                    <td class="text-center">
                      <span v-if="v.pending">{{ v.pending || 0 }}</span>
                    </td>
                    <td class="text-center">
                      <span v-if="v.approve">{{ v.approve || 0 }}</span>
                    </td>
                    <td class="text-center">
                      <span v-if="v.close">{{ v.close || 0 }}</span>
                    </td>
                    <td class="text-center">
                      <span v-if="v.cancel">{{ v.cancel || 0 }}</span>
                    </td>
                    <td class="text-center">
                      <div v-if="v.lastmail" class="font-weight-bold caption">
                        {{ $nova.formatDate(v.lastmail) }}
                      </div>
                    </td>
                    <td class="text-center">

                      <div v-if="(v.mailpending || 0) == 0" class="success--text">
                        <v-icon color="green" class="">mdi-check-circle</v-icon>

                        <v-btn text class="ml-2 text-capitalize"
                          v-if="$nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add') || $nova.hasRight(right, 'delete')"
                          small @click="sendMail('reminder', v._id)">
                          Follow-Up
                        </v-btn>
                      </div>
                      <div v-else class="info--text">
                        <div class="d-flex align-center justify-center">
                          <v-icon color="blue" class="">mdi-timer-sand</v-icon>
                          <span class="">{{ v.mailpending || 0 }}</span>

                          <v-btn text class="ml-2 text-capitalize"
                            v-if="$nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add') || $nova.hasRight(right, 'delete')"
                            small @click="sendMail('reminder', v._id)">
                            Send
                          </v-btn>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <div v-else class="grey--text title">
                No Data available
              </div>
            </template>
            <template v-slot:actions>
              <v-spacer></v-spacer>
              <v-btn class="black white--text ">Close</v-btn>
            </template>
          </lb-drawer>

          <lb-drawer v-model="viewresponsedrawer" heading="Upload">
            <template v-slot:body>
              <v-row>
                <v-col cols="12" class="font-weight-bold text-h6">{{ addeditresponseheader.description }}</v-col>
                <v-col cols="3" class="gray--text">Requested Fom</v-col>
                <v-col cols="9">{{ addeditresponseheader.info_owner_detail.name }}</v-col>
                <v-col cols="3" class="gray--text">Email Id</v-col>
                <v-col cols="9"
                  class="blue--text text-decoration-underline">{{ addeditresponseheader.info_owner_detail.email }}</v-col>
                <v-col cols="3" class="gray--text">Type</v-col>
                <v-col cols="9"> <v-chip label class="lightgray radius-small">{{ addeditresponseheader.type }}</v-chip>
                </v-col>
                <v-col cols="3" class="gray--text">Priority</v-col>
                <v-col cols="9"><v-chip label class="lightgray radius-small"
                    v-if="addeditresponseheader.priority">{{ addeditresponseheader.priority }}</v-chip></v-col>
                <v-col cols="3" class="gray--text">Status</v-col>
                <v-col cols="9">
                  <v-chip class="lightyellow yellow--text" v-if="addeditresponseheader.stage === 0">
                    <v-icon color="yellow">mdi-circle-small</v-icon>
                    <span>Pending Submission</span>
                  </v-chip>
                  <v-chip class="lightblue blue--text" v-if="addeditresponseheader.stage === 1">
                    <v-icon color="blue">mdi-circle-small</v-icon>
                    <span>Pending Approval</span>
                  </v-chip>
                  <v-chip class="lightred error--text" v-if="addeditresponseheader.stage === -1">
                    <v-icon color="error">mdi-circle-small</v-icon>
                    <span>Cancelled </span>
                  </v-chip>
                  <v-chip class="lightgreen green--text" v-if="addeditresponseheader.stage === 2">
                    <v-icon color="green">mdi-circle-small</v-icon>
                    <span>Closed </span>
                  </v-chip>
                </v-col>
            
             
                <v-col cols=12>
                  <v-tabs v-model="tabify" bg-color="primary" class="border-bottom d-flex align-center justify-center"
                    color="caccounting" height="34">
                    <v-tab :key="0" class="text-transform-none pa-0 px-2  justify-start">
                      Recent Activity
                      <v-icon class="mr-2">mdi-information-outline</v-icon>
                    </v-tab>
                    <v-tab :key="1" class="text-transform-none pa-0 px-2  justify-start"
                      @click="markCommentAsRead(addeditresponseheader, 0); commentreqkey = 0; addeditresponseheader = addeditresponseheader; commentsdata = ''; commentserror = ''">
                      Comments
                    </v-tab>

                  </v-tabs>

                  <v-tabs-items v-model="tabify">
                    <v-tab-item :key="0">
                      <v-container class="pa-0">
                        <v-row no-gutters class="">
                          <template v-if="addeditresponseheader.fileid_detail">
                            <v-col cols='3' class=" pa-3 mt-2 mr-1 border-on radius-small">
                                <div class=" d-flex align-start justify-space-between">
                                  <p class="ma-0 black--text text-body-1 mb-3 single-line">{{
                                    addeditresponseheader.fileid_detail.uploadname ||
                                    addeditresponseheader.fileid_detail.originalname }}</p>
                                </div>

                                <p class=" text-caption">{{ $nova.formatDate(addeditresponseheader.fileid_detail.date)
                                  }}</p>
                                <div class="mt-2">
                                  <v-btn @click="$nova.downloadFile(addeditresponseheader.fileid_detail._id)" icon
                                    class="cursor-pointer radius-mini  border-on" >
                                    <v-icon>mdi-download</v-icon>
                                  </v-btn>
                                  <v-spacer></v-spacer>
                                </div>
                            </v-col>
                          </template>
                          <v-col v-if="addeditresponse?.response" cols='3' class=" pa-3 mt-2 mr-1 border-on radius-small">
                            <p class="ma-0 black--text text-body-1 mb-3">Response</p>
                            <!-- <p class=" text-caption">{{ $nova.formatDate('2024-02-23T07:01:03.080Z') }}</p> -->
                            <v-tooltip left content-class="tooltip-left">
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" class="blue--text text-decoration-underline">View
                                  response</span>
                              </template>
                              <span>{{ addeditresponse?.response }}</span>
                            </v-tooltip>
                          </v-col>
                          <v-col v-if="addeditresponse?.remark" cols='3' class=" pa-3 mt-2 border-on radius-small">
                            <p class="ma-0 black--text text-body-1 mb-3">Remark</p>
                            <!-- <p class=" text-caption">{{ $nova.formatDate('2024-02-23T07:01:03.080Z') }}</p> -->
                            <v-tooltip left content-class="tooltip-left">
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" class="blue--text text-decoration-underline">View
                                  remark</span>
                              </template>
                              <span>{{ addeditresponse?.remark }}</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-tab-item>
                    <v-tab-item :key="1" class="all-height">
                      <v-card-text
                        class="border-on d-flex flex-column all-height justify-space-between comments-container overflow-scroll pa-2">
                        <div v-if="(addeditresponseheader.comments || []).length === 0"
                          class="title text-center my-5 grey--text">
                          No Comments added yet
                        </div>
                        <div v-else class="mb-8">
                          <perfect-scrollbar class="">
                            <template v-for="(v, k) in addeditresponseheader.comments">
                              <div
                                :class="`d-flex  all-width ${($store.state.user.id !== v.user) ? '' : 'flex-column   justify-end'}`"
                                :key="k">
                                <div class="mx-1 caption d-flex align-center justify-end ">
                                  {{ $nova.formatDate(v.created_at, "DD-MMM-YYYY, H:m", "") }}
                                  <v-avatar size="24" class="lightpurple caccounting--text pa-2 ml-2">{{getUserById( v.user)}}</v-avatar>
                                </div>
                                <div
                                  :class="`lightpurple radius-small px-4 py-2 my-2 ${($store.state.user.id !== v.user) ? 'grey lighten-2' : 'text-left'}`">
                                  {{ v?.comment }}</div>
                              </div>
                            </template>
                          </perfect-scrollbar>
                        </div>
                        <div
                          v-if="data.stage === 0 && ($nova.hasRight(right, 'add') || $nova.hasRight(right, 'edit') || $nova.hasRight(right, 'delete'))"
                          class="d-flex align-center" :disabled="[-1, 2].indexOf(addeditresponseheader.stage) > -1"
                          style=" position: absolute; bottom: 2%; width: 95%;  background: white;">
                          <lb-string v-model="commentsdata" label="Write here..." :error="commentserror" hidedetails />
                          <v-btn small color="caccounting" class="white--text ml-2" @click="addComment()">
                            <v-icon>mdi-send</v-icon>
                          </v-btn>
                        </div>
                      </v-card-text>
                    </v-tab-item>
                  </v-tabs-items>






                </v-col>
              </v-row>
            </template>
            <template v-slot:actions>
              <v-spacer></v-spacer>
              <v-btn class="black white--text " @click="viewresponsedrawer=false">Close</v-btn>
            </template>
          </lb-drawer>

          <lb-drawer v-model="commentsdialog" heading="Comments">
            <template v-slot:body>
              <div v-if="(addeditresponseheader.comments || []).length === 0" class="title text-center my-5 grey--text">
                No Comments added yet
              </div>
              <div v-else>
                <div class="">
                  <perfect-scrollbar class="">
                    <template v-for="(v,k) in addeditresponseheader.comments">
                      <div class="mx-1 caption" :key="k" :class="`d-flex align-center ${($store.state.user.id!==v.user)?'':'flex-row-reverse text-right'}`">
                        {{$nova.formatDate(v.created_at, "DD-MMM-YYYY, H:m", "")}}
                      </div>
                      <div :key="k" :class="`d-flex align-center ${($store.state.user.id!==v.user)?'':'flex-row-reverse text-right'}`" >
                        <div :class="`selected-caccounting px-4 py-2 my-2 ${($store.state.user.id!==v.user)?'grey lighten-2':'text-left'}`">{{v.comment}}</div>
                      </div>
                    </template>
                  </perfect-scrollbar>
                </div>
  
              </div>
            </template>
            <template v-slot:actions>

              <div v-if="data.stage===0 && ($nova.hasRight(right, 'add') || $nova.hasRight(right, 'edit') || $nova.hasRight(right, 'delete'))" class="d-flex all-width align-center" :disabled="[-1,2].indexOf(addeditresponseheader.stage) > -1">
                <lb-string v-model="commentsdata" label="Write here..." :error="commentserror" hidedetails/>
                <v-btn small color="caccounting" class="white--text ml-2" @click="addComment()">Add</v-btn>
              </div>
            </template>    
          </lb-drawer>
        </v-row>
      </div>
    </div>
    <v-snackbar v-model="snackbar_status" auto-heigh :color="snackbar_text.type" :timeout="3000" bottom center>
      <div class="text-center">
        {{ snackbar_text.content }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import DragDropfile from "../../../views/Inputs/DragDropfile.vue";
// @ is an alias to /src
// import pageHeader from '@/components/pageHeader'
// import managelist from '@/components/ManageList'
import Pagination from "../../../views/common/Pagination.vue"
export default {
  name: 'pbc_request_list',
  props: {
    embedid: {
      type: String,
      default: undefined,
    },
    embed: {
      type: Boolean,
      default: false,
    },
    api: {
      type: String,
      default: "",
    },
    reflist: {
      type: Array,
      default() {
        return []
      }
    },
    metadata: {
      type: Object,

    },
    userlist:{
      type: Array,
      default() {
        return []
      }
    }
    // required: {
    //   type: Boolean,
    //   default: false
    // }

  },
  data: function () {
    return {
      right: "financialclose_pbc_request",
      pageprops: {
        name: "Projects",
        breadcrumbs: [],
      },
      id: '',
      isFree: true,
      statustext: {
        's-1': { text: 'Cancelled', color: 'error' },
        's0': { text: 'Waiting', color: 'info' },
        's1': { text: 'Pending Approval', color: 'warning' },
        's2': { text: 'Closed', color: 'success' }
      },
      changestatustext: {
        'pending': { text: 'Pending', stages: [2, -1], changeto: 0, color: 'info' },
        'approve': { text: 'Approve', stages: [1], changeto: 2, color: 'success' },
        'close': { text: 'Close', stages: [0], changeto: 2, color: 'success' },
        'cancel': { text: 'Cancel', stages: [0, 1, 2], changeto: -1, color: 'error' },
      },
      requesttype: {
        "text": "Text",
        "number": "Number",
        "fileupload": "File Upload",
        "link": "URL Link",
        "fileuploadtolink": "File Upload Link",
        "multiselect": "Multi Select",
        "choice": "Choice",
      },
      totalrequests: 0,
      counter: 0,
      pendingmail: 0,
      progress: 30,
      typedialog: false,
      statushelpdialog: false,
      addeditrequest: {},
      addeditrequesterror: {},
      addeditinforowner: {},
      addeditinforownererror: {},
      addeditresponseheader: {},
      addeditresponse: {},
      addeditresponseerror: {},
      infoownerlist: [],
      infoownerlistids: {},
      statusloading: {},
      deleteloading: false,
      deletedialog: false,
      deletedata: {},
      sendmailloading: false,
      addrequestdialog: false,
      addrequestloading: false,
      addinfoownerdialog: false,
      addinfoownerloading: false,
      responsedialog: false,
      responseloading: false,
      bulkuploaddialog: false,
      bulkuploadloading: false,
      bulkuploadfile: null,
      okBulkRequestData: [],
      errorBulkRequestData: {},
      commentsdialog: false,
      commentsloading: false,
      commentsdata: "",
      commentserror: "",
      commentreqkey: -1,
      closeprojectdialog: false,
      closeprojectloading: false,
      openprojectdialog: false,
      openprojectloading: false,
      infoownersummarylist: [],
      infoownersummarydialog: false,
      infoownersummaryloading: false,
      loading: false,
      data: {},
      baseurl: this.api || "/v2/pbc/",
      owners: {},
      requestlist: [],
      requestlistCopy:[],
      categorylist: ["Others"],
      categoryfilter: [],
      stagefilter: [0, 1],
      selectdata: {
        "type": ["Text", "Number", "File Upload", "Multi Select", "Choice", "File Upload to link"],
      },
      snackbar_text: { type: '', content: '' },
      snackbar_status: false,
      locationList: [],
      addedittaskdialog: false,
      selectchecklist_id: '',
      addedittaskloading: false,
      tableheaders: [
        {
          text: 'S No.',
          value: 'index',
          visible: true,
          filterable: false,
          sortable: false,
        },
        {
          text: 'Requested Information',
          value: 'description',
          visible: true,
          filterable: true,
          sortable: true,
        },
        {
          text: 'Type',
          value: 'type',
          visible: true,
          filterable: true,
          sortable: true,
        },
        {
          text: 'Classification',
          value: 'category',
          visible: true,
          filterable: true,
          sortable: true,
        },
        {
          text: 'Status',
          value: 'stage',
          visible: true,
          filterable: false,
          sortable: false,
        },
        {
          text: 'Due',
          value: 'duedate',
          visible: true,
          filterable: false,
          sortable: false,
        },
        {
          text: 'Requested From',
          value: 'info_owner_detail',
          visible: true,
          filterable: false,
          sortable: false,
        },
        {
          text: 'Action',
          value: 'action',
          visible: true,
          filterable: false,
          sortable: false,
        },

      ],
      searchcolumn: '',
      showSearch: false,
      searchQuery: "",
      itemsPerPage: 12,
      paginatedItems: [],
      selectedTab: null,
      selectItem: "",
      uploadedfiles: null,
      tabify: 0,
      requestreponse:'',
      bulkactionuserusertype:["Add", "Replace" ],
      viewresponsedrawer:false,
      selectAllTask:false,
      typeofrequest:["text", "fileupload"],
      prioritylist:["High","Medium","Low"],
      selectedTasklist:[]
    }
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  components: {
    Pagination, DragDropfile
  },
  mounted() {
    // Initialize `checked` status for each item based on `addeditrequest.selectlist`
    this.reflist.forEach(item => {
      item.checked = this.isSelected(item);
    });
    // Set selectAll based on initial selection state
    this.selectAll = this.reflist.every(item => item.checked);
  },

  methods: {
    
    toggleAllTasks() {
      this.reflist.forEach(item => {
        item.checked = this.selectAllTask;
        this.addeditrequest.push(item.value)
      });
    },
    isSelected(item) {
      // Check if the item is in `addeditrequest.selectlist`
      return this.addeditrequest?.selectlist?.some(
        selected => selected.selectlist === item.master_id && selected.location === item.location
      );
    },
    toggleSelectAll() {
      // Toggle all items based on the selectAllTask checkbox state
      this.reflist.forEach(item => {
        item.checked = this.selectAllTask;
        this.updateSelectList(item);
      });
    },
    toggleItemSelection(item) {
      this.selectedTasklist.push(item.value)
      if (!this.addeditrequest.reference) {
        this.addeditrequest.reference = [];
      }
      const index = this.addeditrequest.reference.indexOf(item.value);

      if (index === -1) {
          this.addeditrequest.reference.push(item.value);
        } else {
        this.addeditrequest.reference.splice(index, 1);
      }
      this.selectedTasklist = [...this.addeditrequest.reference];
    },
    
    updateFilesUpload(filesss) {
      this.uploadedfiles = filesss
      console.log("qwertyujik", filesss);
    },
    refreshData() {
      if (this.$route.hash === "#add") {
        this.addNewRequest();
      }

      if ((this.$store.state.subscriptions.pbc || {}).Free) this.isFree = true;
      else this.isFree = false;
      if (this.embedid) this.id = this.embedid;
      else this.id = this.$route.params.id;
      this.progress = 0;
      return this.getData();
    },
    addEditTaskItem() {
      this.addedittaskdialog = true;
      if (!Object.keys(this.addeditrequest).includes('selectlist') || !Object.keys(this.addeditrequest).includes('reference')) {
        this.addeditrequest.selectlist = [];
        this.addeditrequest.reference = [];
      }
      if (this.addeditrequest.reference?.length !== 0 && this.selectchecklist_id) {
        this.addeditrequest.reference = this.addeditrequest.reference.includes(this.selectchecklist_id) ? [...this.addeditrequest.reference] : [...this.addeditrequest.reference, this.selectchecklist_id]
      }
      else if (this.addeditrequest.selectlist?.length !== 0 && this.selectchecklist_id) {
        let filtered_task = this.reflist.find((item) => item.value === this.selectchecklist_id)
        this.addeditrequest.selectlist.some(item => item.selectlist === this.selectchecklist_id || item.selectlist === filtered_task.master_id) ? " " : this.addeditrequest.selectlist.push({ selectlist: filtered_task.master_id, location: filtered_task.location });
      }
      this.selectchecklist_id = ''
      this.addedittaskdialog = false;
    },
    removetasklist(item) {


      if (this.addeditrequest.reference?.length !== 0) {
        let arr = this.addeditrequest.reference.filter(function (i) {
          return i !== item
        })
        this.addeditrequest.reference = arr;
      }
      else if (this.addeditrequest.selectlist?.length !== 0) {
        const index = this.addeditrequest.selectlist.findIndex(selectItem =>
          selectItem.selectlist === item.selectlist && selectItem.location === item.location
        );
        if (index !== -1) {
          this.addeditrequest.selectlist.splice(index, 1);
        }
      }
      this.selectedTasklist=[... this.addeditrequest.reference]

    },
    getData() {
      this.loading = true;
      return this.axios.post(this.baseurl + "project/get/" + this.id).then(dt => {
        if (dt.data.status === "success") {
          if (dt.data.data.length > 0) {
            this.data = dt.data.data[0];
            console.log("qwedrfthyujkil", dt.data.data[0]);

            // count total request
            if (this.data.summary) this.totalrequests = this.data.summary.requested + this.data.summary.pending + this.data.summary.approved + this.data.summary.cancelled;
            this.progress = (((((this.data.summary || {}).approved || 0) + ((this.data.summary || {}).cancelled || 0)) / this.totalrequests) * 100 || 0).toFixed(0);
            return this.axios.post(this.baseurl + "project/getrequests/" + this.id)
          } else {
            if (!this.embed) this.$nova.gotoLink({ path: '/pbc/project' });
            throw new Error("");
          }
        } else throw new Error("No project found");
      }).then(dt => {
        this.requestlist = [];
        if (dt.data.status === "success") {
          if (dt.data.data.length > 0) {
            this.requestlist = dt.data.data || [];

            // populate reference summary
            let refsummary = {};
            for (const i of this.requestlist) {
              for (const j of (i.reference || [])) {
                refsummary[j] = refsummary[j] || { total: 0, pending: 0, completed: 0, cancelled: 0 };
                refsummary[j].total++
                if (i.stage === -1) refsummary[j].calcelled++;
                else if (i.stage === 2) refsummary[j].completed++;
                else refsummary[j].pending++;
              }
            }
            // send pbc data in case of embed
            this.$emit('refresh', { items: this.requestlist, referencesummary: refsummary });

            // for owner colors
            this.owners = {};
            this.pendingmail = 0;
            for (let i = 0; i < this.requestlist.length; i++) {
              const el = this.requestlist[i];
              if (el.category) {
                if (this.categorylist.indexOf(el.category) === -1) this.categorylist.push(el.category);
              } else el.category = "Others"
              this.owners[el['info_owner']] = {};
              if (el.mail_count === 0 && el.stage === 0) this.pendingmail++;
            }
            this.categoryfilter = this.categorylist;
            let colors = this.$nova.getColors(Object.keys(this.owners).length, 0.5);
            for (let i = 0; i < Object.keys(this.owners).length; i++) {
              const el = Object.keys(this.owners)[i];
              this.owners[el].color = colors[i];
            }
          }
          console.log(this.requestlist,"requestlist");
          this.requestlistCopy = JSON.parse(JSON.stringify(this.requestlist));
        
          return this.axios.post(this.baseurl + "infoowner/list");
        } else throw new Error("Error fetching request details");
      }).then(dt => {
        if (dt.data.status === "success") {
          if (dt.data.data.length > 0) {
            this.infoownerlist = [];
            this.infoownerlistids = {};
            for (let i = 0; i < dt.data.data.length; i++) {
              const el = dt.data.data[i];
              this.infoownerlist.push({ "_id": el._id, "name": el.name + " (" + el.email + ")" });
              this.infoownerlistids[el._id] = el;
            }
          }
          this.viewInfoOwnerSummary();
        } else throw new Error("Error fetching information owner list");
      }).then(() => {
        return this.axios.post("/v2/financialclose/location/get").then((dt) => {
          if (dt.data.status === "success") {
            this.locationList = dt.data.data;
            this.loading = false;
          } else throw new Error(dt.data.message);
        });
      }).catch(err => {
        if (err.message) {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }
      }).finally(() => {
        this.counter++;
        this.loading = false;
      })
    },
    viewInfoOwnerSummary: function () {
      let fdt = {};
      for (let i = 0; i < this.requestlist.length; i++) {
        const el = this.requestlist[i];
        fdt[el.info_owner] = fdt[el.info_owner] || { lastmail: ((el.info_owner_detail || {}).mails || {}).action_date, _id: el.info_owner, name: (el.info_owner_detail || {}).name, email: (el.info_owner_detail || {}).email, pending: 0, approve: 0, close: 0, cancel: 0, mailsent: 0, mailpending: 0 };
        let info = fdt[el.info_owner];
        if ((el.mail_count || 0) > 0) info.mailsent++;
        else info.mailpending++;
        if (el.stage === -1) info.cancel = info.cancel + 1;
        else if (el.stage === 0) info.pending = info.pending + 1;
        else if (el.stage === 2) info.close = info.close + 1;
        else if (el.stage === 1) info.approve = info.approve + 1;
        fdt[el.info_owner] = info;
      }
      this.infoownersummarylist = fdt;

    },
    changeStage(id, s) {
      this.statusloading[id] = true;
      console.log(this.statusloading);
      this.axios.post(this.baseurl + "request/changestage/" + id + "/" + s).then(dt => {
        if (dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Status changed");
          this.refreshData();
        } else throw new Error(dt.data.message);
      }).catch(err => {
        this.statusloading[id] = false;
        this.counter++;
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.statusloading[id] = false;
      })
    },
    addNewRequest() {
      this.addrequestdialog = true;
      this.addeditrequest = {};
      this.addeditrequesterror = {};
    },
    beforeRequestUpdate(d) {
      for (let i = 0; i < d.length; i++) {
        d[i].project = this.id;
        if (this.isFree) {
          if (d[i].fileupload) d[i].type = "fileuploadtolink";
          else d[i].type = "text";
        }
        else {
          if (d[i].fileupload) {
            if (d[i].uploadlink) d[i].type = "fileuploadtolink";
            else d[i].type = "fileupload";
          }
          else d[i].type = "text";
        }
      }
      return d;
    },
    closeProject() {
      this.closeprojectloading = true;
      this.axios.post(this.baseurl + "project/close/" + this.id).then(dt => {
        if (dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Project Closed Successfully");
          this.closeprojectdialog = false;
          this.refreshData();
        } else {
          throw new Error(dt.data.message);
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.closeprojectloading = false;
      })
    },
    openProject() {
      this.openprojectloading = true;
      this.axios.post(this.baseurl + "project/open/" + this.id).then(dt => {
        if (dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Project re-opened Successfully");
          this.openprojectdialog = false;
          this.refreshData();
        } else {
          throw new Error(dt.data.message);
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.openprojectloading = false;
      })
    },
    addRequest() {
      this.addrequestloading = true;
      let rdata = this.beforeRequestUpdate([this.addeditrequest]);
      this.axios.post(this.baseurl + "request/add/", { data: rdata }).then(dt => {
        if (dt.data.status === "success") {
          this.$store.commit("sbSuccess", "New Request Added");
          this.addeditrequest = {};
          this.addrequestdialog = false;
          this.$nextTick(() => {
            this.refreshData();
          });
        } else {
          if (dt.data.data.length > 0) {
            if (dt.data.data[0].index0) this.addeditrequesterror = dt.data.data[0].index0;
          }
          throw new Error(dt.data.message);
        }
      }).catch(err => {

        this.$store.commit("sbError", err.message || err || "Unknown error!");
        // this.local_sankbar('Ticket updated successfully', 'success');
        // console.log(err);
      }).finally(() => {
        this.addrequestloading = false;
      })
    },
    bulkUploadRequest: function () {
      this.bulkuploadloading = true;
      let rdata = this.beforeRequestUpdate(this.okBulkRequestData);
      this.axios.post(this.baseurl + "request/add/", { data: rdata }).then(dt => {
        if (dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Bulk Requests Added");
          this.bulkuploaddialog = false;
          this.refreshData();
        } else {
          if (dt.data.data.length > 0) {
            let er = {};
            for (let i = 0; i < dt.data.data.length; i++) {
              const el = dt.data.data[i];
              let etxt = [];
              let fk = Object.keys(el)[0];
              for (const k in el[fk]) {
                if (Object.hasOwnProperty.call(el[fk], k)) {
                  const nel = el[fk][k];
                  let netxt = "";
                  netxt = netxt + "'" + k + "': "
                  if (nel instanceof Array) netxt = netxt + nel.join(",");
                  else netxt = netxt + nel.toString();
                  if (netxt !== "") etxt.push(netxt);
                }
              }
              er[fk] = etxt;
            }
            this.errorBulkRequestData = er;
          }
          throw new Error(dt.data.message);
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.bulkuploadloading = false;
      });
    },
    editRequest() {
      this.addrequestloading = true;
      this.beforeRequestUpdate([this.addeditrequest]);
      let newData = {};
      newData._id = this.addeditrequest._id || "";
      newData.description = this.addeditrequest.description || "";
      newData.category = this.addeditrequest.category || undefined;
      if (this.addeditrequest.duedate) newData.duedate = this.addeditrequest.duedate || "";
      newData.info_owner = this.addeditrequest.info_owner || "";
      newData.type = this.addeditrequest.type || "";
      newData.response = "";
      newData.urgent = this.addeditrequest.urgent || false;
      newData.selectlist = this.addeditrequest.selectlist || [];
      newData.uploadlink = this.addeditrequest.uploadlink || "";
      newData.location = this.addeditrequest.location,
        newData.reference = this.addeditrequest.reference || [],
        this.axios.post(this.baseurl + "request/edit/" + newData._id, { data: newData }).then(dt => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "Request Updated");
            this.addrequestdialog = false;
            this.addeditrequest = {};
            this.$nextTick(() => {
              this.refreshData();
            });
          } else {
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.addeditrequesterror = dt.data.data[0].index0;
            }
            throw new Error(dt.data.message);
          }
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.addrequestloading = false;
          this.addrequestdialog=false
        })
    },
    deleteRequest(id) {
      this.deleteloading = true;
      this.axios.post(this.baseurl + "request/delete/" + id).then(dt => {
        if (dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Request Deleted");
          this.deletedialog = false;
          this.refreshData();
        } else throw new Error((dt.data.message) || "error deleting the request");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.deleteloading = false;
      })
    },
    updateResponse() {
      this.responseloading = true;
      Promise.resolve().then(() => {
        if (this.addeditresponseheader.type === "fileupload") return this.$nova.uploadFile(this.axios, this.uploadedfiles[0]);
      }).then(dt => {
        if (this.addeditresponseheader.type === "fileupload") {
          if (dt.data.status === "success" && dt.data.data.length > 0) {
            this.addeditresponse.fileid = dt.data.data[0]._id;
            this.addeditresponse.response={}
          } else throw new Error("Unable to upload file");
        }
        return this.axios.post(this.baseurl + "request/updateresponse/" + this.addeditresponseheader._id, { data: this.addeditresponse })
      }).then(dt => {
        if (dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Response Updated");
          this.responsedialog = false;
          this.refreshData();
        } else {
          if (dt.data.data.length > 0) {
            if (dt.data.data[0].index0) this.addeditresponseerror = dt.data.data[0].index0;
          }
          throw new Error(dt.data.message);
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.responseloading = false;
      })
    },
    addInfoOwner() {
      this.addinfoownerloading = true;

      this.axios.post(this.baseurl + "infoowner/add/", { data: this.addeditinforowner }).then(dt => {
        if (dt.data.status === "success" && dt.data.data.length > 0) {
          this.$store.commit("sbSuccess", "New Information Owner Added");
          this.addinfoownerdialog = false;
          let tis = this;
          let reqdata = { ...this.addeditrequest };
          this.refreshData().then(function () { tis.addeditrequest = reqdata; tis.addeditrequest.info_owner = dt.data.data[0]._id || "" });
        } else {
          if (dt.data.data.length > 0) {
            if (dt.data.data[0].index0) this.addeditinforownererror = dt.data.data[0].index0;
          }
          throw new Error(dt.data.message);
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.addinfoownerloading = false;
      })
    },
    editInfoOwner() {
      this.addinfoownerloading = true;

      this.axios.post(this.baseurl + "infoowner/edit/" + this.addeditinforowner._id, { data: this.addeditinforowner }).then(dt => {
        if (dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Updated Owner Information");
          this.addinfoownerdialog = false;
          let t = this.addeditrequest.info_owner;
          this.addeditrequest.info_owner = null;
          let tis = this;
          let reqdata = { ...this.addeditrequest };
          this.refreshData().then(function () { tis.addeditrequest = reqdata; tis.addeditrequest.info_owner = t });
        } else {
          if (dt.data.data.length > 0) {
            if (dt.data.data[0].index0) this.addeditinforownererror = dt.data.data[0].index0;
          }
          throw new Error(dt.data.message);
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.addinfoownerloading = false;
      })
    },
    addComment() {
      this.commentsloading = true;
      this.axios.post(this.baseurl + "request/addcomment/" + this.id, { data: [{ comment: this.commentsdata, request: this.addeditresponseheader._id || '' }] }).then(dt => {
        if (dt.data.status === "success") {
          if (dt.data.data.length > 0) {
            this.$store.commit("sbSuccess", "Comment Added");
            this.requestlist[this.commentreqkey].comments = this.requestlist[this.commentreqkey].comments || [];
            this.requestlist[this.commentreqkey].comments.push(dt.data.data[0])
            this.addeditresponseheader = this.requestlist[this.commentreqkey];
            this.commentsdata = '';
            this.commentserror = '';
          } else throw new Error("error adding comment");
        } else {
          if (dt.data.data.length > 0) {
            if (dt.data.data[0].index0) this.addeditinforownererror = dt.data.data[0].index0;
          }
          throw new Error(dt.data.message);
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.commentsloading = false;
      })
    },
    markCommentAsRead(x, k) {
      if ((x.comments || []).length > 0) {
        let commentkey = x.comments.length - 1;
        let comment = x.comments[commentkey];
        if ((comment.readby || []).indexOf(this.$store.state.user.id) === -1) {
          this.axios.post(this.baseurl + "request/markcommentasread/" + x._id).then(dt => {
            if (dt.data.status === "success" && dt.data.response.nModified > 0) {
              this.requestlist[k].comments[commentkey].readby.push(this.$store.state.user.id);
            } else throw new Error(dt.data.message);
          }).catch(err => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          }).finally(() => {
            this.commentsloading = false;
          })
        }
      }
    },
    sendMail(t = "", info_owner = "") {
      let ndt = {};
      if (t === 'reminder') {
        ndt.info_owner = [info_owner]
        ndt.type = "reminder";
      }
      this.sendmailloading = true;
      this.infoownersummaryloading = true;
      this.axios.post(this.baseurl + "request/sendmail/" + this.id, { data: [ndt] }).then(dt => {
        if (dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Mail sent");
          this.refreshData();
        } else throw new Error(dt.data.message) || "Error sending mail";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.sendmailloading = false;
        this.infoownersummaryloading = false;
      })
    },
    downloadBulkUploadTemplate() {
      let rows = [["description", "category", "info_owner_name", "info_owner_email", "file_upload", "file_upload_link", "urgent", "due_date"]];
      let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "bulk_request_template.csv");
      document.body.appendChild(link);
      link.click();
    },
    downloadAllRequest() {
      let rows = [[]];
      rows.push(["", "Project Name", this.data.name])
      rows.push(["", "Due Date", this.$nova.formatDate(this.data.duedate)]);
      rows.push([]);
      rows.push([["", "Sl No", "Description", "Category", "Location", "Type", "Due Date", "Information Owner Name", "Information Owner Email", "Status", "Response", "Remarks"]]);
      for (let i = 0; i < this.requestlist.length; i++) {
        const el = this.requestlist[i];
        let nd = ["", i + 1, '"' + el.description + '"', '"' + (el.category || '') + '"']
        nd.push((el.type === 'text') ? "Text" : ((el.type === 'fileuploadtolink') ? "File Upload to Link" : ((el.type === 'fileupload') ? "File Upload" : "Unknown")));
        nd.push(this.$nova.formatDate(el.duedate || 'NA'));
        nd.push((el.info_owner_detail || {}).name);
        nd.push((el.info_owner_detail || {}).email);
        let stg = el.stage;
        let stgname = { "-1": "Cancelled", "0": "Waiting Response", "1": "Pending Approval", "2": "Closed" }
        nd.push(stgname[stg.toString()] || "Unknown");
        // let filetpye = {"fileuploadtolink":"fileuploadtolink","true":"Done","fileupload":"fileupload","Done":"Done"}
        if (el.type === "fileuploadtolink" && el.response) nd.push("Uploaded to Link");
        else if (el.type === "fileupload" && el.response) nd.push("Uploaded");
        else nd.push(el.response || "");
        //  nd.push(filetpye[el.type] || filetpye[el.response] || "Done");
        // nd.push((el.type === "fileupload")?el.response:((el.type === "fileuploadtolink")?((el.response === "true")?"Done":""):((el.type === "fileupload")?((el.response === "Done")?"Done":""):el.response)));
        nd.push('"' + (el.remark || "") + '"');
        rows.push(nd);
      }
      let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "request_list.csv");
      document.body.appendChild(link);
      link.click();
    },
    validateBulkRequest(dt) {
      return new Promise((resolve, reject) => {
        console.log(dt);
        Promise.resolve().then(() => {
          let okdata = [];
          let error = {};
          let infoowners = {};
          for (const k in this.infoownerlistids) {
            if (Object.hasOwnProperty.call(this.infoownerlistids, k)) {
              const el = this.infoownerlistids[k];
              infoowners[el.email] = el._id;
            }
          }
          for (let i = 0; i < dt.length; i++) {
            const el = dt[i];
            console.log(el);
            if (i === 0) {
              if (el.join(",") !== "description,category,info_owner_name,info_owner_email,file_upload,file_upload_link,urgent,due_date") {
                reject("all required columns were not found!");
                break;
              }
            } else if (el.length === dt[0].length) {
              // console.log("in")
              let lerr = [];
              let lokd = {};
              if (el[0]) lokd["description"] = el[0];
              else lerr.push("Description is required");
              if (el[1]) lokd["category"] = el[1];
              if (!el[3]) lerr.push("info_owner_email is required");
              else if (infoowners[el[3]]) {
                lokd["info_owner"] = infoowners[el[3]];
                lokd["info_owner_email"] = el[3];
                lokd["info_owner_name"] = el[2];
              }
              else if (!el[2]) lerr.push("info_owner_name is required");
              else {
                lokd["info_owner_email"] = el[3];
                lokd["info_owner_name"] = el[2];
              }
              let fu = (el[4] || "").toLowerCase();
              if (el[5]) lokd["uploadlink"] = el[5];
              else lokd["uploadlink"] = "";
              if (fu === "y" || fu === "yes") {
                lokd["fileupload"] = true
                if (this.isFree) {
                  if (!lokd["uploadlink"]) lerr.push("file_upload_link is required");
                }
              } else lokd["fileupload"] = false
              let ur = (el[6] || "").toLowerCase();
              if (ur === "y" || ur === "yes") lokd["urgent"] = true;
              else lokd["urgent"] = lokd["urgent"] = false;
              if (el[7]) {
                if (this.$nova.isValidDate(el[7], "DD-MM-YYYY")) lokd["duedate"] = this.$nova.formatDateISO(el[7], "DD-MM-YYYY");
                else lerr.push("Invalid due_date");
              }
              if (lerr.length > 0) {
                error['index' + (okdata.length)] = lerr;
                lokd["status"] = false;
              } else lokd["status"] = true;
              okdata.push(lokd);
            } else if (el.length > 1) {
              let nd = { ...el };
              nd["status"] = -1;
              okdata.push(nd);
              error['index' + (okdata.length)] = ["Incomplete data"];
            }
          }
          resolve({ "data": okdata, "error": error });
        }).catch(err => {
          reject(err);
        })
      })
    },
    local_sankbar(content, type) {
      console.log("FF");
      this.snackbar_text.content = content
      this.snackbar_text.type = type ?? 'success'
      this.snackbar_status = true
    },

    addTableHeader(item) {
      this.tableheaders.find((x) => x === item ? x.visible = !x.visible : "")
    },
    setPaginatedData(paginatedData) {
      this.paginatedItems = paginatedData;
    },
    select(isSelected) {
      if (isSelected) {
        this.selectItem = this.paginatedItems.map(item => item._id);
      } else {
        this.selectItem = [];
      }
    },
    filterPart(item,header){
        console.log(item,header);
        this.requestlist = this.$nova.filterByDynamicCriteria(this.requestlistCopy, item);  
      },
    clearPart(item){
        console.log(item,"item");
        this.requestlist = this.requestlistCopy;
      }
  },
  computed: {
    requesttypedropdown: function () {
      let r = [];
      for (const k in this.requesttype) {
        if (Object.hasOwnProperty.call(this.requesttype, k)) {
          const el = this.requesttype[k];
          r.push({ name: el, value: k });
        }
      }
      return r;
    },
    getLocationName() {
      return (id) => {
        const location = this.locationList.find(loc => loc._id === id);
        return location ? location.name : '';
      };
    },
    getlocationById() {

      return (id) => {


        if (typeof id === 'string') {
          let taskdetail = this.reflist.filter(loc => loc.value === id);
          if (taskdetail) {
            taskdetail = this.getRefById(taskdetail.value)
          }
          return taskdetail ? taskdetail.location : '';
        }
        if (typeof id === 'object' && Object.keys(id).includes('location')) {
          let locationID = this.getLocationName(id?.location)

          return locationID ? locationID : 'NA';
        }
      };
    },
    getNameById() {

      return (id) => {
        const taskdetail = this.reflist.find(loc => loc.value === id || loc.master_id === id);
        return taskdetail ? taskdetail.name : 'NA';
      };
    },
    getRefById() {
      return (id) => {
        const reference = this.reflist.find(loc => loc.value === id || loc.master_id === id);
        return reference ? reference : 'NA';
      };
    },
    getUserById() {
      return (id) => {
        const name = this.userlist.find(loc => loc._id === id );
        console.log("wert6y7u8i765",name,id);
        
        return  name ? name.name :'NA';
      };
    },
    filteredHeaders() {
      const searchTerm = this.searchcolumn.toLowerCase();
      return this.tableheaders.filter(header =>
        header.text.toLowerCase()?.includes(searchTerm)
      );
    },

    filterbysearch() {
      const searchTerm = this.searchQuery.trim().toLowerCase();
      if (searchTerm) {
        return this.filterbytabselected.filter(item =>
          item.description.toLowerCase().includes(searchTerm)
        );
      }
      else {
        return this.filterbytabselected
      }
    },

    filterbytabselected() {
      if (this.selectedTab !== null) {
        return this.requestlist.filter((item) => item.stage === this.selectedTab)
      }
      return this.requestlist
    },
    // removeAllSelect(id){

    //   this.selectItem.push(id)
    // }

  },
  watch: {
    "$store.state.client": function () {
      this.refreshData();
    },
    "$route": function (d) {
      if (d.hash === "#add") this.addNewRequest();
    },
    "addrequestdialog": function (d) {
      if (!d) this.$nova.gotoLink({ hash: '' });
    },
    "bulkuploadfile": function (d) {
      this.okBulkRequestData = [];
      this.errorBulkRequestData = {};
      if (d) {
        this.$nova.readCSV(d, {}).then(dt => {
          console.log(dt);
          return this.validateBulkRequest(dt);
        }).then(dt => {
          this.okBulkRequestData = dt.data || [];
          this.errorBulkRequestData = dt.error || {};
          console.log(this.errorBulkRequestData);
        }).catch(err => {
          console.log(err);
          this.errorBulkRequestData["error"] = err;
        })
      } else this.okBulkRequestData = [];
    }
  }
}
</script>

<style>
.pbc-view-table .status-td {
  max-width: 120px !important;
}

/* .pbc-view-table .sl-no{
      max-width: 30px !important;
    } */
.response-td {
  max-width: 300px !important;
}

.response-td .response-td-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>

<style lang="scss" scoped>
.comment-text {
  max-width: 80%;
  border-radius: 15px;
  border: 1px solid var(--v-primary-base);
}

.comments-container {
  height: 30vh;
}
</style>
my7 name us