<template>
  <Analysis :project="vid" embed></Analysis>
</template>

<script>
import Analysis from '../../../flux/views/AnalysisNew.vue';

export default {
  name: 'financialclose_varienceanalysis_analysis',
  // props: ['props'],
  data: function () {
    return {
      vid: "",
    }
  },
  components: {
    Analysis
  },
  created() {
    this.vid = localStorage.getItem("VarianceAnalysis_id");
    console.log("this.vid anyalysis",this.vid);
  },
  activated() {
    this.vid = localStorage.getItem("VarianceAnalysis_id");
  },

  methods: {
    refreshData() {
    },

  },
  computed: {
  },
  watch: {
  }
}
</script>

