<template>
  <Configuration :project="vid" embed></Configuration>
</template>

<script>
import Configuration from '../../../flux/views/ConfigurationNew.vue';

export default {
  name: 'financialclose_varienceanalysis_configuration',
  // props: ['props'],
  data: function () {
    return {
      vid: "",
    }
  },
  components: {
    Configuration
  },
  created() {
    this.vid = localStorage.getItem("VarianceAnalysis_id");
    console.log("this.vid config",this.vid );
  },
  activated() {
    this.vid = localStorage.getItem("VarianceAnalysis_id");
  },

  methods: {
    refreshData() {
    },

  },
  computed: {
  },
  watch: {
  }
}
</script>

