<template>
	<div class="d-flex justify-center filter-hover">
		<div class="border-on d-flex shadow-on radius-small">
			<template v-for="(header) in filterHeadersCompute">
				<div class="text-center px-4 py-2 hoverable" :key="header.key">
					<div class="grey--text text--darken-1 line-1">{{ header.name }}</div>
					<div class="font-weight-bold">
						<v-menu max-width="300" offset-y :close-on-content-click="false" rounded="lg">
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon x-small :id="header.key" v-bind="attrs" v-on="on" @click="setHeader(header.key)">
									<v-icon x-small class="grey--text text--lighten-1" v-if="header.type === 'daterange' && (!selectedDateRange[header.key]?.start || !selectedDateRange[header.key]?.end)">mdi-filter</v-icon>
									<v-icon x-small class="grey--text text--lighten-1" v-else-if="header.type !=='daterange' && (!filterSearch[header.key] || filterSearch[header.key]?.length === 0 || !selectedFilterItemsv2?.[header.key] || selectedFilterItemsv2?.[header.key]?.length === 0 )">mdi-filter</v-icon>
									<v-icon x-small class="black--text text--lighten-0" v-else>mdi-filter</v-icon>
								</v-btn>
							</template>
							<v-card v-if="typeof header.type !==undefined &&  header.type === 'daterange'">
								<div class="ml-3 mt-4 mr-3">
									<lb-date  label="Period From" v-model="selectedDateRange[header.key].start" :max="selectedDateRange[header.key]?.start || $nova.formatDateISO(new Date()) || 0" />
									<lb-date  label="Period To"  v-model="selectedDateRange[header.key].end" :min="selectedDateRange[header.key].start" :max="$nova.formatDateISO(new Date())" />
								</div>
							</v-card>
							<v-card max-height="400" v-else>
								<v-card-text class="pa-0">
									<lb-string class="px-2 pt-2 mt-0" label="Search" v-model="seachFilter[header.key]" hidedetails :isClearable="false"/>
								</v-card-text>
								<div class="mt-2 ml-3 d-flex" v-if="selectedFilterItemsv2[header.key]?.length > 0">
									{{ selectedFilterItemsv2[header.key]?.length }} Selected Items
									<v-spacer></v-spacer>
									<div class="mr-2">
										<v-btn class="" x-small @click="clearFilter(header.key),clearSelections(header.key)">
											<span class="text-capitalize">Clear Selections</span>
										</v-btn>
									</div>
								</div>
								<v-tabs v-model="tab" centered height="30" class="mt-2">
									<v-tabs-slider></v-tabs-slider>
									<v-tab href="#tab-1" class="text-transform-none justify-start">
										<v-icon class="pa-1">mdi-checkbox-blank-outline</v-icon>
										Unselected
									</v-tab>
									<v-tab href="#tab-2" class="text-transform-none justify-start">
										<v-icon class="pa-1">mdi-checkbox-marked</v-icon>
										Selected
									</v-tab>
								</v-tabs>
								<v-tabs-items v-model="tab">
									<v-tab-item value="tab-1">
										<v-card-text class="pa-0 ma-0 pt-2 scroll">
											<v-checkbox
												v-for="(option, k) in getFilterOptionsList(seachFilter[header.key], header.data, header.value).slice(0, 25)"
												:key="k" class="pa-2 mt-0 pt-0" dense multiple :label="option[header.value]" :value="option"
												hide-details v-model="filterSearch[header.key]" @change="saveFilter(header.value, option)" />
											<div v-if="getFilterOptionsList(seachFilter[header.key], header.data, header.value).length > 25"
												class="mx-2 my-1 grey--text caption">
												{{ getFilterOptionsList(seachFilter[header.key], header.data, header.value).length - 25 }} more
											</div>
										</v-card-text>
									</v-tab-item>
									<v-tab-item value="tab-2">
										<v-card-text class="pa-0 ma-0 pt-2 scroll">
											<v-checkbox v-for="(item, k) in (getSelectedFilterList('',selectedFilterItemsv2[header.key],header.value) || []).slice(0, 25)" :key="k" :value="item"
												class="pa-2 mt-0 pt-0" dense multiple :label="item[header.value]"
												v-model="selectedFilterItemsv2[header.key]" @click="unSelectSelectedItem(header.key, item)"
												hide-details scrollable />
											<div v-if="(getSelectedFilterList('',selectedFilterItemsv2[header.key],header.value)|| []).length > 25"
												class="mx-2 my-1 grey--text caption">
												{{ selectedFilterItemsv2[header.key].length - 25 }} more
											</div>
										</v-card-text>
									</v-tab-item>
								</v-tabs-items>
							</v-card>
						</v-menu>
					</div>
				</div>
			</template>
			<div>
				<lb-divider type="vertical"></lb-divider>
			</div>
			<div class="text-center py-2 px-0 hoverable" v-if="searchOption">
				<div>
					<v-tooltip bottom content-class="tooltip-bottom">
						<template v-slot:activator="{ on, attrs }">
							<v-btn v-bind="attrs" id="search" v-on="on" @click.stop="getFilterData()">
								<v-icon>mdi-magnify</v-icon>
								<span class="text-capitalize">Search</span>
							</v-btn>
						</template>
					</v-tooltip>
				</div>
			</div>
			<div>
				<lb-divider type="vertical"></lb-divider>
			</div>
			<div class="text-center py-2 px-1 hoverable">
				<div>
					<v-tooltip bottom content-class="tooltip-bottom">
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-bind="attrs" v-on="on" id="resetfilters" @click.stop="clearFilter('all'),resetFilters()">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</template>
						<span>Reset filters</span>
					</v-tooltip>
				</div>
			</div>
		</div>
		<div class="ml-4 mt-3" v-if="generateFileOption">
			<v-btn small color="success" @click="genarateFile" v-if="!isFileGenarating" id="generatefile"><span class="text-capitalize">{{ generateFileText }}</span></v-btn>
			<v-btn small color="warning" v-else id="generatingfile">
				<div class="spinner-container">
					<div class="loading-spinner"></div>
				</div>
				Generating
			</v-btn>
		</div>
	</div>
	
</template>

<script>
export default {
	data() {
		return {
			selectedItems: {},
			filterSearch: {},
			selectedOptions: {},
			selectedFilterItemsv2: {},
			selectedDateRange:{},
			filterheadersCopy: [],
			seachFilter: {},
			selectedHeader: '',
			tab: 0
		}
	},
	props: {
		filterheaders: {
			type: Array,
			default: () => []
		},
		loading: {
			type: Boolean,
			default: false
		},
		generateFileOption:{
			type: Boolean,
			default: false
		},
		searchOption:{
			type: Boolean,
			default: true
		},
		isFileGenarating:{
			type:Boolean,
			default: true
		},
		generateFileText : {
			type: String,
			default: 'Generate'
		},
	},
	created() {
		if (!this.loading) {
			this.initializeFilterValues()
		}
	},
	activated() {
		if (!this.loading) {
			this.initializeFilterValues()
		}
	},
	computed: {
		filterHeadersCompute() {
			return this.filterheadersCopy
		}
	},
	methods: {
		initializeFilterValues(isFirst =true) {
			this.filterheadersCopy = this.filterheaders.map(header => {
				if(header.type === "daterange"){
					this.$set(this.selectedDateRange, header.key, {});
				}
				let data = header.data.filter(item => item !== null && item[header.value] !== 'All')
				if(header.defaultSelectAll === true && isFirst === true){
					this.$set(this.selectedFilterItemsv2, header.key, data);
					data = []
				}
				return { ...header, data }
			})
		},
		setHeader(headerKey) {
			this.selectedHeader = headerKey
		},
		saveFilter(key, value) {
			this.filterheadersCopy.forEach((item, index) => {
				if (item.value === key) {
					let data = item.data.filter(dataItem => dataItem[key] !== value[key])
					this.filterheadersCopy[index].data = data || []
				}
			})
			this.selectedFilterItemsv2[this.selectedHeader] = [...(this.selectedFilterItemsv2?.[this.selectedHeader] || []), value]
		},
		unSelectSelectedItem(key, value) {
			this.filterheadersCopy.forEach((item, index) => {
				if (item.key === key) {
					let data = [...[value], ...item.data]
					this.filterheadersCopy[index].data = data || []
					this.selectedFilterItemsv2[this.selectedHeader] = this.selectedFilterItemsv2[this.selectedHeader].filter(selectedItem => selectedItem[item.value] !== value[item.value])
					this.filterSearch[this.selectedHeader] = this.filterSearch[this.selectedHeader].filter(selectedItem => selectedItem[item.value] !== value[item.value])
				}
			})
		},
		getFilterOptionsList(searchText = "", data, key) {
			if (searchText !== "") {
				return data.filter(item => item[key].toLowerCase().includes(searchText.toLowerCase()))
			}
			return data
		},
		getSelectedFilterList(searchText = "", data, key) {
			if (searchText !== "") {
				return data.filter(item => item[key].toLowerCase().includes(searchText.toLowerCase()))
			}
			return data
		},
		getFilterData() {
			this.page = 1
			this.filterheadersCopy.forEach((item) => {
				if (item.type === "daterange") {
					if((this.selectedDateRange[item.key]?.start !== undefined && this.selectedDateRange[item.key]?.start !== "") && (this.selectedDateRange[item.key].end !== undefined && this.selectedDateRange[item.key]?.end !== "")){
						this.selectedFilterItemsv2[item.key] = []
						this.selectedFilterItemsv2[item.key][0] = {_id:this.selectedDateRange[item.key]}
					}else{
						delete this.selectedFilterItemsv2?.[item.key]
					}
				}
			})
			let filter= {...this.selectedFilterItemsv2};
			this.filterheaders.forEach((item) => {
				if(typeof filter?.[item.key] !== "undefined"){
					if (item.type !== "daterange" && filter[item.key].length === item.data.length) {
						delete filter[item.key];
					}
				}
			})
			this.$emit('loadFilterData', { filter: filter, page: 1 })
		},
		clearSelections(key) {
			this.filterheadersCopy.forEach((item, index) => {
				if (item.key === key) {
					// let data = [...this.selectedFilterItemsv2[this.selectedHeader], ...item.data]
					this.filterheadersCopy[index].data = this.filterheaders[index]?.data || []
					this.selectedFilterItemsv2[this.selectedHeader] = []
					if(item.type === "daterange"){
						this.selectedDateRange[item.key] = {};
					}
				}
			})
			this.selectedFilterItemsv2[key] = []
		},
		resetFilters() {
			this.selectedFilterItemsv2 = {}
			this.getFilterData()
		},
		genarateFile(){
			let filter= {...this.selectedFilterItemsv2};
			this.filterheaders.forEach((item) => {
				if(typeof filter?.[item.key] !== "undefined"){
					if (item.type !== "daterange" && filter[item.key].length === item.data.length) {
						delete filter[item.key];
					}
				}
			})
			this.$emit('genarateFile', { filter: filter })
		},
		clearFilter(key){
			if(key === 'all'){
				this.selectedFilterItemsv2 = {}
				this.filterSearch = {}
				this.initializeFilterValues(false);
			}else{
				this.filterheadersCopy.forEach((item, index) => {
					if (item.key === key) {
						this.filterheadersCopy[index].data = this.filterheaders[index].data || []
						delete this.selectedFilterItemsv2[this.selectedHeader]
						delete this.filterSearch[this.selectedHeader] 
					}
				})
			}
		}
	},
	watch:{
		filterheaders:{
			handler() {
				this.initializeFilterValues()
			}
		}
	}
}
</script>

<style>

.spinner-container {
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
  height: 50%; /* Ensure it takes the full height of the parent */
}
</style>
