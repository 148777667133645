<template>
   <div class="all-width screen-height">
    <v-card :loading="loading" class="all-width screen-height shadow-off" :disabled="loading" loader-height="2">
        <v-card-text class="px-0">
          <div class="d-flex align-center px-4 justify-space-between">
            <div class="d-flex">
              <h1 class="d-flex align-center ma-0 text-h5 black--text font-weight-black">Information Manager</h1>
              <v-tooltip bottom content-class="tooltip-bottom" >
                <template v-slot:activator="{ on, attrs }"> 
                    <v-btn 
                        v-bind="attrs"
                        v-on="on"
                        class="shadow-off ml-1" icon @click="refreshData()"><v-icon
                        size="24">mdi-refresh</v-icon>
                    </v-btn>
                </template>
                <span>Refresh</span>
              </v-tooltip>
            </div>
            <div class="d-flex align-center ma-0">
  
                <bulkPBCUpload @reload="refreshData()" v-if="$nova.hasRight(right, 'add')"/>
                <v-btn small height="4vh" color="black" class="mr-1 px-4 border-dark white--text" 
                    @click=" addeditdialog = true; addeditdata = {};  addeditdataerror = {}; locationUserList = []; customassertions = false;" 
                     v-if="$nova.hasRight(right, 'add')">
                <v-icon class="pr-2">mdi-plus</v-icon>
                <span class="text-capitalize">New Requirement</span>
              </v-btn>
            </div>
          </div>
          <div class="d-flex align-center px-4 justify-space-between my-3 ">
  
            <lb-string placeholder="Search" label="" v-model="search" width="30vh" appendiconinner="mdi-magnify" hidedetails
              :isClearable="false" radius="8px" />
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <v-menu v-if="selectItem.length>0" class="radius-small" :close-on-content-click="false" offset-y transition="slide-y-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-tooltip left content-class="tooltip-left">
                  <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                  <v-btn icon v-bind="{ ...attrs, ...tooltipAttrs }" v-on="{ ...on, ...tooltipOn }"  class="ml-2"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                  </template>
                  <span>Bulk actions</span>
                </v-tooltip>
              </template>
              <v-list  class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width">
              <v-list-title class="ma-0  all-width">
                <div class="d-flex grayBackground align-center pa-2 pb-3 pt-3 pb-1 justify-space-between">
                  <p class="ma-0 font-weight-bold">Bulk Actions</p>
                  <v-icon color="primary">mdi-information-outline</v-icon>
                </div>
                <v-divider></v-divider>
              </v-list-title>
              <v-list-item  class="d-flex px-2 all-width align-center  pa-0 ma-0 gray--text" @click="editRequestUser=true;getrequstedfromusers()">
                <v-icon>mdi-pencil-outline</v-icon>
                Edit 'Requested From'
              </v-list-item>
            </v-list>
            </v-menu>
  
            <v-menu  :close-on-content-click="false" offset-y transition="slide-y-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-tooltip left content-class="tooltip-left">
                  <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                  <v-btn icon v-bind="{ ...attrs, ...tooltipAttrs }" v-on="{ ...on, ...tooltipOn }"  class="ml-2 border-on"><v-icon color="caccounting">mdi-table-large-plus</v-icon></v-btn>
                  </template>
                  <span>Customize column</span>
                </v-tooltip>
              </template>

              <v-list style="max-height:50vh ; overflow:hidden; z-index:13 !important"
                class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width">
                <v-list-title class="ma-0  all-width">
                  <div class="d-flex grayBackground align-center pa-2 pb-3 pt-3 pb-1 justify-space-between">
                    <p class="ma-0 font-weight-bold">Customize Column</p>
                  </div>
                  <v-divider></v-divider>
                  <lb-string label="" placeholder="Search" class="px-2 mt-3 mb-2" v-model="searchcolumn" width="30vh"
                    :clearable="false" appendiconinner="mdi-magnify" hidedetails radius="8px" />
                </v-list-title>


                <v-list-item style="max-height:25vh ; overflow:scroll"
                  class="d-flex all-width align-start pa-0 ma-0 flex-column">
                  <v-list-item v-for="(item, index) in filteredHeaders" class="d-flex pa-0 px-2 ma-0 " :key="index">
                    <v-checkbox color="caccounting" v-if="item.text !== 'Action'" :ripple="false" class="pa-0 ma-0"
                      @change="addTableHeader(item)" hide-details :input-value="item.visible"
                      :label="item.text"></v-checkbox>
                  </v-list-item>

                </v-list-item>

              </v-list>
            </v-menu>
          </div>
          <lb-datatableaction :headers="headers" :tabledata="formateDataCopy" :itemsperpage="itemsPerPage" :searchterm="search" @select-all-change="select" :isDataSelected="selectItem.length>0 && selectItem.length < paginatedFilteredItems.length? true: false" @filter-item="filterPart" @clear-item="clearPart">

            <template v-slot:tbody>
                <tr v-for="item in paginatedFilteredItems" :key="item._id" class="cursor-pointer  "
                  :class="{ 'low-opacity': item.status === false }">
                  <td class=" px-2 d-flex align-center justify-center">
                    <v-checkbox dense color="caccounting" class="ma-0 py-1   single-line" :ripple="false" hide-details
                        :value="item['_id']" v-model="selectItem" @click="removeAllSelect()"></v-checkbox>
                  </td>
                  <template v-for="(header,index) in headers">
                    <td v-if="header.visible && header.value !== 'action'" :key="index" class="px-2 single-line" :class="{'description-header': header.value === 'description'}">
                      <div class="" v-if="header.value === 'tasklist'">
                        <div class="" v-if="(item.tasklist || []).length > 0">
                          <template >
                            <v-tooltip bottom  content-class="tooltip-bottom" >
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-if="item.tasklist?.[0].checklist_id" v-bind="attrs" v-on="on" class="pa-2 caccounting-background radius-mini caccounting--text mr-2" :key="index">{{(getTaskName(item.tasklist?.[0].checklist_id)).ref }}</span>
                                </template>
                                <div class=" pa-2 ma-0 d-flex flex-column" style="max-width:20vh">
                                  {{(getTaskName(item.tasklist?.[0]?.checklist_id)).description }}
                                 <p class="mt-2"> <v-icon class="mr-2 " color="white">mdi-map-marker</v-icon>
                                  {{(getLocationName(item.tasklist?.[0]?.location)) }}</p>
                                </div>
                              </v-tooltip>
                            <span v-if="item.tasklist.length >2 && item.tasklist?.[0].checklist_id"
                              class=" radius-mini caccounting-background radius-mini text-caption caccounting--text pa-2 ">+{{ item.tasklist.length - 2 }}</span>
                          </template>
                         
                        </div>
                      </div>
                      <div class="d-flex " v-else-if="header.value === 'location'">
                        <div class="d-flex">
                          <p class="ma-0 mt-2 mr-2">{{(getLocationName(item.tasklist?.[0]?.location)) }}</p>
                          <span v-if="item.tasklist.length >2 && item.tasklist?.[0]?.checklist_id"
                            class=" radius-mini caccounting-background radius-mini text-caption caccounting--text pa-2 ">+{{ item.tasklist.length - 2 }}</span>
                        </div>
                      </div>
                      <div class="d-flex " v-else-if="header.value === 'task_category'">
                        <div class="d-flex">
                          <p class="ma-0 mt-2 mr-2">{{(getTaskName(item.tasklist?.[0]?.checklist_id)).category }}</p>
                         </div>
                      </div>
                      <span v-else> {{ getItemData(item, header.value) }}</span>
                    </td>
                  </template>
                  <td class=" px-3 single-line border-right border-left action-data">
                    <v-tooltip left content-class="tooltip-left">
                      <template v-slot:activator="{ on }">
                        <v-btn icon @click="addEditForm(item)" v-on="on"><v-icon>mdi-pencil-outline</v-icon></v-btn>
                      </template>
                      Edit
                    </v-tooltip>

                  
                    <v-tooltip bottom content-class="tooltip-bottom" v-if="item.status">
                      <template v-slot:activator="{ on, attrs }"> 
                      <v-btn  v-bind="attrs" v-on="on" icon @click="enableDisableAccess(item._id, 'disable')"><v-icon
                          color="success">mdi-check-circle</v-icon></v-btn>
                      </template>
                      <span>Enable</span>
                    </v-tooltip>

                    <v-tooltip bottom content-class="tooltip-bottom"  v-else>
                      <template v-slot:activator="{ on, attrs }"> 
                          <v-btn v-bind="attrs" v-on="on" icon
                              @click="enableDisableAccess(item._id, 'enable')"><v-icon
                                  color="error">mdi-close-circle</v-icon></v-btn>
                      </template>
                      <span>Disable</span>
                    </v-tooltip>

                    
                  </td>

                </tr>
            </template>

          </lb-datatableaction>

          <Pagination :list="filteredItemsfromsearch" :itemsperpage="itemsPerPage" @paginated-data="setPaginatedData" />
        </v-card-text>
    </v-card>
    
    <lb-drawer
    v-model="addeditdialog"
    :heading="addeditdata._id ? 'Update' : 'Create New Request'"
    width="600"
    :loading="addeditloading"
  >
    <template v-slot:body>
      <div>
        <v-row>
                 
          <v-col cols="12" class="my-0 py-1">
            <p class="pa-0 ma-0  text-subtitle-1 mb-1">Requested Information*</p>
            <lb-textBox v-model="addeditdata.description" label="" requriedrows="3" :hidedetails="true" :isRequired="true"
              :error="addeditdataerror.description" />
          </v-col>
          <v-col cols="6" class="my-0 py-1">
            <p class="pa-0 ma-0  text-subtitle-1 mb-1">Requested From*</p>
            <lb-string v-model="addeditdata.info_owner_name" label=""  :hidedetails="true" :isRequired="true"
              :error="addeditdataerror.info_owner_name" />
            
          </v-col>
          <v-col cols="6" class="my-0 py-1">
            <p class="pa-0 ma-0  text-subtitle-1 mb-1">Email*</p>
            <lb-string v-model="addeditdata.info_owner_email" label=""  :hidedetails="true" :isRequired="true"
              :error="addeditdataerror.info_owner_email" />
          </v-col>
          <v-col cols="6" class="my-0 py-1">
            <p class="pa-0 ma-0  text-subtitle-1 mb-1">Type*</p>
            <lb-dropdown
                v-model="addeditdata.type"
                label=""
                :items="informationtypes"
                :error="addeditdataerror.type"
                :hidedetails='true'
            />
          </v-col>
          <v-col cols="6" class="my-0 py-1">
            <p class="pa-0 ma-0  text-subtitle-1 mb-1">Priority</p>
            <lb-dropdown
                v-model="addeditdata.priority"
                label=""
                :hidedetails="true"
                :items="requestpriority"
                :error="addeditdataerror.priority"
            />
          </v-col>
          <v-col cols="6" class="my-0 py-1">
            <p class="pa-0 ma-0  text-subtitle-1 mb-1">Classification</p>
            <lb-string 
                v-model="addeditdata.category"
                label=""
                :hidedetails="true"
                :error="addeditdataerror.category"
            />
          </v-col>
          <v-col cols="6" class="my-0 py-1">
            <p class="pa-0 ma-0  text-subtitle-1 mb-1">Due Days</p>
            <lb-number  v-model="addeditdata.duedate" placeholder="Enter value between 1 and 200"
            label="" :error="addeditdataerror.category"/>
          </v-col>
          <v-col cols="12" class="d-flex all-width align-center justify-space-between">
            <p class="ma-0 pa-0 font-weight-bold">Linked tasks</p>


            <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text v-bind="attrs" v-on="on" @click="selecttaskstep=0"> + Add Tasks</v-btn>
                </template>

                <v-card  class="pa-0 ma-0 radius-small" v-if="selecttaskstep===0" >
                  <div class="d-flex align-center pa-2 grayBackground justify-space-between">
                    <p class="ma-0">Select Task</p>
                    <v-icon>mdi-information</v-icon>
                  </div>
                    <lb-string placeholder="Search" label="" v-model="searchTask" class="ma-2" :hidedetails="true"></lb-string>

                      <v-simple-table dense class="border-on overflow-scroll ma-2 simple-table" style="max-height:35vh ;min-width:60vh;">
                          <thead class="grayBackground">
                              <th class="grayBackground py-2">Reference</th>
                              <th class="grayBackground py-2">Description</th>
                          </thead>
                          <tbody>
                            <template v-for="(item,index) in filtertasklist">
                              <tr  :class="`${item._id === selectchecklist_id ? 'selected-item':'' }`" :key="index" class="cursor-pointer" @click="selectLocation(item._id)">
                                  <td>{{item.ref}}</td>
                                  <td class="single-line">{{item.description}}</td>
                              </tr>
                            </template>
                          </tbody>
                      </v-simple-table>
                      <v-divider></v-divider>
                      <div class="d-flex align-center pa-1 justify-end">
                        <v-btn class="primary" @click="selecttaskstep=1">Next</v-btn>
                      </div>
                </v-card>

                <v-card v-else-if="selecttaskstep===1" class="radius-small">
                  <div class="d-flex align-center pa-2 grayBackground justify-space-between">
                    <p class="ma-0">Choose Location</p>
                    <v-icon>mdi-information</v-icon>
                  </div>
                  <lb-string placeholder="Search" label="" class="ma-2" v-model="searchTask" :hidedetails="true"/>
                
                  <div   class=" px-3 overflow-scroll" style="max-height:35vh ;min-width:60vh;">
                    <template v-for="(item,index) in tasklocation_details" >                        
                        <v-checkbox color="caccounting" hide-details :label="item.name" class="ma-0 pa-1 my-2" :key="index" @change="selectTaskLocation(item._id)"></v-checkbox>
                    </template>
                  </div>
                  <v-divider></v-divider>
                  <div class="d-flex align-center justify-end pa-1 ">
                    <v-btn class="primary" @click="selecttaskstep=-1 ; addEditTaskItem()">Submit</v-btn>
                  </div>
                </v-card>

            </v-menu>
          </v-col>
          <v-col cols="12">
          <v-simple-table class="border-on" v-if="tasklistlocation.length>0">
            <thead>
              <th class="grayBackground pa-2">Reference</th>
              <th class="grayBackground pa-2">Description</th>
              <th class="grayBackground pa-2">Location</th>
              <th class="grayBackground pa-2">Actions</th>
            </thead>
            <tbody>
              <tr v-for="(item,index) in tasklistlocation" :key="index">
                <td>{{getTaskName(item.checklist_id).ref}}</td>
                <td>{{getTaskName(item.checklist_id).description}}</td>
                <td>{{getLocationName(item.location)}}</td>
                <td>
                  <v-btn icon @click="removetasklist(item)"> <v-icon>mdi-delete</v-icon></v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:actions>
      <span class="caption">*Required</span>
      <v-spacer></v-spacer>
      <v-btn small color="primary" @click="addEditItem()">
        <span v-if="addeditdata._id">Update</span>
        <span v-else>Create Request</span>
      </v-btn>
    </template>
  </lb-drawer>

  <lb-drawer
  v-model="editRequestUser"
  heading="Edit 'Request From'"
  width="600"
  >
  <template v-slot:body>
    <div class="">
      <v-row>
        <v-col cols="6">
          <v-chip color="lightpurple" class="caccounting--text">
            <v-icon class="mr-1">mdi-information-outline</v-icon>
            {{ selectItem.length }} information requests selected
          </v-chip>
        </v-col>
        <v-col cols="12">
          <p class="ma-0 mb-1">Actions</p>
          <lb-dropdown label="" hidedetails :items="editRequestActions"/>
        </v-col>
        <v-col cols="12">
          <p class="ma-0 mb-1">Existing</p>
          <lb-string label="" v-model="edituserlist.exiting_info_owner_name" hidedetails/>
        </v-col>
        <v-col cols="12">
          <p class="ma-0 mb-1">Email ID</p>
          <lb-string label="" v-model="edituserlist.exiting_info_owner_email" hidedetails/>
        </v-col>
        <v-col cols="12">
          <p class="ma-0 mb-1">Replace With</p>
          <!-- <lb-string label="" v-model="edituserlist.new_info_owner_name" hidedetails/> -->
          <!-- <lb-dropdown v-model="edituserlist.new_info_owner_name" :items="usersList"  label="" placeholder="Select New Information manager"
          :error="addeditdataerror.glcode"  itemtext="name"
          itemvalue="email"/> -->

          <v-select
            v-model="selectedUser"
            :items="usersList"
            item-value="_id"  
            item-text="name"
            label="Select User"
            @change="updateUserInfo"
            outlined
          >
          </v-select>

        </v-col>
        <!-- <v-col cols="12">
          <p class="ma-0 mb-1">Email Id</p>
          <lb-string label="" v-model="edituserlist.new_info_owner_email" hidedetails/>
        </v-col> -->
      </v-row>

    </div>
  </template>
  <template v-slot:actions>
    <span class="caption">*Required</span>
    <v-spacer></v-spacer>
    <v-btn class="py-4" small color="primary" @click="updaterequesteduser()">
      Replace
    </v-btn>
  </template>
  </lb-drawer>

   </div>
</template>
  
  <script>
  import bulkPBCUpload from "../../views/Components/BulkUploadInformationManager.vue";
  import Pagination from "../../../../views/common/Pagination.vue";
  export default {
    name: "financialclose_master_informationmanager",
    // props: ['props'],
    data: function () {
      return {
        right: "financialclose_master_informationmanager",
        addeditdata: {},
        addeditdataerror: {},
        informationtypes: [
          { value: "text", name: "Text" },
          { value: "fileupload", name: "File Upload" },
        ],
        requestpriority:[
            "High",
            "Medium",
            "Low"
        ],
        addeditdialog: false,
        checklist: [],
        addeditloading: false,
        addedittaskloading: false,
        addedittaskdialog:false,
        loading: false,
        listdata: [],
        headers: [
          {
            value: "indexno",
            text: "S No",
            visible: true,
            filterable:false,
            sortable: false,

          },
          {

            value: "description",
            text: "Request Information",
            visible: true,
            filterable:true,
            sortable: true,

          },
          {
            value: "type",
            text: "Type",
            visible: true,
            filterable:true,
            sortable: true,
          },
  
          {
            value: "category",
            text: "Classification",
             visible: true,
            filterable:true,
            sortable: true,
          },
          {
            value: "info_owner_name",
            text: "Requested From",
             visible: true,
            filterable:true,
            sortable: true,
          },
          {
            value: "info_owner_email",
            text: "Email ID",
             visible: true,
            filterable:true,
            sortable: true,
          },
          {
            value: "tasklist",
            text: "Task Linked",
             visible: true,
            filterable:false,
            sortable: false,
          },
          {
            value:"task_category",
            text:"Task Category",
             visible: true,
            filterable:false,
            sortable: false,
          },
          {
            value: "duedate",
            text: "Due Days",
            visible: true,
            filterable:false,
            sortable: false,
          },
          {
            value: "location",
            text: this.$store.state.LocationHeader || 'Location',
             visible: true,
            filterable:false,
            sortable: false,
          },
          {
            value: "created_at",
            text: "Created At",
             visible: true,
            filterable:true,
            sortable: true,
          },
          {
            value: "updated_at",
            text: "Updated At",
             visible: true,
             filterable:true,
            sortable: true,
          },
          {
            value:"priority",
            text:"Priority",
             visible: true,
            filterable:false,
            sortable: false,
          },
          {
            value: "action",
            text: "Action",
            sticky:true,
            visible: true,
            filterable:false,
            sortable: false,
          },
        ],
        selectedTaskHeaders:[
          {  title: 'Reference', align: 'start', key: 'ref',},
          { title: 'Descriptions', align: 'end', key: 'checklist' },
          { title: 'Location', align: 'end', key: 'location' },
          { title: 'Actions', value: 'actions', sortable: false },

        ],
        formatedData: [],
        formateDataCopy:[],
        selectchecklist_id:'',
        selectlocation_id:'',
        tasklistlocation:[],
        addedittaskdataerror:{},
        location_details:[],
        tasklocation_details:[],
        locationfilter:[],
        selectedFilterItems: [],
        searchTask:'',
        selectedTask:'',
        selecttaskstep:0,
        searchcolumn:'',
        search:"",
        itemsPerPage:13,
        selectAll:false,
        selectItem:[],
        editRequestUser:false,
        editRequestActions:["Replace"],
        paginatedFilteredItems:[],
        edituserlist:{},
        usersList:[],
        selectedUser:null,
      };
    },
    created() {
      this.init();
    },
    activated() {
      this.init();
    },
    components: {
      bulkPBCUpload,Pagination
    },
    computed:{
        filtertasklist(){            
            return this.checklist.filter(item =>
                item.description.toLowerCase().includes(this.searchTask.toLowerCase())
            );
        },

        filteredHeaders() {
          const searchTerm = this.searchcolumn.toLowerCase();
          return this.headers.filter(header =>
            header.text.toLowerCase()?.includes(searchTerm)
          );
        },
        filteredItemsfromsearch() {          
          let filteredData = this.formatedData.filter(item =>
            item.description.toLowerCase().includes(this.search.toLowerCase())
          );

          if (this.selectedFilterItems.length > 0) {
            filteredData = filteredData.filter(item => {
              return this.selectedFilterItems.every(filter => {
                return Object.entries(filter).every(([key, values]) => {
                  const itemValue = item[key];
                  return Array.isArray(itemValue)
                    ? itemValue.some(val => values.includes(val))
                    : values.includes(itemValue);
                });
              });
            });
          }

          return filteredData;
        },

    },
    methods: {
      formateData() {
        let ObjectTemp = {};
        let ArrayTemp = [];
        this.listdata.forEach((item, index) => {
          ObjectTemp = {
            __key: index,
            __formatedkey: index,
            _id: item._id,
            indexno: index + 1,
            type: item.type,
            category: item.category?.trim(),
            description: item.description?.trim(),
            duedate:item.duedate,
            info_owner_email: item.info_owner_email?.trim(),
            info_owner_name: item.info_owner_name?.trim(),
            urgent: item.urgent,
            tasklist: item.checklist_id,
            location:this.getLocationName(item.tasklist?.[0]?.location),
            checklist_id_detail: item.checklist_id_detail,
            created_at: item.created_at?.split("T")[0],
            updated_at: item.updated_at?.split("T")[0],
            status: item.status,
          };
          ArrayTemp.push(ObjectTemp);
        });
        this.formatedData = ArrayTemp;
        this.formateDataCopy = ArrayTemp;
      },
      init() {
        this.refreshData();
      },
      setPaginatedData(paginatedData) {
        this.paginatedFilteredItems = paginatedData; 
      },
      refreshData() {
        this.charts = [];
        this.chartdata = {};
        this.listdata = [];
        this.locationfilter = [];
        this.getData();
      },
      getData() {
        this.loading = true;
        return this.axios
          .post("/v2/financialclose/masters/informationmanager/formData")
          .then((dt) => {
            if (dt.data.status === "success") {
              let formdt = dt.data.data[0] || {};
              this.checklist = formdt.checklist || [];
              console.log("wertgtrewqwert",formdt);
              
              return this.axios.post(
                "/v2/financialclose/masters/informationmanager/get"
              );
            } else throw new Error("Error reading form data");
          })
          .then((dt) => {
            this.listdata = [];
            if (dt.data.status === "success") {
              this.listdata = dt.data.data;
              this.formateData();
            } else throw new Error(dt.data.message);
          }).then(() => {
             this.axios
                .post("/v2/financialclose/location/get")
                .then((ele) => {
                  this.location_details = ele.data.data;
                })
                .catch((err) => {
                  console.log(err);
                });
          }).then(() => {
          return this.axios.post(
            "/v2/financialclose/masters/checklist/getformdata"
          );
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            let formdata = dt.data.data[0] || {};
            this.usersList = formdata.users;
           
          } else throw new Error(dt.data.message);
        })
          .catch((err) => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
          })
          .finally(() => {
            this.loading = false;
          });
      },
      addTableHeader(item) {
        this.headers.find((x) => x === item ? x.visible = !x.visible : "")
      },
      select() {
        this.selectItem = [];
        this.selectAll=!this.selectAll

        if (this.selectAll) {
          for (let i in this.paginatedFilteredItems) {
            this.selectItem.push(this.paginatedFilteredItems[i]['_id']);
          }
        } else {
          this.selectItem = [];
        }
      },
      removeAllSelect() {
        if (this.selectItem.length === this.formatedData.length) this.selectAll = true;
        else this.selectAll = false;
      },
      addForm(){
        this.addeditdata = {};
        this.addeditdataerror = {};
        this.tasklocation_details=[],
        this.addeditdialog = true;
        this.tasklistlocation = [];
      },
      addEditForm(dt = {}) {
        this.addeditdata = { ...dt };
        
        this.tasklistlocation = JSON.parse(JSON.stringify(this.addeditdata.tasklist));
        this.addeditdataerror = {};
        this.addeditdialog = true;
        //tasklistlocation
      },
      getItemData(item, key) {
        return item[key];
      },
      addEditItem() {
        this.addeditloading = true;
        let type = "add";
        let url = "/v2/financialclose/masters/informationmanager/add";
        if (this.addeditdata._id) {
          type = "edit";
          url =
            "/v2/financialclose/masters/informationmanager/edit/" +
            this.addeditdata._id;
        }
        this.addeditdata.checklist_id = this.tasklistlocation;
        let ndt = { ...this.addeditdata };
        delete ndt._id;
        this.axios
          .post(url, { data: ndt })
          .then((dt) => {
            if (dt.data.status === "success") {
              if (type === "add")
                this.$store.commit("sbSuccess", "New Request added");
              else this.$store.commit("sbSuccess", "Request updated");
              this.addeditdialog = false;
              this.refreshData();
            } else {
              if (dt.data.data.length > 0) {
                if (dt.data.data[0].index0)
                  this.addeditdataerror = dt.data.data[0].index0;
              } else throw new Error(dt.data.message);
            }
          })
          .catch((err) => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.addeditloading = false;
          });
      },
      enableDisableAccess(id, action) {
        this.loading = true;
        let url = "/v2/financialclose/masters/informationmanager/disable/";
        if (action === "enable") {
          url = "/v2/financialclose/masters/informationmanager/enable/";
        }
        this.axios
          .post(url + id, {})
          .then((dt) => {
            if (dt.data.status === "success") {
              if (action === "enable") {
                this.$store.commit("sbSuccess", "Item enabled");
                // this.listdata[k].status = true;
              } else {
                this.$store.commit("sbSuccess", "Item disabled");
                // this.listdata[k].status = false;
              }
              this.refreshData();
            } else throw new Error(dt.data.message || "Error performing action");
          })
          .catch((err) => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      selectTaskLocation(id){
        this.selectlocation_id=[...this.selectlocation_id , id]        
        console.log("wertyujk",this.selectlocation_id);
      },
      addEditTaskItem(){
        if (this.selectchecklist_id && this.selectlocation_id  ) {
          this.selectlocation_id.map((item)=>{
            let obj = {
              checklist_id:this.selectchecklist_id,
              location:item
            }
            this.tasklistlocation.push(obj);
          })
          this.addedittaskdialog = false;
          
        } else {
           this.$store.commit("sbError", "Taskllist and location Required");
        }
        this.selectchecklist_id = ""
        this.selectlocation_id = ""
      },
      getLocationName(locationId) {
        const location = this.location_details.find(
          (loc) => loc._id === locationId
        );
        return location ? location.name : "";
      },
      getTaskName(taskId) {
        const task = this.checklist.find((task) => task._id === taskId);
        return task ? task : "";
      },
       removetasklist(item){
        let indexItem = this.tasklistlocation.indexOf(item);
        this.tasklistlocation.splice(indexItem, 1);
      },
      selectLocation(Id){
        let taskLoc = '';
        if (Id) {
           taskLoc = this.checklist.find((x) => x._id === Id);
        }        
        this.selectchecklist_id=Id
        this.tasklocation_details = taskLoc.location;        
      },
      locationFilterFun(locationid){
        this.loading = true;
        if (locationid) {
          this.axios
            .post("/v2/financialclose/masters/informationmanager/getinfomationwithfilter/"+locationid)
            .then((ele) => {
               if (ele.data.status === "success") {
                this.listdata = ele.data.data;
                this.formateData();
               }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
            this.loading = false;
          });
        }
      },
      getrequstedfromusers() {
        
        const selectedItemsData = this.formatedData.filter(item => this.selectItem.includes(item._id));
        console.log("wsedrftghgtrew",selectedItemsData);
        this.edituserlist.exiting_info_owner_name = selectedItemsData.map(item => item.info_owner_name);  
        this.edituserlist.exiting_info_owner_email =selectedItemsData.map(item => item.info_owner_email); 
        this.edituserlist.ids=[...this.selectItem]
      },
      updateUserInfo() {
        console.log("werfgfewwerfgfewerf",this.selectedUser,this.usersList);
        const selectedUserData = this.usersList.find(user => user._id === this.selectedUser);
      if (selectedUserData) {
        this.edituserlist.new_info_owner_name = selectedUserData.name;
        this.edituserlist.new_info_owner_email = selectedUserData.email;
      }
      
    },
      updaterequesteduser(){
        this.loading = true;
          this.axios
            .post("/v2/financialclose/masters/informationmanager/updateinfouser",{data:[this.edituserlist]})
            .then((ele) => {
               if (ele.data.status === "success") {
                 this.editRequestUser=false
                this.refreshData();
               }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
            this.loading = false;
          });
      },
    filterPart(item){
      console.log(item);
      this.formatedData = this.$nova.filterByDynamicCriteria(this.formateDataCopy, item);  
    },
    clearPart(item){
      console.log(item,"item");
      this.formatedData = this.formateDataCopy;
    }
    },
    watch: {
    },
  };
  </script>
  