const moment = require('moment');
import routerobj from '../router';
const router = routerobj.router;
import store from '../store';

// console.log(process.env.VUE_APP_API_URL)

export default {
    install(Vue) {
        Vue.prototype.$nova = objects;
    }
}

let objects = {
    // Global libs
    moment: moment,

    colorlist: [
        "#00ff00",
        "#9c27b0",
        "#4775FF",
        "#EB5353",
        "#f57c00",
        "#24A19C",
        "#6263A7",
        "#825E4A",
        "#B28106",
        "#F7508D",
        "#77A6A5",
        "#957593",
        "#8bc34a",
        "#535679",
        "#3692D3",
        "#666C60",
    ],

    // Data
    ui: {},
    projectname: '',
    formData: {},
    uploadData: {},


    // functions
    getHighlitedText(words, query) {
        var iQuery = new RegExp(query, "ig");
        return words.toString().replace(iQuery, function(matchedTxt) {
            return ('<span class=\'highlight\'>' + matchedTxt + '</span>');
        });
    },

    numToletter: function(num) {
        if (num === 0) return ""
        var mod = num % 26,
            pow = num / 26 | 0,
            out = mod ? String.fromCharCode(64 + mod) : (--pow, '');
        return pow ? objects.numToletter(pow) + out : out;
    },

    getStore: function(){
        // console.log(store.state["m_" + ((router.currentRoute.meta || {}).modulename || "")] || {});
        return (store.state["m_" + ((router.currentRoute.meta || {}).modulename || "")] || {});
    },
    getDate: () => { return new Date(); },
    isValidDate: function(dt, f = "YYYY-MM-DD") {
        return moment(dt, f).isValid();
    },
    formatDate: function(dt, opf = "D MMM YYYY", inpf = "YYYY-MM-DD") {
        return formatDateValue(dt, opf, inpf);
    },
    formatDateTime: function(dt, opf = "D MMM YYYY hh:mm A", inpf = undefined) {
        return formatDateValue(dt, opf, inpf);
    },
    formatTime: function(dt) {
        return formatTimeValue(dt);
    },
    formatDateISO: function(dt, inpf = "YYYY-MM-DD") {
        return formatDateValue(dt, undefined, inpf);
    },
    getDateRange: function(r, cr = []) {
        let rt = [];
        if (r == 'today') {
            rt.push(moment().format());
        } else if (r == 'thisweek') {
            rt.push(moment().startOf("w").format());
            rt.push(moment().endOf("w").format());
        } else if (r == 'thismonth') {
            rt.push(moment().startOf("M").format());
            rt.push(moment().endOf("M").format());
        } else if (r == 'lastmonth') {
            rt.push(moment().subtract(1, 'M').startOf("M").format());
            rt.push(moment().subtract(1, 'M').endOf("M").format());
        } else if (r == 'thisquarter') {
            rt.push(moment().startOf("Q").format());
            rt.push(moment().endOf("Q").format());
        } else if (r == 'lastquarter') {
            rt.push(moment().subtract(1, 'Q').startOf("Q").format());
            rt.push(moment().subtract(1, 'Q').endOf("Q").format());
        } else if (r == 'thisyear') {
            if (moment().month() < 3) {
                rt.push(moment().subtract(1, 'y').month(3).startOf("M").format());
                rt.push(moment().month(2).endOf("M").format());
            } else {
                rt.push(moment().month(3).startOf("M").format());
                rt.push(moment().add(1, 'y').month(2).endOf("M").format());
            }
        } else if (r == 'lastyear') {
            if (moment().month() < 3) {
                rt.push(moment().subtract(2, 'y').month(3).startOf("M").format());
                rt.push(moment().subtract(1, 'y').month(2).endOf("M").format());
            } else {
                rt.push(moment().subtract(1, 'y').month(3).startOf("M").format());
                rt.push(moment().month(2).endOf("M").format());
            }
        } else if (r == 'custom') {
            let v1 = "";
            let v2 = "";
            let c = true;
            if (cr instanceof Array) {
                if (cr.length > 1) {
                    if (cr[0] && moment(cr[0]).isValid()) v1 = moment(cr[0]);
                    if (cr[1] && moment(cr[1]).isValid()) v2 = moment(cr[1]);
                    if (v1.isAfter(v2)) {
                        let tm = v1;
                        v1 = v2;
                        v2 = tm;
                    }
                } else if (cr.length == 1) {
                    if (cr[0] && moment(cr[0]).isValid()) {
                        v1 = moment(cr[0]);
                        v2 = v1;
                    }
                } else c = false;
            }
            if (c) {
                rt.push(v1.format());
                rt.push(v2.format());
            }
        }
        return rt;
    },
    checkDateRange: function(v, r, cr = []) {
        // v = value, r = rangeType, cr = customRange 
        let ffm = false;
        if (v instanceof Array) {
            if (v.length === 2) {
                let start = moment(v[0]);
                let end = moment(v[1]);
                if (start > end) {
                    let t = start;
                    start = end;
                    end = t;
                }
                if (r == 'today') {
                    if (moment(new Date()).isBetween(start, end, undefined, '[]')) ffm = true;
                } else if (r == 'thisweek') {
                    if (moment(start).isBetween(new Date(), new Date(), 'w', '[]') || moment(end).isBetween(new Date(), new Date(), 'w', '[]')) ffm = true;
                } else if (r == 'thismonth') {
                    if (moment(start).isBetween(new Date(), new Date(), 'M', '[]') || moment(end).isBetween(new Date(), new Date(), 'M', '[]')) ffm = true;
                } else if (r == 'lastmonth') {
                    if (moment(start).isBetween(moment().subtract(1, 'M'), moment().subtract(1, 'M'), 'M', '[]') || moment(end).isBetween(moment().subtract(1, 'M'), moment().subtract(1, 'M'), 'M', '[]')) ffm = true;
                } else if (r == 'thisquarter') {
                    if (moment(start).isBetween(new Date(), new Date(), 'Q', '[]') || moment(end).isBetween(new Date(), new Date(), 'Q', '[]')) ffm = true;
                } else if (r == 'lastquarter') {
                    if (moment(start).isBetween(moment().subtract(1, 'Q'), moment().subtract(1, 'Q'), 'Q', '[]') || moment(end).isBetween(moment().subtract(1, 'Q'), moment().subtract(1, 'Q'), 'Q', '[]')) ffm = true;
                } else if (r == 'thisyear') {
                    if (moment().month() < 3) {
                        if (moment(start).isBetween(moment().subtract(1, 'y').month(3).startOf("M"), moment().month(2).endOf("M"), 'd', '[]') || moment(end).isBetween(moment().subtract(1, 'y').month(3).startOf("M"), moment().month(2).endOf("M"), 'd', '[]')) ffm = true;
                    } else {
                        if (moment(start).isBetween(moment().month(3).startOf("M"), moment().add(1, 'y').month(2).endOf("M").format(), 'd', '[]') || moment(end).isBetween(moment().month(3).startOf("M"), moment().add(1, 'y').month(2).endOf("M").format(), 'd', '[]')) ffm = true;
                    }
                } else if (r == 'lastyear') {
                    if (moment().month() < 3) {
                        if (moment(start).isBetween(moment().subtract(2, 'y').month(3).startOf("M"), moment().subtract(1, 'y').month(2).endOf("M"), 'd', '[]') || moment(end).isBetween(moment().subtract(2, 'y').month(3).startOf("M"), moment().subtract(1, 'y').month(2).endOf("M"), 'd', '[]')) ffm = true;
                    } else {
                        if (moment(start).isBetween(moment().subtract(1, 'y').month(3).startOf("M"), moment().month(2).endOf("M"), 'd', '[]') || moment(end).isBetween(moment().subtract(1, 'y').month(3).startOf("M"), moment().month(2).endOf("M"), 'd', '[]')) ffm = true;
                    }
                } else if (r == 'custom') {
                    let v1 = "";
                    let v2 = "";
                    let c = true;
                    if (cr instanceof Array) {
                        if (cr.length > 1) {
                            if (cr[0] && moment(cr[0]).isValid()) v1 = moment(cr[0]);
                            if (cr[1] && moment(cr[1]).isValid()) v2 = moment(cr[1]);
                            if (v1.isAfter(v2)) {
                                let tm = v1;
                                v1 = v2;
                                v2 = tm;
                            }
                        } else if (cr.length == 1) {
                            if (cr[0] && moment(cr[0]).isValid()) {
                                v1 = moment(cr[0]);
                                v2 = v1;
                            }
                        } else c = false;
                    }
                    if (c) {
                        if (moment(start).isBetween(v1, v2, 'd', '[]') || moment(end).isBetween(v1, v2, 'd', '[]')) ffm = true;
                    }
                }
            }
        } else {
            if (r == 'today') {
                if (moment(v).isSame(new Date(), "d")) ffm = true;
            } else if (r == 'thisweek') {
                if (moment(v).isBetween(new Date(), new Date(), 'w', '[]')) ffm = true;
            } else if (r == 'thismonth') {
                if (moment(v).isBetween(new Date(), new Date(), 'M', '[]')) ffm = true;
            } else if (r == 'lastmonth') {
                if (moment(v).isBetween(moment().subtract(1, 'M'), moment().subtract(1, 'M'), 'M', '[]')) ffm = true;
            } else if (r == 'thisquarter') {
                if (moment(v).isBetween(new Date(), new Date(), 'Q', '[]')) ffm = true;
            } else if (r == 'lastquarter') {
                if (moment(v).isBetween(moment().subtract(1, 'Q'), moment().subtract(1, 'Q'), 'Q', '[]')) ffm = true;
            } else if (r == 'thisyear') {
                if (moment().month() < 3) {
                    if (moment(v).isBetween(moment().subtract(1, 'y').month(3).startOf("M"), moment().month(2).endOf("M"), 'd', '[]')) ffm = true;
                } else {
                    if (moment(v).isBetween(moment().month(3).startOf("M"), moment().add(1, 'y').month(2).endOf("M").format(), 'd', '[]')) ffm = true;
                }
            } else if (r == 'lastyear') {
                if (moment().month() < 3) {
                    if (moment(v).isBetween(moment().subtract(2, 'y').month(3).startOf("M"), moment().subtract(1, 'y').month(2).endOf("M"), 'd', '[]')) ffm = true;
                } else {
                    if (moment(v).isBetween(moment().subtract(1, 'y').month(3).startOf("M"), moment().month(2).endOf("M"), 'd', '[]')) ffm = true;
                }
            } else if (r == 'custom') {
                let v1 = "";
                let v2 = "";
                let c = true;
                if (cr instanceof Array) {
                    if (cr.length > 1) {
                        if (cr[0] && moment(cr[0]).isValid()) v1 = moment(cr[0]);
                        if (cr[1] && moment(cr[1]).isValid()) v2 = moment(cr[1]);
                        if (v1.isAfter(v2)) {
                            let tm = v1;
                            v1 = v2;
                            v2 = tm;
                        }
                    } else if (cr.length == 1) {
                        if (cr[0] && moment(cr[0]).isValid()) {
                            v1 = moment(cr[0]);
                            v2 = v1;
                        }
                    } else c = false;
                }
                if (c) {
                    if (moment(v).isBetween(v1, v2, 'd', '[]')) ffm = true;
                }
            }
        }
        return ffm;
    },
    formatCurrency: function(dt, i, smartDecimals = false, threecomma = true, decimals = 0) {
        return objects.commaSaperateNumber(dt, smartDecimals, i, true, threecomma, decimals);
    },
    formatNumber: function(dt, smartDecimals = false, threecomma = true, decimals = 0) {
        return objects.commaSaperateNumber(dt, smartDecimals, undefined, false, threecomma, decimals);
    },
    commaSaperateNumber: function(dt, smartDecimal = false, i = undefined, isCurr = false, threecomma = true, decimals = 0) {
        // let inbracket = false;
        // if((d.ui[d.projectname || ""] || {})["negativeamountinbracket"]) inbracket = true;
        let amt = Number(dt) || 0;
        let originalamt = amt;
        let putbracket = false;
        if (amt < 0) {
            amt = amt * -1;
            putbracket = true;
        }
        let dec = decimals;
        if (smartDecimal) {
            if (amt <= 1) dec = 4;
            else if (amt > 10000) dec = 0;
            else dec = 2;
        }
        let localcountry = 'en-IN';
        if (threecomma) localcountry = 'en-US';
        amt = amt.toLocaleString(localcountry, {
            maximumFractionDigits: dec,
            minimumFractionDigits: dec
        });
        let symbol = "₹";
        if (i !== undefined) symbol = i;
        if (isCurr) {
            if (originalamt === 0) amt = "-";
            else amt = (symbol + " " + amt.toString()).trim();
        } else amt = amt.toString();
        if (putbracket) amt = "(" + amt + ")";
        return amt;
    },
    getError(x, el = [], i = 0) {
        let xv = x.split(".");
        if (el[xv[0]]) {
            if (xv.length > 1) {
                let na = xv.slice(1);
                let nel = el[xv[0]] || {};
                if (nel instanceof Array) {
                    if (nel.length > 0) {
                        if (typeof nel[0] === "object" && !(nel[0] instanceof Array)) {
                            nel = nel[0]["index" + i];
                        } else nel = {}
                    } else nel = {};
                }
                return objects.getError(na.join("."), nel);
            } else {
                if (el[xv[0]] instanceof Array && el[xv[0]].length > 0) {
                    let ret = [];
                    if (typeof el[xv[0]][0] === "object" && !(el[xv[0]][0] instanceof Array)) ret = el[xv[0]][0]["index" + i];
                    else ret = el[xv[0]];
                    if (ret instanceof Array) return ret;
                    else return [ret];
                }
            }
        }
        return [];
    },
    getPermission: function(k, ops = {}) {
        let p = [];
        let up = store.state.user || {};
        if (Object.prototype.hasOwnProperty.call(up, "permission")) {
            if (Object.prototype.hasOwnProperty.call(up.permission, k)) {
                let allper = up.permission[k];
                p = p.concat(allper);
                for (let pk in ops) {
                    if (Object.prototype.hasOwnProperty.call(ops, pk)) {
                        if (allper.indexOf(ops[pk]) > -1) p.push(pk);
                    }
                }
            }
        }
        return p;
    },
    // processCondition: function (eld, v, ty = "and", datatypes = {}) {
    //   let acs = eld || {};
    //   let dv = v || {};
    //   let carr = [];
    //   for (let k in acs) {
    //     if (Object.prototype.hasOwnProperty.call(acs, k)) {
    //       let nel = acs[k];
    //       if (k === '$or') carr.push(objects.processCondition(nel, dv, "or"));
    //       else if (k === '$and') carr.push(objects.processCondition(nel, dv));
    //       else if (k === '$not') carr.push(!objects.processCondition(nel, dv));
    //       else {
    //         let r = true;
    //         let vl = (dv || {})[k] || "";
    //         let ck = acs[k];
    //         if ((datatypes.datefields || []).indexOf(k) > -1) {
    //           vl = this.$nova.formatDate(vl);
    //         }
    //         if (Object.prototype.hasOwnProperty.call(ck, "eq")) {
    //           if (ck.eq instanceof Array) {
    //             let eqr = false;
    //             for (let i = 0; i < ck.eq.length; i++) {
    //               let el = ck.eq[i];
    //               if (v[k] === el) {
    //                 eqr = true;
    //                 break;
    //               }
    //             }
    //             r = eqr;
    //           } else {
    //             if (v[k] !== ck.eq) r = false;
    //           }
    //         }
    //         else if (Object.prototype.hasOwnProperty.call(ck, "neq")) {
    //           if (ck.eq instanceof Array) {
    //             let neqr = false;
    //             for (let i = 0; i < ck.eq.length; i++) {
    //               let el = ck.eq[i];
    //               if (v[k] === el) {
    //                 neqr = true;
    //                 break;
    //               }
    //             }
    //             r = !neqr;
    //           } else {
    //             if (v[k] !== ck.eq) r = false;
    //           }
    //         }
    //         else if (Object.prototype.hasOwnProperty.call(ck, "lt")) {
    //           if (v[k] >= ck.lt) r = false;
    //         }
    //         else if (Object.prototype.hasOwnProperty.call(ck, "lte")) {
    //           if (v[k] > ck.lte) r = false;
    //         }
    //         else if (Object.prototype.hasOwnProperty.call(ck, "gt")) {
    //           if (v[k] <= ck.gt) r = false;
    //         }
    //         else if (Object.prototype.hasOwnProperty.call(ck, "gte")) {
    //           if (v[k] < ck.gte) r = false;
    //         }
    //         carr.push(r);
    //       }
    //     }
    //   }
    //   let rt = true;
    //   for (let i = 0; i < carr.length; i++) {
    //     if (ty === "or") rt = rt || !!carr[i];
    //     else rt = rt && !!carr[i];
    //   }
    //   return rt;
    // },
    generateLink: function(data) {
        if (store.state.client) return data + "?cid=" + store.state.client;
        else return data;
    },
    gotoLink: function(ops = {}, sendlink = false) {
        let r = (router.history || {}).current || {};
        let link = {
            path: ops.path ?? r.path ?? "/",
            hash: ops.hash ?? "",
            query: ops.query ?? {...r.query, ...ops.addquery }
        }
        if (sendlink) {
            let queryString = Object.keys(link.query).map(key => key + '=' + link.query[key]).join('&');
            return link.path + '?' + queryString + link.hash;

        } else router.push(link);
    },
    clientName: function() {
        let clientlist = store.state.clientlist;
        let client = store.state.client;
        for (let i = 0; i < clientlist.length; i++) {
            const el = clientlist[i];
            if (el._id === client) {
                return el.name;
            }
        }
        return "Client not found";
    },
    readExcelFile(file, sheet = "", config = {},blank) {
        return new Promise((resolve, reject) => {
            Promise.resolve().then(() => {
                let ignoretop = config.ignoretop || 0;
                let ignoreleft = config.ignoreleft || 0;
                let xlsx = require('xlsx');
                let headerFile = new FileReader();
                let sheetname = sheet;
                headerFile.onload = function(e) {
                    try {
                        let d = e.target.result;
                        let wb = xlsx.read(d, { type: 'binary', cellDates: true, raw: true });
                        if(sheetname === "") sheetname = wb.SheetNames[0];
                        if (wb.SheetNames.indexOf(sheetname) === -1) throw Error("'" + sheetname + "' not found in the file");
                        // let sh = wb[sheetname];
                        let dt = xlsx.utils.sheet_to_json(wb.Sheets[sheetname], { header: 1, blankrows: blank||false });
                        // console.log(dt[0]);
                        dt = dt.splice(ignoretop, dt.length);
                        for (let i = 0; i < dt.length; i++) {
                            const el = dt[i];
                            dt[i] = el.slice(ignoreleft, el.length);
                        }
                        let rdt = [];
                        if(config.removeblankrows){
                            for (let i = 0; i < dt.length; i++) {
                                const el = dt[i];
                                if (el.join("") !== "") rdt.push(el);
                            }
                        }else rdt = dt;
                        resolve(rdt);
                    } catch (err) {
                        reject(err);
                    }
                }
                headerFile.onerror = function(ex) { reject(ex) };
                headerFile.readAsBinaryString(file);
            }).catch(err => { reject(err) });
        })
    },
    uploadFile: function(axios, file, ops = {}) {
        // console.log(file, "FILEOOOO");
        return new Promise((resolve, reject) => {
            Promise.resolve().then(() => {
                if (typeof(file || {}).name != 'string') throw new Error("Invalid file");
                else if (!file.id) {
                    let fl = new FormData();
                    fl.append('file', file);
                    fl.append('client', store.state.client);
                    let url = process.env.VUE_APP_FILE_UPLOAD_API_URL;
                    // console.log(process.env);
                    let progressFunction = () => { return undefined };
                    if (ops.progressfunction) progressFunction = ops.progressfunction;
                    return axios.post(url, fl, {
                        onUploadProgress: progressFunction,
                        timeout: 60 * 60 * 1000,
                        withCredentials: true,
                        headers: {
                            snclientid: store.state.client,
                            'auth-data': JSON.stringify(ops.additionaldata || {}),
                            // 'Accept': 'application/json',
                            // 'content-type': 'multipart/form-data'
                        }
                    });
                } else return { data: { status: "success", data: [{ _id: file.id }] } };
            }).then(dt => {
                resolve(dt);
            }).catch(err => {
                reject(err);
            });
        })
    },

    processConserveFile(file, config, colum) {
        return new Promise((resolve, reject) => {
            Promise.resolve().then(() => {
                let ext = ((file || {}).name || "").split(".").pop();
                // console.log(ext, "FFFFF");
                if (!file)
                    throw new Error("This file is required!");
                else if (colum.length === 0)
                    throw new Error("0 columns found in master");
                else if (!file.name)
                    throw new Error("Upload a valid file");
                else if (["xls", "xlsx", "xlsb"].includes(file.name.split(".").pop().toLowerCase()) && file.size > 1024 * 1024 * 2)
                    throw new Error("File with size > 2MB should be in csv format");
                else if (["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) > -1)
                    return objects.readExcelFile(file, "", config);
                else if (["csv"].indexOf(ext) > -1)
                    return objects.readCSV(file, config);
                throw new Error("Upload a valid file");
            }).then((data) => {
                //console.log(data);
                return objects.finalColumCheck(file, config, colum, data);

            }).then(resolve).catch(reject);
        });
    },

    finalColumCheck(file, config = {}, colum, dataList) {
        // console.log(config, "configSTART");
        return new Promise((resolve, reject) => {
            let ignorerows = Number(config.ignorerows) || 0;
            let ignorecolumns = Number(config.ignorecolumns) || 0;
            let keepduplicate = config.keepduplicate ?? false;
            let columnmap = config.columnmap || colum;
            console.log(columnmap);
            let blankcolumnheaders = config.blankcolumnheaders || "left";
            Promise.resolve().then(() => {
                let selectRow = [...(dataList[ignorerows] || [])];
                let copyindex = 0;
                if (blankcolumnheaders === "bottom") copyindex = 1;
                else if (blankcolumnheaders === "top") copyindex = -1;
                let blankRow = [...(dataList[ignorerows + copyindex] || [])];

                let selectColumsOne = selectRow.slice(ignorecolumns) || [];
                let blankColum = blankRow.slice(ignorecolumns) || [];

                let finalColum = [];
                // console.log(config);
                let displaytablecolumn = 0;
                let subtable = dataList.slice(Math.max(ignorerows - 20, 0),Math.min(ignorerows + 20, dataList.length));
                for (const i of subtable) {
                  displaytablecolumn = Math.max(displaytablecolumn, i.length);
                }
                for (let r = 0; r < (displaytablecolumn || config.columncount || selectColumsOne.length); r++) {
                    if ((selectColumsOne[r] || "") == "") {
                        if (["bottom", "top"].indexOf(blankcolumnheaders) > -1)
                            finalColum.push(blankColum[r] ?? "");
                        else if (blankcolumnheaders === "left")
                            finalColum.push(finalColum[r - 1] ?? "");
                        else finalColum.push(selectColumsOne[r]);
                    } else {
                        finalColum.push(selectColumsOne[r]);
                    }
                }
                finalColum = finalColum.map((x) => (x || "").trim());
                finalColum = finalColum.map(unkownColum);

                let finalheaders = [];
                if (!keepduplicate) {
                    finalheaders = renameFiles(finalColum);
                } else {
                    finalheaders = finalColum;
                }
                // console.log(finalheaders, "finalheaders");
                let reqColumns = [];
                for (let cc = 0; cc < columnmap.length; cc++) {
                    reqColumns.push(columnmap[cc][1]);
                }
                // console.log(reqColumns, "reqColumns");
                let checkColumDiff = reqColumns.filter((x) => finalheaders.indexOf(x) === -1);
                //console.log(checkColumDiff,"checkColumDiff","----config----",config);
                if (checkColumDiff.length > 0) throw new Error("These columns are required '" + checkColumDiff.join(", ") + "'");
                else return;
            }).then(resolve).catch(reject);
        })
    },

    downloadFile: function(id, authdata = {}, link = "", query='') {
        if (link) window.open(process.env.VUE_APP_API_URL + link + "?cid=" + store.state.client+query, "_blank");
        else window.open(process.env.VUE_APP_API_URL + "/v2/upload/download/" + id + "/a/" + JSON.stringify(authdata) + "?cid=" + store.state.client, "_blank");
    },
    downloadFiles: function (id, authdata = {}, link = "") {
        if (link) window.open(process.env.VUE_APP_API_URL + link + "?cid=" + store.state.client, "_blank");
        else window.open(process.env.VUE_APP_API_URL + "/v2/upload/downloadmultiplefiles/" + id + "/a/" + JSON.stringify(authdata) + "?cid=" + store.state.client, "_blank");
    },
    move_array_item: function(arr, old_index, new_index) {
        let icount = arr.length - 1;
        if (old_index >= 0 && old_index <= icount && new_index >= 0 && new_index <= icount) arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    },
    getColors: function(n, gradient = 1) {
        let l = [];
        for (let i = 0; i < n; i++) {
            let ci = ((i) % (objects.colorlist.length));
            const el = objects.colorlist[ci];
            l.push(objects.hexToRgbA(el, gradient));
        }
        return l;
    },
    hexToRgbA: function(hex, alpha = 1) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
        }
        return hex;
    },
    rgba2hex: function(orig) {
        var a,
            rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
            alpha = (rgb && rgb[4] || "").trim(),
            hex = rgb ?
            (rgb[1] | 1 << 8).toString(16).slice(1) +
            (rgb[2] | 1 << 8).toString(16).slice(1) +
            (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

        if (alpha !== "") {
            a = alpha;
        } else {
            a = "01";
        }
        // multiply before convert to HEX
        a = ((a * 255) | 1 << 8).toString(16).slice(1)
        hex = a + hex;

        return hex;
    },
    copyText(d) {
        navigator.clipboard.writeText(d);
        store.commit("sbSuccess", "Copied");
    },
    getCopyText(d) {
        return navigator.clipboard.readText(d);
    },
    deepCopyJson(d) {
        return JSON.parse(JSON.stringify(d));
    },
    readCSV(file, ops = {}) {
        return new Promise((resolve, reject) => {
            let t = ops.top || 0;
            let l = ops.left || 0;
            let res = ops.response || "array";
            let readCSVFile = new FileReader();
            let dt = [];
            let delimit = ops.delimiter || ",";
            readCSVFile.onload = function(e) {
                dt = CSVToArray(e.target.result, delimit);
                if (dt.length >= t + 1) dt = dt.slice(Math.max(t, 0));

                for (let i = 0; i < dt.length; i++) {
                    if (l > 0) {
                        dt[i] = objects.slice(Math.max(l, 0));
                    }
                }
                let rdt = [];
                if(ops.removeblankrows){
                    for (let i = 0; i < dt.length; i++) {
                        const el = dt[i];
                        if (el.join("") !== "") rdt.push(el);
                    }
                }else rdt = dt;
                if (res === "array") resolve(rdt);
                else resolve(rdt);
            }
            readCSVFile.onerror = function(ex) { reject(ex) };
            if (typeof file.name === "string") {
                let regex = /^([a-zA-Z0-9\s_\\.\-:()])+(.csv)$/;
                if (regex.test(file.name.toLowerCase())) readCSVFile.readAsText(file.slice(0, 1024 * 1024 * 20));
                else reject("Invalid file");
            } else reject("Invalid file")
        });
    },
    hasRight(right, action){
        let hasright = true
        if(right){
          let permission = store.state.permission;
          if(Object.prototype.hasOwnProperty.call(permission, right)){
            if(action){
              if(action instanceof Array){
                if(!(action.some(val => permission[right].includes(val)))) hasright = false;
              }else{
                if(!(permission[right].includes(action))) hasright = false;
              }
            }
          }else hasright = false;
        }
        return hasright;
      },

      filterByDynamicCriteria(array, criteria) {
        return array.filter(item => {
            // Iterate over all keys in the criteria object
            return Object.keys(criteria).every(key => {
                const criterionValue = criteria[key]; // Get the value from the criteria
                const itemValue = item[key]; // Get the value from the array item
    
                if (Array.isArray(criterionValue) && criterionValue.length > 0) {
                    // Both criterionValue and itemValue should be arrays
                    if (Array.isArray(itemValue)) {
                        // Check if there is any overlap between the two arrays
                        return criterionValue.some(val => itemValue.includes(val));
                    }
                    // If itemValue is not an array, filter by exact match
                    return criterionValue.includes(itemValue);
                }
    
                // If the criterionValue is not an array or is empty, skip filtering for this key
                return true;
            });
        });
    },
  
}


function unkownColum(item) {
    if (item == "") {
        return "Unnamed";
    } else {
        return item;
    }
}

function renameFiles(arr) {
    var count = {};
    arr.forEach(function(x, i) {
        if (arr.indexOf(x) !== i) {
            var c = x in count ? (count[x] = count[x] + 1) : (count[x] = 1);
            var j = c + 1;
            var k = x + (j - 1);

            while (arr.indexOf(k) !== -1) k = x + "(" + ++j + ")";
            arr[i] = k;
        }
    });
    return arr;
}

function CSVToArray(strData, strDelimiter) {
    strDelimiter = (strDelimiter || ",");
    var objPattern = new RegExp(
        (
            // Delimiters.
            "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
            // Quoted fields.
            "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
            // Standard fields.
            "([^\"\\" + strDelimiter + "\\r\\n]*))"
        ),
        "gi"
    );
    var arrData = [
        []
    ];
    var arrMatches = null;
    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    // eslint-disable-next-line no-cond-assign
    while (arrMatches = objPattern.exec(strData)) {
        // Get the delimiter that was found.
        var strMatchedDelimiter = arrMatches[1];
        // Check to see if the given delimiter has a length
        // (is not the start of string) and if it matches
        // field delimiter. If id does not, then we know
        // that this delimiter is a row delimiter.
        if (
            strMatchedDelimiter.length &&
            strMatchedDelimiter !== strDelimiter
        ) {
            // Since we have reached a new row of data,
            // add an empty row to our data array.
            arrData.push([]);
        }
        var strMatchedValue;
        // Now that we have our delimiter out of the way,
        // let's check to see which kind of value we
        // captured (quoted or unquoted).
        if (arrMatches[2]) {
            // We found a quoted value. When we capture
            // this value, unescape any double quotes.
            strMatchedValue = arrMatches[2].replace(
                new RegExp("\"\"", "g"),
                "\""
            );
        } else {
            // We found a non-quoted value.
            strMatchedValue = arrMatches[3];
        }
        // Now that we have our value string, let's add
        // it to the data array.
        arrData[arrData.length - 1].push(strMatchedValue);
    }
    // Return the parsed data.
    return (arrData);
}


function formatDateValue(dt, opf = "YYYY-MM-DD", inpf) {
    let nopf = opf || "YYYY-MM-DD";
    if (dt instanceof Array) {
        let d = JSON.parse(JSON.stringify(dt));
        d.sort(function(a, b) {
            let a1 = a;
            let b1 = b;
            if (a1 && moment(a1, inpf).isValid()) a1 = moment(a1);
            if (b1 && moment(b1, inpf).isValid()) b1 = moment(b1);
            return a1 - b1;
        });
        for (let i = 0; i < d.length; i++) {
            let el = d[i];
            if (el && moment(el, inpf).isValid()) d[i] = moment(el, inpf).format(nopf).toString();
        }
        return d;
    } else if (dt && moment(dt, inpf).isValid()) return moment(dt, inpf).format(nopf).toString();
    else return dt;
}

function formatTimeValue(dt) {
    // console.log(dt);
    if (dt instanceof Array) {
        let d = JSON.parse(JSON.stringify(dt));
        let nd = [];
        let allok = true;
        for (let i = 0; i < d.length; i++) {
            let el = d[i];
            let vr = isValidTime(el);
            if (vr.r) nd[i] = vr.d.toString();
            else allok = false;
        }

        if (allok) {
            nd.sort(function(a, b) {
                let a1a = a.split(":");
                a1a[1] = a1a[1].split(" ")[0];
                a1a = a1a.map(x => Number(x));
                let b1a = b.split(":");
                b1a[1] = b1a[1].split(" ")[0];
                b1a = b1a.map(x => Number(x));
                // console.log(a1a, b1a);
                return Number(a1a[0] + "." + a1a[1]) - Number(b1a[0] + "." + b1a[1]);
                // if((a1a[0] > b1a[0]) || ((a1a[0] === b1a[0]) && (a1a[1] > b1a[1]))) return true;
                // else return false;
            });
            return nd;
        } else return [];
    } else {
        let vr = isValidTime(dt);
        if (vr.r) return vr.d.toString();
        else return "";
    }
}

function isValidTime(t = "") {
    let res = { r: false, d: "" };
    let ta = t.toString().split(":");
    if (ta.length === 2) {
        let t0 = Number(ta[0]);
        let t1 = Number(ta[1]);
        if (!isNaN(t0) && !isNaN(t1)) {
            if ((t0 >= 0 && t0 <= 23) && (t1 >= 0 && t1 <= 59)) {
                res.r = true;
                if (t0 === 0) res.d = 12 + ":" + ta[1] + " AM";
                else if (t0 === 12) res.d = 12 + ":" + ta[1] + " PM";
                else if (t0 < 12) res.d = ta[0] + ":" + ta[1] + " AM";
                else res.d = ta[0] - 12 + ":" + ta[1] + " PM";
            }
        }
    }
    return res;
}