<template>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card v-if="typeOfUser === 'Free'">
          <v-card-text>
            <br>
            <br>
            <br>
            <div class="text-h5 text-center font-weight-bold" align-center>
              This feature is available on paid version
            </div>
            <br>
            <br>
          </v-card-text>
        </v-card>
        <v-card class="shadow-off" loader-height="2" :loading="loading" :disabled="loading" v-else>
          <v-card-text class="pb-0">
  
  
            <div class="d-flex align-center justify-space-between ">
              <div class="">
                <div class="d-flex">
                  <p class="mb-1">Home</p>
                  <p class="px-2 mb-1">/</p>
                  <p class="mb-1">{{ metadata?.description }}</p>
                </div>
                <div class="d-flex">
                  <p class="black--text font-weight-bold text-h5">Virtual Analysis</p>
                  <v-btn class="shadow-off ml-2" small icon @click="refreshData()"><v-icon>mdi-refresh</v-icon></v-btn>
                </div>
              </div>
              <div class="d-flex ">
                <v-btn small color="black" class="mr-1 white--text" height="4vh" @click="addDialogBox()"
                  v-if="$nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add')">
                  <v-icon class="mr-1">mdi-plus</v-icon>
                  <span>New</span>
                </v-btn>
              </div>
            </div>
            <div v-if="loading">
              <v-skeleton-loader type="table-heading, list-item-two-line, article, article"></v-skeleton-loader>
            </div>
            <div v-else class="mt-4">
              <div class="mb-4" v-if="data.length > 0">
  
                <lb-datatableaction :headers="headers"  v-if="paginatedItems.length>0" :tabledata="paginatedItems" :itemsperpage="itemsPerPage"
                  searchterm="" :bulkActions="false">
  
                  <template v-slot:tbody>
                    <tr v-for="item in paginatedItems" :key="item._id" class=""
                      :class="{ 'low-opacity': item.status === false }">
                      <template v-for="header in headers">
                        <td v-if="header.visible && header.value !== 'action'" :key="header.value"
                          class="px-2 single-line"
                          :class="{ 'description-header': header.value === 'description', 'single-line': header.value !== 'description' }">
                          <div v-if="header.value==='stage'">
                              <v-chip small label color="info" v-if="item.stage === 0">Open</v-chip>
                              <v-chip small label text color="success" class="" v-else-if="item.stage === 2">Close</v-chip>
                              <v-chip small label text color="error" v-else-if="item.stage === 1">freeze</v-chip>
                          </div>
                          <div v-else-if="header.value==='created_at' || header.value==='to' ||header.value==='from'">
                            {{ $nova.formatDate(item[header.value]) }}
                        </div>
                          <span v-else> {{ item[header.value] }}</span>
                         
                        </td>
                      </template>
                      <td class="d-flex align-center px-3 single-line border-right border-left action-data">
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small color="" v-bind="attrs" v-on="on"
                              @click="selectVa(item)">
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>View</span>
                        </v-tooltip>
  
                      </td>
                    </tr>
                  </template>
                </lb-datatableaction>

                <div class="d-flex align-center justify-center" v-else>
                  NO DATA
                </div>
                <Pagination :list="data" :itemsperpage="itemsPerPage" @paginated-data="setPaginatedData" />
  
              </div>
              <div class="mb-4 text grey--text title pa-8 text-center" v-else>
                No analysis added
              </div>
            </div>
  
          </v-card-text>
        </v-card>
  
        <lb-drawer v-model="adddialog" heading="Add New Variance Analysis" width="600" :loading="addloading">
          <template v-slot:body>
            <div>
              <div class="">
                <p class="ma-0 mb-1">Project*</p>
                <lb-dropdown label="Select Project" v-model="vafromvalue" :items="formdata.projects"
                  itemtext="description" itemvalue="_id" />
                <v-row>
                  <v-col cols="6">
                    <p class="ma-0 mb-1">Amount 1 Column Name*</p>
                    <lb-string v-model="addeditdata.amount1name" label="" placeholder="Enter Amount 1 Column Name"
                      :error="addediterror.amount1name" hidedetails />
                  </v-col>
                  <v-col cols="6">
                    <p class="ma-0 mb-1">Amount 2 Column Name*</p>
                    <lb-string v-model="addeditdata.amount2name" label="" placeholder="Enter Amount 2 Column Name"
                      :error="addediterror.amount2name" hidedetails />
                  </v-col>
                  <v-col cols="6">
                    <p class="ma-0 mb-1">Materiality Amount</p>
                    <lb-number class="mr-2" prependiconinner="mdi-cash" label="" hidedetails
                      v-model="addeditdata.materialityamount1" min="0" />
                  </v-col>
                  <v-col cols="6">
                    <p class="ma-0 mb-1">Materiality Percentage</p>
                    <lb-number label="" prependiconinner="mdi-percent" step="0.01" hidedetails
                      v-model="addeditdata.materialitypercent1" min="0" />
                  </v-col>
                </v-row>
                <div class="mt-4">
                  <div class="font-weight-bold mr-2">Note:</div>
                  <div>The Column Names filled above will be used to display the comparative. Eg: Actuls Vs Budget</div>
                  <div>Variance analysis workflow will be triggered if either the Materiality Amount or Percentage is
                    crossed.</div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:actions>
            <span class="caption">*Required</span>
            <v-spacer></v-spacer>
            <v-btn small color="black" class="white--text " height="3.5vh" @click="addAnalysis()">Create</v-btn>
          </template>
        </lb-drawer>
  
  
      </div>
    </div>
  </template>
  
  <script>
  import Pagination from '../../../../views/common/Pagination.vue';
  export default {
    name: 'financialclose_varienceanalysis_list',
    // props: ['props'],
    data: function () {
      return {
        right: 'financialclose_analysis',
        metadata: {},
        summary: {},
        data: [],
        userid: "",
        formdata: {},
        userlist: {},
        stage: -1,
        id: "",
        loading: false,
        addeditdata: {},
        addediterror: {},
        adddialog: false,
        addloading: false,
        vafrom: "",
        vafromvalue: "",
        populatevaitems: [
          { _id: "blank", name: "Blank" },
          { _id: "previousproject", name: "Previous Project" },
        ],
        typeOfUser: "Paid",
        headers: [
          {
            value: "description",
            text: "Description",
            sortable: false,
            filterable: false,
            visible: true,
          },
          {
            value: "from",
            text: "From",
            sortable: false,
            filterable: false,
            visible: true,
          },
          {
            value: "to",
            text: "To",
            sortable: false,
            filterable: false,
            visible: true,
          },
          {
            value: "stage",
            text: "Status",
            sortable: false,
            filterable: false,
            visible: true,
          },
          {
            value: "created_at",
            text: "Created on",
            sortable: false,
            filterable: false,
            visible: true,
          },
          {
            value: "action",
            text: "Action",
            sortable: false,
            filterable: false,
            visible: true,
          },
        ],
        itemsPerPage: 16,
        paginatedItems: []
      }
    },
    components: {
      Pagination
    },
    created() {
      this.init();
      this.refreshData();
    },
    activated() {
      this.init();
      this.refreshData();
    },
  
    methods: {
      selectVa(item){
        this.$nova.gotoLink({ path: '/financialclose/project/' + this.id + '/varianceanalysis/analysis/' + item._id });
        localStorage.setItem("VarianceAnalysis_id",item._id)
      },
      setPaginatedData(paginatedData) {
        this.paginatedItems = paginatedData;
      },
      init() {
        if (this.$store.state.subscriptions.financialclose.plan_detail.name) {
          this.typeOfUser = this.$store.state.subscriptions.financialclose.plan_detail.name || "Paid";
        }
      },
      refreshData() {
        this.id = this.$route.params.id || "";
        this.userid = this.$store.state.user.id;
        this.getData();
      },
      getData() {
        this.loading = true;
        return this.axios.post("/v2/financialclose/analysis/getvarianceanalysisdetails/" + this.id).then(dt => {
          if (dt.data.status === "success" && dt.data.data.length > 0) {
            let data = dt.data.data[0] || {}
            this.metadata = data.metadata || {};
            this.data = data.metadata?.flux_projects_detail || [];
            this.stage = this.metadata.stage;
            this.summary = data.summary || {};
          } else throw Error("Error loading project");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).then(() => {
          return this.axios.post("/v2/financialclose/analysis/getvarianceanalysisformdata/" + this.metadata._id);
        }).then(dt => {
          if (dt.data.status === "success") {
            this.formdata = dt.data.data[0] || {};
            this.userlist = {};
            let colors = this.$nova.getColors((this.formdata.users || []).length, 0.2);
            // console.log(colors);
            for (let i = 0; i < (this.formdata.users || []).length; i++) {
              const el = (this.formdata.users || [])[i];
              this.userlist[el._id] = el;
              this.userlist[el._id].color = colors[i];
            }
          } else throw new Error(dt.data.message);
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        }).finally(() => {
          this.loading = false;
        })
      },
      addDialogBox() {
        this.addeditdata = {};
        this.addeditdata.amount1name = "Actuals";
        this.addeditdata.materialityamount1 = "0";
        this.addeditdata.materialitypercent1 = "2";
        this.adddialog = true;
      },
      addAnalysis() {
        this.addloading = true;
        this.axios.post("/v2/financialclose/analysis/addvarianceanalysis/" + this.metadata._id, { data: [this.addeditdata] }).then(dt => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "Analysis Added");
            this.adddialog = false;
            this.$nova.gotoLink({ path: '/financialclose/project/' + this.id + '/varianceanalysis/analysis/' + dt.data.data[0]?._id })
            // this.refreshData();
          } else throw dt.data.message || "Error changing status";
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.addloading = false;
        });
      },
  
    },
    computed: {
    },
    watch: {
      "vafrom": function (d) {
        this.addeditdata.vafrom = d;
      },
      "vafromvalue": function (d) {
        this.addeditdata.vafromvalue = d;
      }
    }
  }
  </script>
  