  <template>
      <v-combobox
        v-model="computedModel"
        hide-selected
        :multiple="multiple"
        :small-chips="multiplechips"
        :deletable-chips="multiplechips"
        :items="items"
        dense
        :error-messages="error"
        :placeholder="placeholder"
        outlined
        clearable
        :allow-overflow="false"
        :prepend-inner-icon="prependiconinner"
        :hide-details="hidedetails" 
        :disabled="disabled"
        :append-icon="appendiconinner"
        :item-text="itemtext"
        :item-value="itemvalue"
        class="pa-0 ma-0 cursor-pointer"    
      >
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip
            v-bind="attrs"
            :input-value="selected"
            label
            small
            :color="multiple === false ? 'white' : tagcolor"
            class="pa-0 px-1"
          >
            <span class="pr-2 pa-0 ma-0">
              {{ item }}
            </span>
            <v-icon v-if="multiple"
            style="font-size: 10px !important;"
            class="pa-0 "
              @click="parent.selectItem(item)"
            >mdi-close</v-icon>
          </v-chip>
        </template>
      </v-combobox>
  </template>

  <script>
  export default {
    data() {
      return {
        selected: [],
        select: "",
      };
    },
    props: {
      tagcolor: {
        type: String,
        default: "primary",
      },
      hidedetails: {
        type: Boolean,
        default: false,
      },
      items: {
        type: Array,
        default: function () {
          return [];
        },
      },
      isRequried: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      multiplechips: {
        type: Boolean,
        default: false,
      },
      value: {
        type: [String, Array],
        default: "",
      },
      prependiconinner: {
        type: String,
        default: "",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      appendiconinner: {
        type: String,
        default: "",
      },
      error: {
        type: [String, Array],
      },
      placeholder:{
        type:String,
        default:""
      },
      itemtext: {
      type: String,
      default: "name",
      },
      itemvalue: {
        type: String,
        default: "value",
      },
    },
    computed: {
      computedModel: {
        get() {
          return this.multiple ? this.selected : this.select;
        },
        set(val) {
          if (this.multiple) {
            this.selected = val;
          } else {
            this.select = val;
          }
          this.updateValue();
        },
        value: function(){
          console.log("qwertyuio",this.value);
          
        },
      },
    },
    watch: {
      selected() {
        this.updateValue();
      },
      select() {
        this.updateValue();
      },
      value: {
        immediate: true,
        handler(newVal) {
          if (this.multiple) {
            this.selected = newVal || [];
          } else {
            this.select = newVal || "";
          }
        },
      },
    },
    methods: {
      updateValue() {
        this.$emit("input", this.computedModel);
        this.$emit("change", this.computedModel);
      },
    },
  };
  </script>
<style scoped>
.v-select__selections{
    padding: 0 !important;
}
.v-menu__content .v-autocomplete__content.v-menu__content{
  border:1px solid red !important
} 
</style>